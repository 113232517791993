@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

.wrapper {
  width: 100%;
  height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  .container {
    height: 84%;
    width: 50%;
    max-width: 800px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000000D;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      text-align: center;
      font: normal normal bold 24px/29px Lato;
      color: #000000;
      opacity: 1;
      padding-top: 20px;
    }
    .name-save-btn {
      margin-top: 20px;
      width: 80%;
      background-color: #CD4A4A;
      color: white;
      border-radius: 5px;
      height: 40px;
    }
    .topics {
      width: 80%;
      height: 420px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding-top: 20px;
      &::-webkit-scrollbar {
        display: none;
      }
      .topic {
        display: flex;
        justify-content: space-between;
        .topic__data {
          display: flex;
          align-items: center;
          gap: 16px;
          .topic__data__icon {
            width: 40px;
            height: 40px;
          }
          .topic__data__title{
            color: black;
          }
        }
        span {
          color: #CD4A4A;
            svg {
                height: 29px;
              width: 29px;

          }
        }
      }
    }
  }
}