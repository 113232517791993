// live streaming

.live-container { 
    & { margin: 0 auto; max-width: 1000px; width: 100%; min-height: 700px; }
      .live-stream-catalog {
        // & { display: none; }
        .stream-container {
          & { width: 100%; height: 280px; margin: 25px 0; }
          .streams-carousel {
            & { margin: 0 auto; width: 100%; max-width: 969px; height: 280px; }
            .carousel-root { height: 100%; }
            .carousel.carousel-slider { height: 100%; }
            .slider-wrapper { height: 100%; }
            .slider { height: 100%; }
          }
          .stream {
            & { position: relative; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; border-radius: 10px; background: transparent linear-gradient(180deg, #1B9FA3 0%, #267EB1 20%, #6B559E 39%, #B04570 60%, #D24C50 79%, #F58726 100%) 0% 0% no-repeat padding-box; }
            img { border-radius: 10px; margin: 0 auto; height: 100%; width: 100%; object-fit: cover; }
            
            .profile {
            & { color: #fff; position: absolute; bottom: 15px; left: 5%; display: flex; align-items: center; gap: 10px;}
            img { height: 24px !important; width: 24px !important; border-radius: 100%; }
          }
          
          .view-count {
            & { height: 25px; width: 75px; background: #00000090; color: #fff; border-radius: 5px; position: absolute; top: 15px; right: 5%; display: flex; align-items: center; justify-content: center; gap: 5px;}
          }
          .scheduled-for {
            & { text-transform: uppercase; height: 25px; background: #F58726; color: #fff; font-size: 13px; padding: 0 10px; border-radius: 5px; position: absolute; top: 15px; right: 5%; display: flex; align-items: center; justify-content: center; gap: 5px;}
          }
          
          .we-are-live {
            & { height: 30px; width: 135px; background: #E71D1D; color: #fff; border-radius: 5px; position: absolute; bottom: 15px; right: 5%; display: flex; align-items: center; justify-content: center;}
            span { text-transform: uppercase; font-weight: 800; }
          }
          .notify-me {
            & { height: 30px; width: 125px; font-size: 10px; border-radius: 5px; position: absolute; bottom: 15px; right: 5%; display: flex; align-items: center; justify-content: center;}
            .notify-me-btn {
              & { height: 30px; width: 125px; font-size: 12px; color: #FFF;  border: 2px solid #FFF; }
            }
          }
        }
      }
  
      .explore-streams {
        & { width: 100%; margin: 25px 0; position: relative; }
        h3 { padding: 0 1rem; }
        
        .streams {
          & { min-height: 500px; padding: 0 1rem; display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start; gap: 20px; }
          .stream-box {
            & { margin-bottom: 1rem; cursor: pointer; width: 227px; height: 224px; box-shadow: 0px 2px 10px #0000001A; border-radius: 10px;}
            .stream-img {
              & { border-radius: 10px 10px 0 0; width: 227px; height: 136px; display: flex; align-items: center; justify-content: center; }
              img { border-radius: 10px 10px 0 0; object-fit: cover; width: 227px; height: 136px; }
            }
            .stream-deets { 
              & { padding: 10px 15px; }
              .stream-name { margin-bottom: 10px; font-size: 16px; color: #000; font-weight: 800;  }
              .stream-date { font-size: 14px; color: #000; font-weight: 600; }
              .stream-status { 
                & { font-size: 14px; font-weight: 600; color: #000; display: flex; align-items: center;}
                .status { padding: 0 5px; }
                .is-live { color: #E71D1D; }
                .is-completed { color: #1AB53F; }
              }
            }
          }
        }
        .no-events-info {
          & { display: flex; align-items: center; justify-content: center; flex-direction: column-reverse; gap: 10px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
        }
      }
    }
  
    .live-stream-display {
      & { height: calc(100% - 58px); width: 100%; display: flex; overflow: hidden; position: absolute; top: 58px; left: 0; right: 0; bottom: 0; }
      .live-stream {
        & { position: relative; height: 100%; width: 100%; }
        
        img { height: 100%; width: 100%; object-fit: cover; }

        .live-video-player {
          & { height: 100%; width: 100%; }
          video { height: 100%; width: 100%; object-fit: contain; }
        }
        
        .stream-details {
          img { height: 100%; width: 100%; object-fit: cover; }
          & { position: absolute; top: 24px; left: 24px; display: flex; align-items: center; }
          .title { color: #fff; font-size: 20px; font-weight: 600; }
          .live-indicator {
            & { height: 25px; width: 40px; font-size: 14px; padding: 0 5px; margin: 0 0 0 10px; background: #ff0000; color: #fff; border-top-left-radius: 5px; border-bottom-left-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;}
          }
          .time-count {
            & { height: 25px; padding: 0 5px; margin: 0 10px 0 0; background: #000000; color: #fff; border-top-right-radius: 5px; border-bottom-right-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;}
          }
          .view-count {
            & { height: 25px; width: 75px; margin: 0 10px; background: #00000090; color: #fff; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;}
            .MuiSvgIcon-root { height: 16px; width: 16px; }
          }
        }
        .chat-icon {
          & { cursor: pointer; height: 32px; width: 32px; color: #fff; background: transparent linear-gradient(131deg, #FFFFFF66 0%, #FFFEFE26 100%); border-radius: 5px; backdrop-filter: blur(20px); position: absolute; top: 24px; right: 40px; display: flex; align-items: center; justify-content: center; }
          img { height: 24px; width: 24px; }
          .chat-count {
            & { position: relative; }
            span { height: 24px; width: 24px; font-size: 12px; display: flex; align-items: center; justify-content: center; position: absolute; bottom: -30px; right: -15px; background: #f00; color: #fff; padding: 5px; border-radius: 100%; }
          }
        }
        
        .stream-controls {
          & { position: absolute; bottom: 24px; right: 0; left: 0; display: flex; gap: 5px; align-items: center; justify-content: center; }
          img { height: 24px; width: 24px; }
          .mic {
            & { cursor: pointer; height: 32px; width: 32px; color: #fff; background: transparent linear-gradient(131deg, #FFFFFF66 0%, #FFFEFE26 100%); border-radius: 5px; backdrop-filter: blur(20px); display: flex; align-items: center; justify-content: center; }
          }
          .video {
            & { cursor: pointer; height: 32px; width: 32px; color: #fff; background: transparent linear-gradient(131deg, #FFFFFF66 0%, #FFFEFE26 100%); border-radius: 5px; backdrop-filter: blur(20px); display: flex; align-items: center; justify-content: center; }
          }
          .share-screen {
            & { cursor: pointer; position: relative; height: 32px; width: 32px; color: #fff; background: transparent linear-gradient(131deg, #FFFFFF66 0%, #FFFEFE26 100%); border-radius: 5px; backdrop-filter: blur(20px); display: flex; align-items: center; justify-content: center; }
            .share-screen-options { 
              & { position: absolute; top: -165px; left: 0px; height: 155px; width: 185px; display: flex; flex-direction: column; background: #fff; border-radius: 5px; }
              #title { font-weight: 700; font-size: 16px; color: #000; padding: 16px; }
              .options {
                & { color: #000; }
                .option {
                  & { font-weight: 600; display: flex; align-items: center; justify-content: flex-start; font-size: 14px; margin: 5px 0; }
                  &:hover { background: #00000030; }
                  img { width: 24px; height: 24px; margin: 0 5px 0 16px;}
                }
              }
            }
          }
          .end-live {
            & { cursor: pointer; height: 32px; width: 92px; color: #fff; background: #CD4A4A; border-radius: 5px; backdrop-filter: blur(20px); display: flex; align-items: center; justify-content: center; }
          }
          
        }
      }
      .live-chat { 
        & { position: relative; width: 325px; height: 100%; background: #fff; display: flex; flex-direction: column; justify-content: flex-end; }
        .chat-header { 
          & { z-index: 100; position: absolute; top: 0; height: 55px; width: 100%; background: #fff; padding: 0 15px; display: flex; align-items: center; justify-content: space-between; color: #000; }
          .MuiSvgIcon-root { cursor: pointer; }
        }
        .live-chat-container {
          & { height: 90%; }
          .chat {
            & { flex-grow: 1; min-height: 100%; max-height: 100%; display: flex; justify-content: flex-end; }
            .add-reaction {
              & { height: 100vh; width: 100vw; display: flex; align-items: center; justify-content: center; z-index: 100; background: #000000da; position: absolute;  top: 0; right: 0; bottom: 0; left: 0; }
    
              .reactions {
                & { position: relative; }
                img {
                  & { cursor: pointer; width: 100px; height: 100px; transition: transform 200ms ease ; }
                  &:hover { transform: scale(1.1); }
                } 
                .close-reaction { cursor: pointer; color: #fff; position: absolute; top: -50px; left: 50%; right: 50%; border: 2px solid #fff; border-radius: 5px; }
              }
            }
            .messages {
              & { width: 100%; min-height: 100%; max-height: 100%; padding: 40px 10px 0 10px; overflow-y: scroll; overflow-x: hidden; display: flex; flex-direction: column-reverse; align-items: flex-start;  }
              
              .message {
                & { position: relative; max-width: 95%; margin: 15px 0; display: flex; flex-direction: row-reverse; align-content: flex-end; align-items: center; justify-content: flex-end; gap: 10px; font-size: 12px;}
                .message-text {
                  & { background: #eee; color: #000; position: relative;  padding: 10px; display: flex; flex-direction: column; gap: 5px; align-items: flex-start; word-break: break-word;  }
                  strong { cursor: pointer; color: #3c80ff; display: contents;}
                  .reply-msg {
                    & { height: 100%; width: 100%; }
                    .reply-content { 
                      & { font-size: 15px; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 0 5px 0; border-left: 4px solid #007C8950; }
                      .name { color: #3483FA; font-weight: 700; }
                      .content { width: 100%; max-height: 60px; overflow: hidden; text-overflow: ellipsis; }
                     }
                  }
                  .message-emote-time { 
                    & { position: absolute; bottom: -28px; left: 0; right: 0; width: 275px; height: 25px; display: flex; align-items: center; justify-content: flex-start; gap: 5px; }
                    .time { font-size: 12px; color: #9e9e9e; }
                    .reaction { 
                      & { background: none; height: 25px; display: flex; align-items: center; min-width: 30px; border-radius: 5px; margin: 0 2px; }
                        img { width: 20px; height: 20px; }
                      .reaction-count { height: 25px !important; background: none; font-size: 12px; padding: 0; margin: 0;}
                    }
                  }
                  
                }
                .message-emote-action {
                  & { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); right: -125px; display: flex; align-items: center; justify-content: space-evenly; }
                  .css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
                  &:hover { display: flex !important; }
                }
    
                &:hover {
                  .message-emote-action { display: flex !important; }
                }
    
                .chat-avatar { & { cursor: pointer; position: relative; width: 32px; height: 32px; border-radius: 20px; align-self: flex-start; }
                span { width: 32px; height: 32px; border-radius: 20px; }
                img { width: 32px; height: 32px; border-radius: 20px; object-fit: cover; }      
                .name { cursor: pointer; width: 45px; position: absolute; bottom: -20px; left: 0; right: 0; font-size: 12px; overflow: hidden; text-overflow: ellipsis; }      
              }            
              .chat-attachment { object-fit: contain; width: 100%;}
              span {
                & { position: relative; padding: 5px; min-height: 30px; border-radius: 7px; display: flex; align-items: center; justify-content: center; background-color: #ffffff;}
              .play-btn { & { cursor: pointer; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center;}
              .css-i4bv87-MuiSvgIcon-root { 
                 color: #fff; height: 32px; width: 32px; transition: transform 250ms ease;
                &:hover { transform: scale(1.2); }
                }
            }
              }
    
            }
    
            .message-mine { 
              & { align-self: flex-end; flex-direction: row;  }
              .chat-avatar { display: none; }
              .message-emote-action {
                & { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); left: -40px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-evenly; }
                .css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
                &:hover { display: flex !important; }
              }
    
              &:hover {
                .message-emote-action { display: flex !important; }
              }
              .message-text {
                & { position: relative; /*max-height: 50%;*/ word-break: break-word; background: rgb(99, 182, 168); color: #fff; }
                strong { cursor: pointer; color: #3c80ff; display: contents;}
                .message-emote-time { 
                  & { position: absolute; bottom: -28px; left: auto; right: 0px; width: 240px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start;}
                  .time { font-size: 12px; }
                }
              }
            }
    
            .no-messages { & { color: #989898; user-select: none; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; }
            .top-span {font-size: 20px; display: flex; align-items: center; justify-content: center; gap: 10px; }
          }
          }
    
        }
        }
        .chat-box-container {
          & { position: relative; min-height: 10%; padding: 10px 5px; display:flex; align-items: flex-end; flex-shrink:0;  }
          .msg-edit { 
            & { width: 100%; max-height: 115px; height: 110px; padding: 10px; position: absolute; top: -94px; left: 0; right: 0; background-color:#dadada; }
            .reply-content { 
              & { font-size: 15px; max-width: 80%; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 auto; border-left: 4px solid #007C8950; }
              .name { 
                & { width: 100%; color: #3483FA; font-weight: 700; display: flex; align-items: center; justify-content: space-between; }
                .MuiSvgIcon-root { margin: 0; cursor: pointer; color: #007C8990; border: 2px solid #007C8990; border-radius: 5px; }
              }
              .content { width: 100%; max-height: 40px; overflow: hidden; text-overflow: ellipsis; }
             }
          }
          .input-style{
            & {  max-width: 100%; margin-inline: 10px;  word-break: break-word; flex:1; -moz-appearance: none; -webkit-appearance: none; appearance: none; background-color: #fff; border-radius: 10px; border-width: 0; }
            &:empty::before{content:"Type a message"; font-size: 16px; opacity:0.5;}
            &:focus { outline: none;}
            .emoji-icon { cursor: pointer; position: absolute; top: 8px; right: 5px;}
            & .input-style__control { max-width: 100%; min-height: 100% !important; width: 100%; padding: 0; border-radius: 10px; }
            & .input-style__control input { border: 0px !important; top: 10px !important; padding: 2px 13px !important; outline: none; max-width: 100%; min-width: 100%; }
            & .input-style__control textarea { border: 0px !important; outline: none; border-radius: 10px; }
            & .input-style__control textarea:empty::before{content:"Type a message"; font-size: 16px; opacity:0.5;}
            & .input-style__control input:empty::before{content:"Type a message"; font-size: 16px; opacity:0.5;}
            & .input-style__control textarea:focus { border: 0px !important; outline: none; }
            & .input-style__control input:focus { border: 0px !important; outline: none; }
            & .input-style__control .input-style__highlighter > strong { padding: 2px 0px; border-radius: 5px; font-weight: 700;}
            .input-style__suggestions { display: flex; overflow: scroll !important; top: -165px !important; height: 150px; max-height: 150px; min-width: 150px !important; }
            .input-style__suggestions ul { overflow: scroll !important; width: 100%; }
        }
        .css-i4bv87-MuiSvgIcon-root { margin: auto;}
                  
           
          //.input-style { padding: 7px 30px 7px 10px; width: calc(100% - 40px); border-radius: 3px; border: 1px solid #999; margin-bottom: 10px;}
         
          .attach-meadia { 
            & { position: absolute; left: -48px; right: 0; top: -283px; width: 112px; background: #fff; border-radius: 3px; padding: 15px 10px; box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.4);}
            .attach-btns { 
              & { position: relative; }
              .arrow { position: absolute; bottom: -35px; left: 35px; right: 0; 
                div { height: 27px; width: 27px; background: #fff; transform: rotateZ(45deg); }
              }
              .icon-img {
                & { cursor: pointer; margin: 10px 0; display: flex; flex-direction: column; align-items: center; transition: transform 250ms ease; }
                span { font-size: 14px; color: #9F9FA1; font-weight: bold; }
  
                &:hover { transform: scale(1.05); }
              }
             }
        }
        .attach-parent {
          & {  position: relative; display: flex; align-items: center; justify-content: center; padding: 0px !important; width: 20px; height: 40px; margin: 0px !important; background: #ffffff; color: #000000; box-shadow: none; margin-bottom: 2px; }
        }
  
      .common-button{
        & { position: relative; padding: 8px; width: 40px; height: 40px; margin: 0 3px; background: #CD4A4A; color: #000000; box-shadow: none; margin-bottom: 2px; }
        &:hover, &:focus{ outline:none; }
        .icon{ display:block; width:24px; height:24px; font-size:18px; text-align:center; transition:0.2s; }
        
      }
        
      }
    }
    }
  
  
    .live-stream-viewer {
      & { height: 100%; width: 100%; display: flex; overflow: hidden; }
      .live-stream {
        & { position: relative; height: 100%; width: 100%; }
        
        img { height: 100%; width: 100%; object-fit: cover; }

        .live-video-player {
          & { height: 100%; width: 100% !important; }
          video { height: 100% !important; width: 100% !important; object-fit: contain !important; }
        }
  
        .profile {
          & { color: #fff; position: absolute; top: 60px; left: 60px; display: flex; align-items: center; gap: 10px;}
          img { height: 24px !important; width: 24px !important; border-radius: 100%; }
        }
        
        .stream-details {
          & { padding: 1rem; padding-top: 0; position: absolute; display: flex; align-items: center; min-height: 70px; width: 100%; max-width: 100%; background: linear-gradient(360deg, #ffffff00 0%, #000000a3 100%); top: 0; left: 0; opacity: 0; transition: opacity 400ms ease-in-out; }
          img { height: 100%; width: 100%; object-fit: cover; }
          .exit-stream { cursor: pointer; color: #fff; margin: 0 15px 0 0; }
          .title { color: #fff; font-size: 20px; font-weight: 600; }
          .live-indicator {
            & { height: 25px; width: 40px; font-size: 14px; padding: 0 5px; margin: 0 0 0 10px; background: #ff0000; color: #fff; border-top-left-radius: 5px; border-bottom-left-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;}
          }
          .time-count {
            & { height: 25px; padding: 0 5px; margin: 0 10px 0 0; background: #000000; color: #fff; border-top-right-radius: 5px; border-bottom-right-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;}
          }
          .view-count {
            & { height: 25px; width: 75px; margin: 0 10px; background: #00000090; color: #fff; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;}
            .MuiSvgIcon-root { height: 16px; width: 16px; }
          }
        }
        .chat-icon {
          & { cursor: pointer; height: 32px; width: 32px; color: #fff; background: transparent linear-gradient(131deg, #FFFFFF66 0%, #FFFEFE26 100%); border-radius: 5px; backdrop-filter: blur(20px); position: absolute; top: 24px; right: 40px; display: flex; align-items: center; justify-content: center; }
          img { height: 24px; width: 24px; }
          .chat-count {
            & { position: relative; }
            span { height: 24px; width: 24px; font-size: 12px; display: flex; align-items: center; justify-content: center; position: absolute; bottom: -30px; right: -15px; background: #f00; color: #fff; padding: 5px; border-radius: 100%; }
          }
        }
        
        .stream-controls {
          & { position: absolute; bottom: 24px; right: 0; left: 0; display: flex; gap: 5px; align-items: center; justify-content: center; }
          img { height: 24px; width: 24px; }
          .player { height: 40px; width: 95%; background: transparent linear-gradient(131deg, #FFFFFF66 0%, #FFFEFE26 100%); border-radius: 5px; backdrop-filter: blur(20px); }
          }
        
      }
      .live-chat { 
        & { position: relative; width: 325px; height: 100%; background: #fff; display: flex; flex-direction: column; justify-content: flex-end; }
        .chat-header { 
          & { z-index: 100; position: absolute; top: 0; height: 55px; width: 100%; background: #fff; padding: 0 15px; display: flex; align-items: center; justify-content: space-between; color: #000; }
          .MuiSvgIcon-root { cursor: pointer; }
        }
        .live-chat-container {
          & { height: 90%; }
          .chat {
            & { flex-grow: 1; height: calc(100% - 50px); display: flex; justify-content: flex-end; }
            .add-reaction {
              & { height: 100vh; width: 100vw; display: flex; align-items: center; justify-content: center; z-index: 100; background: #000000da; position: absolute;  top: 0; right: 0; bottom: 0; left: 0; }
    
              .reactions {
                & { position: relative; }
                img {
                  & { cursor: pointer; width: 100px; height: 100px; transition: transform 200ms ease ; }
                  &:hover { transform: scale(1.1); }
                } 
                .close-reaction { cursor: pointer; color: #fff; position: absolute; top: -50px; left: 50%; right: 50%; border: 2px solid #fff; border-radius: 5px; }
              }
            }
            .messages {
              & { width: 100%; min-height: 100%; max-height: 100%; padding: 40px 10px 10px 10px; overflow-y: scroll; overflow-x: hidden; display: flex; flex-direction: column-reverse; align-items: flex-start;  }
              
              .message {
                & { position: relative; max-width: 95%; margin: 15px 0; display: flex; flex-direction: row-reverse; align-content: flex-end; align-items: center; justify-content: flex-end; gap: 10px; font-size: 12px;}
                .message-text {
                  & { background: #eee; color: #000; position: relative;  padding: 10px; display: flex; flex-direction: column; gap: 5px; align-items: flex-start; word-break: break-word;  }
                  strong { cursor: pointer; color: #3c80ff; display: contents; }
                  .reply-msg {
                    & { height: 100%; width: 100%; }
                    .reply-content { 
                      & { font-size: 15px; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 0 5px 0; border-left: 4px solid #007C8950; }
                      .name { color: #3483FA; font-weight: 700; }
                      .content { width: 100%; max-height: 60px; overflow: hidden; text-overflow: ellipsis; }
                     }
                  }
                  .message-emote-time { 
                    & { position: absolute; bottom: -28px; left: 0; right: 0; width: 275px; height: 25px; display: flex; align-items: center; justify-content: flex-start; gap: 5px; }
                    .time { font-size: 12px; color: #9e9e9e; }
                    .reaction { 
                      & { background: none; height: 25px; display: flex; align-items: center; min-width: 30px; border-radius: 5px; margin: 0 2px; }
                        img { width: 20px; height: 20px; }
                      .reaction-count { height: 25px !important; background: none; font-size: 12px; padding: 0; margin: 0;}
                    }
                  }
                  
                }
                .message-emote-action {
                  & { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); right: -125px; display: flex; align-items: center; justify-content: space-evenly; }
                  .css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
                  &:hover { display: flex !important; }
                }
    
                &:hover {
                  .message-emote-action { display: flex !important; }
                }
    
                .chat-avatar { & { cursor: pointer; position: relative; width: 32px; height: 32px; border-radius: 20px; align-self: flex-start; }
                span { width: 32px; height: 32px; border-radius: 20px; }
                img { width: 32px; height: 32px; border-radius: 20px; object-fit: cover; }      
                .name { cursor: pointer; width: 45px; position: absolute; bottom: -20px; left: 0; right: 0; font-size: 12px; overflow: hidden; text-overflow: ellipsis; }      
              }            
              .chat-attachment { object-fit: contain; width: 100%;}
              span {
                & { position: relative; padding: 5px; min-height: 30px; border-radius: 7px; display: flex; align-items: center; justify-content: center; background-color: #ffffff;}
              .play-btn { & { cursor: pointer; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center;}
              .css-i4bv87-MuiSvgIcon-root { 
                 color: #fff; height: 32px; width: 32px; transition: transform 250ms ease;
                &:hover { transform: scale(1.2); }
                }
            }
              }
    
            }
    
            .message-mine { 
              & { align-self: flex-end; flex-direction: row;  }
              .chat-avatar { display: none; }
              .message-emote-action {
                & { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); left: -40px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-evenly; }
                .css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
                &:hover { display: flex !important; }
              }
    
              &:hover {
                .message-emote-action { display: flex !important; }
              }
              .message-text {
                & { position: relative; /* max-height: 50%; */ word-break: break-word; background: rgb(99, 182, 168); color: #fff; }
                strong { cursor: pointer; color: #3c80ff; display: contents;}
                .message-emote-time { 
                  & { position: absolute; bottom: -28px; left: auto; right: 0px; width: 240px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start;}
                  .time { font-size: 12px; }
                }
              }
            }
    
            .no-messages { & { color: #989898; user-select: none; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; }
            .top-span {font-size: 20px; display: flex; align-items: center; justify-content: center; gap: 10px; }
          }
          }
    
        }
        .message-box {
          & {
            position: relative;
            height: 50px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            background-color: #dadada;
          }
          .msg-edit {
            & {
              width: 100%;
              max-height: 115px;
              height: 110px;
              padding: 10px;
              position: absolute;
              top: -94px;
              left: 0;
              right: 0;
              background-color: #dadada;
            }
            .reply-content {
              & {
                font-size: 15px;
                max-width: 80%;
                border-radius: 5px;
                background: #efefef;
                padding: 5px 10px;
                margin: 0 auto;
                border-left: 4px solid #007c8950;
              }
              .name {
                & {
                  width: 100%;
                  color: #3483fa;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }
                .MuiSvgIcon-root {
                  margin: 0;
                  cursor: pointer;
                  color: #007c8990;
                  border: 2px solid #007c8990;
                  border-radius: 5px;
                }
              }
              .content {
                width: 100%;
                max-height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .input-style {
            & {
              max-width: 100%;
              margin-inline: 10px;
              word-break: break-word;
              flex: 1;
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              background-color: #fff;
              border-radius: 10px;
              border-width: 0;
            }
            &:empty::before {
              content: 'Type a message';
              font-size: 16px;
              opacity: 0.5;
            }
            &:focus {
              outline: none;
            }
            .emoji-icon {
              cursor: pointer;
              position: absolute;
              top: 8px;
              right: 5px;
            }
            & .input-style__control {
              max-width: 100%;
              min-height: 100% !important;
              width: 100%;
              padding: 0;
              border-radius: 10px;
            }
            & .input-style__control input {
              border: 0px !important;
              top: 10px !important;
              padding: 2px 13px !important;
              outline: none;
              max-width: 100%;
              min-width: 100%;
            }
            & .input-style__control textarea {
              border: 0px !important;
              outline: none;
              border-radius: 10px;
            }
            & .input-style__control textarea:empty::before {
              content: 'Type a message';
              font-size: 16px;
              opacity: 0.5;
            }
            & .input-style__control input:empty::before {
              content: 'Type a message';
              font-size: 16px;
              opacity: 0.5;
            }
            & .input-style__control textarea:focus {
              border: 0px !important;
              outline: none;
            }
            & .input-style__control input:focus {
              border: 0px !important;
              outline: none;
            }
            & .input-style__control .input-style__highlighter > strong {
              padding: 2px 0px;
              border-radius: 5px;
              font-weight: 700;
            }
            .input-style__suggestions {
              display: flex;
              overflow: scroll !important;
              top: -165px !important;
              height: 150px;
              max-height: 150px;
              min-width: 150px !important;
            }
            .input-style__suggestions ul {
              overflow: scroll !important;
              width: 100%;
            }
          }
          .css-i4bv87-MuiSvgIcon-root {
            margin: auto; height: 20px; width: 20px;
          }
  
          //.input-style { padding: 7px 30px 7px 10px; width: calc(100% - 40px); border-radius: 3px; border: 1px solid #999; margin-bottom: 10px;}
  
          .attach-meadia {
            & {
              position: absolute;
              left: 25px;
              right: 0;
              top: -267px;
              width: 120px;
              background: #fff;
              border-radius: 3px;
              padding: 15px 10px;
              box-shadow: 3px 3px 15px #00000015;
            }
            .attach-btns {
              & {
                position: relative;
              }
              .arrow {
                position: absolute;
                bottom: -35px;
                left: 35px;
                right: 0;
                div {
                  height: 27px;
                  width: 27px;
                  background: #fff;
                  transform: rotateZ(45deg);
                }
              }
              .icon-img {
                & {
                  cursor: pointer;
                  margin: 10px 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  transition: transform 250ms ease;
                }
                span {
                  font-size: 14px;
                  color: #9f9fa1;
                  font-weight: bold;
                }
  
                &:hover {
                  transform: scale(1.05);
                }
              }
            }
          }
          .common-button {
            & {
              position: relative;
              width: 25px;
              height: 40px;
              margin: 0 3px;
              background: #dadada;
              color: #000000;
              box-shadow: none;
              margin-bottom: 2px;
              display: flex;
              align-items: center; 
              justify-content: center;
            }
            &:hover,
            &:focus {
              outline: none;
              .icon {
                filter: grayscale(1);
              }
            }
            .icon {
              display: block;
              width: 24px;
              height: 24px;
              font-size: 18px;
              text-align: center;
              filter: grayscale(1) opacity(0.5);
              transition: 0.2s;
              display: flex;
              align-items: center; 
              justify-content: center;
            }
          }
        }
        }
        .chat-box-container {
          & { height: 65px; position: relative; min-height: 10%; padding: 10px 5px; display:flex; align-items: flex-end; flex-shrink:0;  }
          .msg-edit { 
            & { width: 100%; max-height: 115px; height: 110px; padding: 10px; position: absolute; top: -94px; left: 0; right: 0; background-color:#dadada; }
            .reply-content { 
              & { font-size: 15px; max-width: 80%; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 auto; border-left: 4px solid #007C8950; }
              .name { 
                & { width: 100%; color: #3483FA; font-weight: 700; display: flex; align-items: center; justify-content: space-between; }
                .MuiSvgIcon-root { margin: 0; cursor: pointer; color: #007C8990; border: 2px solid #007C8990; border-radius: 5px; }
              }
              .content { width: 100%; max-height: 40px; overflow: hidden; text-overflow: ellipsis; }
             }
          }
          .input-style{
            & {  max-width: 100%; margin-inline: 10px;  word-break: break-word; flex:1; -moz-appearance: none; -webkit-appearance: none; appearance: none; background-color: #fff; border-radius: 10px; border-width: 0; }
            &:empty::before{content:"Type a message"; font-size: 16px; opacity:0.5;}
            &:focus { outline: none;}
            .emoji-icon { cursor: pointer; position: absolute; top: 8px; right: 5px;}
            & .input-style__control { max-width: 100%; min-height: 100% !important; width: 100%; padding: 0; border-radius: 10px; }
            & .input-style__control input { border: 0px !important; top: 10px !important; padding: 2px 13px !important; outline: none; max-width: 100%; min-width: 100%; }
            & .input-style__control textarea { border: 0px !important; outline: none; border-radius: 10px; }
            & .input-style__control textarea:empty::before{content:"Type a message"; font-size: 16px; opacity:0.5;}
            & .input-style__control input:empty::before{content:"Type a message"; font-size: 16px; opacity:0.5;}
            & .input-style__control textarea:focus { border: 0px !important; outline: none; }
            & .input-style__control input:focus { border: 0px !important; outline: none; }
            & .input-style__control .input-style__highlighter > strong { padding: 2px 0px; border-radius: 5px; font-weight: 700;}
            .input-style__suggestions { display: flex; overflow: scroll !important; top: -165px !important; height: 150px; max-height: 150px; min-width: 150px !important; }
            .input-style__suggestions ul { overflow: scroll !important; width: 100%; }
        }
        .css-i4bv87-MuiSvgIcon-root { margin: auto;}
                  
           
          //.input-style { padding: 7px 30px 7px 10px; width: calc(100% - 40px); border-radius: 3px; border: 1px solid #999; margin-bottom: 10px;}
         
          .attach-meadia { 
            & { position: absolute; left: -48px; right: 0; top: -283px; width: 112px; background: #fff; border-radius: 3px; padding: 15px 10px; box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.4);}
            .attach-btns { 
              & { position: relative; }
              .arrow { position: absolute; bottom: -35px; left: 35px; right: 0; 
                div { height: 27px; width: 27px; background: #fff; transform: rotateZ(45deg); }
              }
              .icon-img {
                & { cursor: pointer; margin: 10px 0; display: flex; flex-direction: column; align-items: center; transition: transform 250ms ease; }
                span { font-size: 14px; color: #9F9FA1; font-weight: bold; }
  
                &:hover { transform: scale(1.05); }
              }
             }
        }
        .attach-parent {
          & {  position: relative; display: flex; align-items: center; justify-content: center; padding: 0px !important; width: 20px; height: 40px; margin: 0px !important; background: #ffffff; color: #000000; box-shadow: none; margin-bottom: 2px; }
        }
  
      .common-button{
        & { position: relative; padding: 8px; width: 40px; height: 40px; margin: 0 3px; background: #CD4A4A; color: #000000; box-shadow: none; margin-bottom: 2px; }
        &:hover, &:focus{ outline:none; }
        .icon{ display:block; width:24px; height:24px; font-size:18px; text-align:center; transition:0.2s; }
        
      }
      

      }
      }
    }
  
    .no-vids {
      & { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; }
  
    }
  }
  