.menu-btn{
    &{ display: none; }
}

.closeBtnMobNav{
    &{ display: none;}
}

.mob-nav-logo-img{
    &{ display: none;}
}

@media screen and (max-width:768px) {
    .top-navbar-container{
        .top-navbar{
            &{ padding: 10px !important;}
            .activities{
                &{ width: 100% !important;  }
            }
        }
        .menu-btn{
            &{ display: block !important; padding: 0 !important; width: 0 !important; color: black; background: transparent !important;}
        }
        .closeBtnMobNav{
            &{ display: block !important; padding: 0 !important; width: 0 !important; color: black; background: transparent !important;}
        }
        .nav-icon{
            &{ z-index: 0 !important;}
        }
    }

    // .nav-search{
    //     &{ display: none !important; }
    // }

    .nav-container {
        &{  position: absolute; top: 0; left: -70%; height: 100vh; background: white; width: 71vw !important; max-width: 280px; z-index: 1; transition: all 0.25s ease-in-out; box-shadow: 0px 3px 10px #00000014; }
        
        ul{
            &{ gap: 40px !important; position: relative; display: flex !important; flex-direction: column; height: 100% !important; align-items: center; justify-content: flex-start !important; padding: 20px !important; }
            // .mobile-img-container{margin-bottom: 10px;}
            .closeBtnMobNav{
                &{ position: absolute; right: 50px; display: block !important; }
            }
        }

        nav{ position: fixed; top: 0; left: 0; background: white; align-items: center; padding: 5px 10px !important; }
    }
    .nav-opened { left: 0;}

	.mob-nav-logo-img {
		& { display: block !important; width: 80%;}
	}
}
