// ! These are global button styles. Please edit with caution. make new class for a specific button that doesn't fit the defined criteria 

.f-btn {
  & { border: 2px solid #CD4A4A; display: inline-flex; align-items: center; justify-content: center; position: relative; background-color: #CD4A4A; outline: 0; border: 2px solid #CD4A4A; margin: 0; border-radius: 0; cursor: pointer; user-select: none; vertical-align: middle; text-decoration: none; color: inherit; font-weight: 600; min-width: 64px; padding: 8px; border-radius: 4px; transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; color: #ffffff; }
  &:hover { text-decoration: none; background-color: #dc5656; border: 2px solid #dc5656; }
  &:active { text-decoration: none; background-color: #ad3d3d; border: 2px solid #ad3d3d; }
  &:disabled { text-decoration: none; background-color: #d2d2d27a; color: #979797; cursor: default; pointer-events: all; border: none; }
}

.f-btn-bordered {
  & { border: 2px solid #CD4A4A; background: #FFFFFF; color: #CD4A4A; }
  &:hover { text-decoration: none; background-color: #CD4A4A10; }
  &:active { text-decoration: none; background-color: #CD4A4A15; }
  &:disabled { text-decoration: none; border: 2px solid #979797; background-color: #d2d2d27a; color: #979797; cursor: default; pointer-events: all; }
}

.f-btn-follow {
  & { min-width: 90px; }
}
.f-btn-unfollow {
  & { min-width: 90px; border: 2px solid #909090; background: #FFFFFF; color: #909090; }
  &:hover { text-decoration: none; background-color: #f1f1f1; border: 2px solid #979797;  }
  &:active { text-decoration: none; background-color: #e8e8e8; border: 2px solid #979797;  }
  &:disabled { text-decoration: none; border: 2px solid #979797; background-color: #d2d2d27a; color: #979797; cursor: default; pointer-events: all; }
}

.f-btn-extra-big {
  & { padding: 14px; font-size: 18px; }
}

.f-btn-big {
  & { padding: 12px; font-size: 16px; }
}

.f-btn-small {
  & { padding: 6px; font-size: 14px; }
}

.f-btn-extra-small {
  & { padding: 6px; font-size: 12px; }
}

.f-btn-full-width {
  & { width: 100%; }
}

.f-btn-half-width {
  & { width: 50%; }
}

.avatar-picture{ width: 100%; height: 100%; background-position: center; background-size: cover; width: 100%; height: 100%; border-radius: 100%;}

// ! These are global button styles. Please edit with caution. make new class for a specific button that doesn't fit the defined criteria 