// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$mainfont : 'Lato', sans-serif;
$mulish : 'Mulish', sans-serif;
$work-sans : 'Work Sans', sans-serif;
$lato :  'Lato', sans-serif;

::-webkit-scrollbar-track { background: #f6f7fa; }

.featured-container {
    & { height: 100%; font-family: $lato; background: #F6F7FA; }
    .dashboard-container {
      & { height: 400px; width: 100%; padding: 30px; background: url('../assets/img/featured/bg-path.svg'), linear-gradient(90deg, #1BA1A1 0%, #1D84B4 19%, #7D4A99 43%, #C04464 63%, #ED5932 83%, #F58B25 100%); background-repeat: no-repeat; background-position: center; background-size: 101%, contain;  transition: 250ms ease-in-out; }
      .dashboard {
        & { display: flex; flex-direction: column; }
        .dashboard-header {
          & { margin: 0 0 2rem 0; display: flex; align-items: center; }
          h2 { margin: 0; color: #fff; font-size: 24px; font-weight: 700; }
          .expand-dashboard {
            & { margin-left: 1rem; color: #FFF; height: 36px; width: 36px; cursor: pointer; transition: transform 250ms ease-in-out; }
          }
        }
        .dash-tabs { 
          & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
          .popular-networks { 
            & { height: 265px; width: 400px; background: #fff; border-radius: 10px; padding: 25px; }
            h3 { margin-top: 0; color: #000; font-size: 20px; font-weight: 700; }
            .card-container {
              & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
              .card {
                & { width: 115px; height: 170px; border: 1px solid #eee; border-radius: 10px; }
                .card-part-1 {
                  & { display: flex; flex-direction: column; align-items: center; padding: 8px 0; border-bottom: 1px solid #eee; }
                  .card-img { width: 96px; height: 80px; border-radius: 5px; display: flex; justify-content: center; align-items: center; overflow: hidden;  object-fit: cover; }
                  .initial { width: 96px; height: 80px; border-radius: 5px; display: flex; justify-content: center; align-items: center; overflow: hidden; }
                  .card-img img { width: 100%; height: 100%; object-fit: cover; }
                  .card-name { font-size: 14px; color: #000; margin-top: 8px; font-weight: 700;  height: 15px; white-space: break-spaces; overflow: hidden; text-overflow: ellipsis; padding: 0 5px; text-align: center; }
                }
                .card-part-2 {
                  & { display: flex; flex-direction: column; align-items: center; padding: 8px; }
                  span { font-size: 12px; }
                  .followers { font-size: 14px; color: #000; font-weight: 700; padding-top: 5px; }
                }
              }
              .card-create {
                & { width: 115px; height: 170px; border: 1px dashed #eee; border-radius: 10px;  }
                .card-part-1 { display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100px; padding: 8px 0; font-size: 38px; cursor: pointer;}
                .card-part-2 {
                  & { display: flex; flex-direction: column; align-items: center; text-align: center; padding: 8px; }
                  span { font-size: 12px; }
                }
              }
            }
          }
  
          .engaged-users { 
            & { height: 265px; width: 400px; background: #fff; border-radius: 10px; padding: 25px; }
            h3 { margin-top: 0; color: #000; font-size: 20px; font-weight: 700; }
            .card-container {
              & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
              .card {
                & { width: 115px; height: 170px; border: 1px solid #eee; border-radius: 10px; }
                .card-part-1 {
                  & { display: flex; flex-direction: column; align-items: center; padding: 8px 0; border-bottom: 1px solid #eee; }
                  .card-img { width: 80px; height: 80px; border-radius: 40px; display: flex; justify-content: center; align-items: center; overflow: hidden; object-fit: cover; }
                  .initial { width: 80px; height: 80px; border-radius: 40px; display: flex; justify-content: center; align-items: center; overflow: hidden; object-fit: cover; }
                  .card-img img { height: 100%; width: 100%; object-fit: cover; }
                  .card-name { font-size: 14px; color: #000; margin-top: 8px; font-weight: 700; height: 15px; white-space: break-spaces; overflow: hidden; text-overflow: ellipsis; padding: 0 5px; text-align: center; }
                }
                .card-part-2 {
                  & { display: flex; flex-direction: column; align-items: center; padding: 8px; }
                  span { font-size: 12px; }
                  .followers { font-size: 14px; color: #000; font-weight: 700; padding-top: 5px; }
                }
              }
            }
          }
          .network-earning { 
            & { height: 265px; width: 400px; background: #fff; border-radius: 10px; display: flex; flex-direction: column; justify-content: space-between; }
            h3 { margin: 0; color: #000; font-size: 20px; font-weight: 700; padding: 24px; }
            .earning-container {
              & { width: 100%; padding: 0 25px; display: none; }
              .earnings {
                & { width: 100%; display: flex; gap: 5px; margin-bottom: 15px; flex-direction: column; align-items: center; justify-content: space-between; }
                .earning-network { 
                  & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
                  .network-name { color: #000; font-weight: 700; }
                  .network-earning-cash { font-weight: 900; color: #1AB53F; }
                  .network-earning-cash-pos { font-weight: 900; color: #1AB53F; }
                  .network-earning-cash-neg { font-weight: 900; color: #E71D1D; }
              }
              .earning { 
                & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
                .network-name { font-size: 16px; color: #000; font-weight: 500; }
                .network-earning-cash { font-weight: 900; color: #000; }
                .network-earning-cash-pos { font-weight: 900; color: #1AB53F; }
                .network-earning-cash-neg { font-weight: 900; color: #E71D1D; }
              }
              .more {
                & { cursor: pointer; width: 100%; text-align: left; font-size: 12px; color: #9B9B9B; }
              }
            }
            }
  
            .become-creator {
              & { height: 193px; font-family: $lato; background: #1C89B0; border-radius: 0px 0px 10px 10px; display: flex; flex-direction: column; align-items: center; padding: 0 8px;  }
              .creator-img {
                & { padding: 10px 15px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
              }
              span { text-align: center; color: #fff; font-weight: 700; }
              .become-creator-btn { margin-top: 10px; font-family: $lato; width: 388px; height: 32px; background: #CD4A4A; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
            }
          }
         }
      }
    }
    .home-featured {
      & { display: flex; max-width: 1366px; margin: 0 auto; }
      .featured {
        & { width: 74%; display: flex; flex-direction: column; overflow: scroll;}
          .featured-hubs {
            & { width: 100%; display: flex; flex-direction: column; align-items: center; height: 270px; gap: 20px; margin-top: 30px; }
            .featured-hub-header {
              & { height: 30px; width: 100%; display: flex; align-items: center; justify-content: space-between; padding-right: 10px; }
              h1 { color: #000; font-size: 24px; margin: 0; }
              .view-more { cursor: pointer; color: #9B9B9B; }
            }
            .networks-container {
              & { width: 100%; height: 230px; display: flex; align-items: center; gap: 20px; padding: 0 5px; flex-wrap: nowrap;
                overflow-x: scroll;}
              .networks {
                & { height: 194px; width: 465px; padding: 16px; position: relative; display: flex; align-items: center; gap: 20px; cursor: pointer; background: #fff; box-shadow: 0px 2px 10px #00000030; border-radius: 10px;}
                .hub-img {
                  & { border: 1px solid #eee; position: relative; height: 162px; width: 164px; min-width: 164px; object-fit: contain; border-radius: 10px; }
                }
                img { height: 162px; width: 164px; min-width: 164px; object-fit: contain; border-radius: 10px; background-color: #fff; }
                .join-btn { 
                & { position: absolute; left: 0; right: 0; bottom: 8px; width: 164px; min-width: 164px; height: 30px; display: flex; align-items: center; justify-content: center; }
                .MuiButtonBase-root { color: #fff; width: 148px; height: 32px; background: #CD4A4A; border-radius: 5px; }
              }
              .initial { height: 162px; width: 164px; min-width: 164px; border-radius: 10px; display: flex; align-items: center; justify-content: center; }
            .network-text {
              & { height: 100%; width: 250px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-evenly;}
              h2 { font-size: 22px; color: #17170F; margin-bottom: 5px; margin-top: 0;  overflow: hidden; text-overflow: ellipsis; }
              h4 { color: #A2A2A4; margin-bottom: auto; margin-top: 0; height: 40px; overflow: hidden; }
              .network-info {
                & { width: 80%; display: flex; align-items: center; justify-content: space-around; }
                span {
                  & { font-size: 22px; font-weight: 700; display: flex; flex-direction: column; align-items: center; gap: 5px; color: #17170F; }
                  img { width: 25px; height: 25px; min-width: 25px; border-radius: 0px; background: transparent; }
                }
              }
            }
          }
        }
        }
  
        .featured-profile {
          & { width: 90%; display: flex; flex-direction: column; align-items: center; }
          .profile-wrapper {
            & { position: relative;  width: 100%; height: 220px; margin-top: 30px;  }
            .profile-title {
              & { width: 100%;  font-weight: 700; height: 10%; padding-right: 10px; display: flex; align-items: center; justify-content: space-between; }
              h1 { font-size: 22px; margin: 0; color: #17170F; }
              .view-more { cursor: pointer; color: #9B9B9B; font-weight: normal; }
            }
            .profile-container {
              & { height: 90%; width: 100%; overflow-x: scroll; display: flex; align-items: center; flex-wrap: nowrap; justify-content: flex-start;  }
              .profile {
                & { width: 118px; margin: 5px 20px; }
                .profile-img {
                  & { cursor: pointer; width: 118px; height: 118px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                img { width: 118px; height: 118px; border-radius: 100%; object-fit: cover; }
                span { width: 118px; height: 118px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .profile-name {
                & { cursor: pointer; margin-top: 10px; color: #17170F; white-space: nowrap; overflow: hidden; text-align: center; }
              }
            }
          }
          }
  
          .hub-tab-container {
            & { position: relative; width: 100%; margin-top: 30px; }
            .featured-networks {
              & { height: 310px; margin-bottom: 15px; display: flex; flex-direction: column; justify-content: space-between; }
              .heading {
                & { width: 100%;  font-weight: 700; height: 30px; padding-right: 10px; display: flex; align-items: center; justify-content: space-between; }
                h1 { font-size: 22px; margin: 0; color: #17170F; }
                .view-more { cursor: pointer; color: #9B9B9B; font-weight: normal; }
              }
              .networks-container {
                & { display: flex; align-items: center; gap: 20px; overflow-x: scroll; height: 280px; padding: 0 5px; }
                .networks {
                  & { width: 174px; height: 245px; box-shadow: 0px 3px 10px #00000030; border-radius: 10px; }
                  .hub-img {
                    & { position: relative; margin-bottom: 10px; width: 174px; height: 132px; display: flex; align-items: center; justify-content: center; border-radius: 10px 10px 0px 0px; background-color: #fff; }
                    img { cursor: pointer; width: 174px; height: 132px; object-fit: cover; border-radius: 10px 10px 0px 0px; background-color: #fff; }
                    .initial { cursor: pointer; min-width: 174px; min-height: 132px; display: flex; align-items: center; justify-content: center; border-radius: 10px 10px 0px 0px; }
                    .favorite {
                      & { cursor: pointer; height: 24px; width: 24px; position: absolute; top: 10px; right: 10px;  background: #00000020; border-radius: 5px; }
                      .fav-hollow { color: #fff; }
                      .fav-filled { color: #CD4A4A; }
                    }
                  }
                  .network-text { 
                    & {  margin-top: 15px; position: relative; height: 100px; display: flex; flex-direction: column; align-items: center; gap: 15px; text-align: center; }
                    .full-name {
                      & { position: absolute; top: 20px; right: 5px; display: none; font-size: 12px; width: 120px; z-index: 100; background: #fff; color: #17170F; overflow: unset; white-space: break-spaces; border-radius: 5px; box-shadow: 0px 2px 10px #0000000F; }
                    }
                    .network-name { 
                      & { cursor: pointer; width: 100%; padding: 0 15px; text-align: center; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 16px; font-weight: 700; color: #17170F; }
                      &:hover {
                        .full-name { display: block; }
                      }
                    }
                    .network-info {
                      & { width: 80%; display: flex; align-items: center; justify-content: space-around; }
                      span {
                        & { font-size: 16px; font-weight: 700; display: flex; flex-direction: column; align-items: center; gap: 5px; color: #17170F; }
                        img { width: 25px; height: 25px; min-width: 25px; border-radius: 0px; background: transparent; }
                      }
                    }
                  }
                }
              }
            }
          }
          .hr {
            & { width: 1px; height: 95%; background: #707070; }
          }
        }
      }
      .activities {
        & { width: 26%; margin: 30px 0; display: flex; flex-direction: column; align-items: center; gap: 32px; }
        .activity-feed {
          & { display: flex; flex-direction: column; width: 332px; height: 435px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 3px 10px #0000000D; border-radius: 10px; }
          .activity-header {
            & { width: 100%; padding: 24px; display: flex; align-items: center; justify-content: space-between; }
            h1 { color: #000; font-size: 24px; margin: 0;  }
            .view-more { cursor: pointer; color: #9B9B9B; }
          }
          .activity-container {
            & { padding: 0 24px 24px 24px; }
            .activity {
              & { height: 40px; width: 100%; margin-top: 15px; display: flex; align-items: center; gap: 15px;  }
              .avatar {
                & { height: 40px; width: 40px; border-radius: 100%; }
                img { height: 40px; width: 40px; border-radius: 100%; object-fit: cover; }
                span { height: 40px; width: 40px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .hub-avatar {
                & { height: 40px; width: 40px; border-radius: 5px; }
                img { height: 40px; width: 40px; border-radius: 5px; object-fit: cover; }
                .initial { height: 40px; width: 40px; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
                span { height: 40px; width: 40px; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
              }
              .activity-details {
                .user-name {
                  & { font-weight: 700; color: #17170F; }
                }
                .activity-description { 
                  & { max-width: 100%; min-width: 100%; display: flex; gap: 5px; align-items: center; justify-content: flex-start; }
                  .description { max-width: 160px; height: 20px; overflow: hidden; color: #17170F; font-size: 14px; text-overflow: ellipsis; }
                  .dot { height: 5px; width: 5px; }
                  .time { color: #9B9B9B; font-size: 12px; }
                 }
              }
            }
          }
        }
  
        .recent-messages {
          & { display: flex; flex-direction: column; width: 332px; max-height: 535px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 3px 10px #0000000D; border-radius: 10px; }
          .messages-header {
            & { width: 100%; padding: 24px; display: flex; align-items: center; justify-content: space-between; }
            h1 { color: #000; font-size: 24px; margin: 0;  }
            .view-more { cursor: pointer; color: #9B9B9B; }
          }
          .messages-container {
            & { padding: 0 24px 24px 24px; overflow: hidden;  }
            .message {
              & { height: 60px; width: 100%; margin-top: 15px; display: flex; align-items: flex-start; gap: 15px;  }
              .avatar {
                & { position: relative; height: 40px; width: 40px; border-radius: 100%; }
                img { height: 40px; width: 40px; border-radius: 100%; object-fit: cover; }
                span { height: 40px; width: 40px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                .online { position: absolute; width: 10px; height: 10px; right: 1px; bottom: 1px; background: #1AB53F; border: 1px solid #fff; border-radius: 100%;}
              }
              .messages-details {
                & { position: relative; width: calc(100% - 40px); }
                .user-name {
                  & { display: flex; align-items: center; gap: 15px; }
                  .name { font-weight: 700; color: #17170F; }
                  .time { font-size: 12px; color: #9B9B9B; }
  
                }
                .messages-text { 
                  & { font-size: 14px; height: 35px; width: 100%; color: #17170F; overflow: hidden; text-overflow: ellipsis; }
                 }
                 .message-count {
                  & { position: absolute; top: 0; right: 0; background: #E71D1D; color: #fff; font-size: 10px; height: 20px; width: 20px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                 }
              }
            }
          }
        }
      }
    }
  }

  .featured-loading {

    .dashboard {
      & {  height: 40%; width: 100%; padding: 30px; display: flex; flex-direction: column; }
      .dash-tabs { 
        & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
        .popular-networks { 
          & { height: 265px; width: 420px; background: #fff; border-radius: 10px; padding: 25px; display: flex; align-items: center; justify-content: center; }
          .card-container {
            & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
            .card {
              & { width: 115px; height: 170px; border: 1px solid #eee; border-radius: 10px; }
              .card-part-1 {
                & { height: 100%; overflow: hidden; display: flex; flex-direction: column; align-items: center; padding: 8px 0; border-bottom: 1px solid #eee; }
              }
            }
          }
        }
       }
    }
    .home-featured {
      & { display: flex; padding: 0 30px; height: 60%; }
      .featured {
        & { margin-top: 100px; width: 100%; display: flex; flex-direction: column; }
            .networks-container {
              & { width: 100%; height: 200px; display: flex; align-items: center; justify-content: space-between; gap: 20px; padding: 0 5px; flex-wrap: nowrap; overflow-x: hidden; overflow-y: hidden; }
              .network {
                & { height: 194px; width: 465px; padding: 16px; position: relative; display: flex; align-items: center; gap: 20px; cursor: pointer; background: #fff; border-radius: 10px;}
              .hub-img { height: 100px; width: 100px; border-radius: 100%; overflow: hidden; }
              .hub-text { 
                & { height: 170px; width: 250px; display: flex; flex-direction: column; align-items: center; gap: 25px; justify-content: center; }
                .text { 
                  height: 25px; width: 100%; border-radius: 5px; overflow: hidden;
                }
              }
          }
        }
      }
    }
    .skeleton {
      width: 0;
      height: 100%;
      box-shadow: 0 0 50px 50px #00000030;
      animation: skeleton1 1.5s linear infinite;
    }
    
    @keyframes skeleton1 {
      0% {
        transform: translateX(-50px);
        opacity: 0;
      }
    
      20% {
        opacity: 0;
      }
    
      50% {
        transform: translateX(20px);
        opacity: 0.3;
      }
    
      100% {
        transform: translateX(170px);
        opacity: 1;
      }
    }
  }