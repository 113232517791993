.main {
  position: relative;
}
.menu {
  z-index: 1;
  position: relative;
}
.wrapper {
  position: relative;
  .wrapper__main {
    display: flex;
    flex-direction: column;
    margin-bottom: 230px;
    .wrapper__main__bg {
      position: absolute;
      width: 100%;
      max-height: 300px;
      svg {
        height: 100vh !important;
      }
    }
    .wrapper__main__content {
      //position: absolute;
      z-index: 1;
      top: 110px;
      width: 100%;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 60px;
        color: white;
        font-weight: 600;
        text-align: center;
        padding-top: 110px;
      }
      .form {
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50vw;
        max-width: 800px;
        min-width: 600px;
        background-color: #fff;
        margin-top: 95px;
        border-radius: 10px;
        .form__name{
          font-size: 60px;
          text-align: center;
          font-weight: bold;
          padding-top: 32px;
        }
        .form__email {
          margin-top: 40px;
          width: calc(100% - 64px);
        }
        .form__message {
          margin-top: 40px;
          width: calc(100% - 64px);
        }
        .submit-button {
          display: inline-block;
          background-color: #cd4a4a;
          box-shadow: 0 3px 6px #00000029;
          border-radius: 5px;
          color: #ffffff;
          font-size: 24px;
          text-decoration: none;
          padding: 10px 15px;
          margin-top: 40px;
          width: calc(100% - 64px);
          text-align: center;
        }
        .form__orText {
          color:  #cd4a4a;
          margin-top: 40px;
          font-size: 20px;
        }
        .form__helper {
          margin-top: 16px;
        }
        .form__persons {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-around;
          width: calc(100% - 64px);
          margin-top: 40px;
          margin-bottom: 32px;
          .form__person {
            display: flex;
            flex-direction: column;
            align-items: center;
            .form__person__image {
              border-radius: 50%;
              width: 100px;
              height: 100px;
              object-fit: cover;
            }
            .form__person__btn {
              width: fit-content;
            }
          }
        }
      }
    }
  }
  //.footer {
  //  bottom: 0;
  //  position: absolute;
  //  width: 100%;
  //}
}