@media screen and (max-width: 600px) {
  .login-register-page { padding: 1rem; }
  .login-register-page .login-register-container { flex-direction: column; width: 100% !important; height: auto !important; margin: 1rem 0; }
  .login-register-page .login-register-container .register { min-height: 50% !important; height: 50% !important; width: 100% !important; }
  .login-register-page .login-register-container .login { display: flex; align-items: center; justify-content: center; min-height: 600px !important; height: 50% !important; width: 100% !important; }
  .login-register-page .login-register-container .overlay-wrapper-login { padding: 1rem !important; width: 100% !important; height: 50% !important; border-radius: 19px 19px 0 0 !important; transform: translateY(-50%) !important; }
  .login-register-page .login-register-container .overlay-wrapper-register { padding: 1rem !important; width: 100% !important; height: 50% !important; border-radius: 0 0 19px 19px !important; transform: translateY(50%) !important; }
  .login-register-page .login-register-container .login .login-form { display: flex; flex-direction: column; justify-content: center; }
  .login-register-page .login-register-container .login .login-form .logo { margin-bottom: 0 !important; }
  .login-register-page .login-register-container .login .login-form h1 { font-size: 24px !important; margin-bottom: 1rem !important; }
  .login-register-page .login-register-container .register .register-form .logo { margin-bottom: 0 !important; }
  .login-register-page .login-register-container .register .register-form h1 { font-size: 24px !important; margin-bottom: 1rem !important; }
  }  