@media screen and (max-width: 420px) {
    .TermsOfUse_wrapper__SpA-B .TermsOfUse_wrapper__SpA-B .TermsOfUse_wrapper__main__2yz58 .TermsOfUse_wrapper__main__content__3KNqQ .TermsOfUse_form__kgJ8X{
        &{ width: 98% !important; }
    }
}

@media screen and (max-width: 480px) {
    .TermsOfUse_wrapper__SpA-B .TermsOfUse_wrapper__SpA-B .TermsOfUse_wrapper__main__2yz58 .TermsOfUse_wrapper__main__content__3KNqQ .TermsOfUse_title__rWjGy{
        &{ font-size: 30px !important; padding-top: 30px !important; }
    }

    .TermsOfUse_wrapper__SpA-B .TermsOfUse_wrapper__SpA-B .TermsOfUse_wrapper__main__2yz58 .TermsOfUse_wrapper__main__content__3KNqQ .TermsOfUse_form__kgJ8X{
        &{ margin-top: 30px !important; }
    }
}