.profile-details { 
    &{width: 100%;display: flex; align-items: center; flex-direction: column; padding-top: 30px;}

  .card-container {
      &{width: 90%;max-width: 1100px;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 10px #0000000D;border-radius: 10px;opacity: 1;display: flex;flex-direction: column;align-items: center; min-height: 589px;}

      .profile-details-loading-container{width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; min-height: 589px;}

      .card-body{
        &{width: 100%;}
          .gradient-header {
            &{position: relative; height: 250px; width: 100%; border-radius: 10px 10px 0px 0px;background: transparent linear-gradient(90deg, #1B9FA3 0%, #267EB1 20%, #6B559E 39%, #B04570 60%, #D24C50 79%, #F58726 100%) 0% 0% no-repeat padding-box;display: flex;justify-content: center; }
            .edit-image-btn {
                & { position: absolute; top: 10px; bottom: 0; left: 850px; right: 10px;  }
                .text {
                    & { color: #fff; width: 100%; border: 2px solid #fff; text-align: center; padding: 8px 0; border-radius: 4px; cursor: pointer;  }
                }
            }
            .banner-image {
                & { height: 100%; width: 100%;  }
                img { width: 100%; height: 100%; object-fit: cover; border-radius: 10px 10px 0px 0px; }
            }
            }
        }
        .card-info{ 
            &{width: 100%; padding: 80px 0 30px 0; background-color: #ffffff; color: #000000; text-align: center; border-radius: 0 0 10px 10px;}
            h2{margin: 40px 0 5px 0;}
            h3{margin: 5px 0;}
            .links-container {
                & { display: flex; align-items: center; justify-content: space-between; padding: 0 1.5rem; }
                .links { &{display: flex; justify-content: space-between; align-items: center; gap: 20px; max-width: 400px;} 
                h3{padding-bottom: 2px; border-bottom: 2px solid black;}    
            }
            .add-links { color: #8c8c8c; text-transform: uppercase; cursor: pointer; }
        }
            .tabs { & {margin: 20px 20px 0; padding: 20px 20px 0 20px; border-top: 2px solid #D9D9D9; display: flex; justify-content: start; align-items: center; flex-wrap: wrap; gap: 15px;}
                .tab{ &{font-size: 14px; padding: 10px; color: #707070; text-transform: uppercase; white-space: nowrap; border-radius: 5px; cursor: pointer;}
                    span{margin-left: 5px;}
                }
                .about-tab{ &{display: flex; justify-content: center; align-items: center; gap: 10px }
                    img{width: 20px;}
                }
                .active-tab{ background-color: #1BA0A2; color: white; }
            }
        }


        .avatar-container {
            &{position: absolute;bottom: 0;min-height: 160px;min-width: 160px;max-width: 230px;max-height: 230px;transform: translateY(50%);height: 13vw;width: 13vw;border-radius: 50%;background: #EEEEEE 0% 0% no-repeat padding-box;display: flex;justify-content: center;align-items: center;overflow: hidden;cursor: pointer; border: 3px solid white;}
            // .avatar-image{ background-size: cover; background-position: center;}
            .overlay-top {
                & { position: absolute; bottom: 100%; left: 0; right: 0; background-color: black; overflow: hidden; opacity: 60%; width: 100%; height: 0; }
              }
              &:hover .overlay-top {
                & { bottom: 0; height: 100%; display: flex; justify-content: center; align-items: center; }
              } 
        }
    }
    .tab-description {
        &{width: 90%;max-width: 950px; margin: 30px 0;}

        .about { & {font-weight: 600; color: #000000; background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 10px #0000000D;border-radius: 10px;opacity: 1; padding: 20px;}
            .edit-btn{margin-left: auto; display: flex; width: fit-content; color: #CD4A4A; font-weight: normal; gap: 10px; padding: 10px; border: 2px solid #CD4A4A; border-radius: 5px; margin-bottom: 20px; cursor: pointer;}
        }

        .posts{
            .posts-container {
                & { width: 100%; display: flex; gap: 20px; flex-direction: column; align-items: center; justify-content: flex-start; }
                .follow-notice { width: 100%; text-align: center; }
                .post {
                    & { max-width: 700px; width: 100%; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
                    .post-profile {
                    & { display: flex; align-items: center; justify-content: space-between; padding: 10px; }
                    .profile {
                        & { height: 64px; width: 100%; display: flex; gap: 16px; align-items: center; }
                        .profile-img {
                        & { height: 64px; width: 64px; border-radius: 100%; }
                        img { height: 64px; width: 64px; border-radius: 100%; object-fit: cover; }
                        span { height: 64px; width: 64px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                        }
                        .profile-name {
                        & { display: flex; flex-direction: column; }
                        .user { 
                            & { font-size: 20px; font-weight: 700; color: #000;  }
                            .tagged-users { cursor: pointer; }
                            .live-indicator { font-weight: 600; color: #CD4A4A; margin-left: 10px; text-transform: uppercase; font-size: 1rem; }
                        }
                        .activity-time { font-size: 16px; color: #000;  }
                        }
                    }
                    }
                    .post-caption {
                    & { padding: 16px 26px; width: 100%; color: #000; font-weight: 600; word-wrap: break-word; }
                    }
                    .newsfeed-post-caption {
                    & { font-size: 20px; width: 100%; }
                    }
            
                    .post-img {
                    & { max-height: 700px; width: 100%; display: flex; align-items: center; justify-content: center; border-top: 1px solid #f1f1f1;  }
                    img { object-fit: contain; height: 100%; width: 100%; }
                    video { object-fit: contain; height: 100%; width: 100%; }
                    .carousel-root { height: 100%; width: 100%; }
                    .carousel .carousel-status { font-size: 10px; text-shadow: none; right: 50px; background: #00000080; border-radius: 5px; }
                    .carousel .thumbs-wrapper { display: none; }
                    .carousel.carousel-slider { height: 100%; }
                    .carousel.carousel-slider .control-arrow { opacity: 0.8; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; border-radius: 100%; background: #ffffff; right: 16px; top: 50%; }
                    .carousel.carousel-slider .control-arrow::before { border-left: 8px solid #d2d2d2; }
                    .carousel .control-prev.control-arrow { opacity: 0.8; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; border-radius: 100%; background: #ffffff; left: 16px; top: 50%; }
                    .carousel .control-prev.control-arrow::before { margin-right: 10px; border-left: 0; border-right: 8px solid #d2d2d2; }
                    .carousel .control-disabled.control-arrow { opacity: 0; }
                    .carousel .slider-wrapper { height: 100%; }
                    .carousel .control-dots { bottom: 0px; }
                    .carousel .control-dots .dot { height: 10px; width: 10px; border: 1px solid #FFF; background: transparent; opacity: 1; box-shadow: 0 0 3px #919191; }
                    .carousel .control-dots .dot.selected { background: #CD4A4A !important; }
                    .carousel .slider-wrapper.axis-horizontal .slider { height: 100%; border-radius: 10px; }
                    .carousel .slider-wrapper.axis-horizontal .slider .slide { height: 100%; border-radius: 10px; }
                    .carousel .slider-wrapper.axis-horizontal .slider .slide .topic { position: absolute; top: 10px; left: 10px; font-size: 12px; padding: 5px; border-radius: 15px; background: #1BA1A1; color: #FFF; }
                    .carousel .slider-wrapper.axis-horizontal .slider .slide .final-img-container { position: relative; height: 100%; border-radius: 10px; }
                    .carousel.carousel-slider .control-arrow:hover { background: rgba(0,0,0,0.1); }
        
                    
                    }
            
                    .post-actions {
                    & { height: 32px; width: 100%; padding: 0px 24px; margin: 16px 0; display: flex; align-items: center; justify-content: flex-start; gap: 16px; }
                    .post-like {
                        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 8px; }
                        img { height: 32px; width: 32px; cursor: pointer; }
                        .like-heart { height: 32px; width: 32px; cursor: pointer; }
        
                        .reaction-container {
                        & { z-index: 100; position: absolute; top: -4rem; left: 0; display: flex; align-items: center; gap: 10px; padding: 10px 16px; background: #fff; box-shadow: 0 0 5px #00000050; border-radius: 12px; }
                        img { height: 32px; width: 32px; cursor: pointer; }
                        }
                    }
                    .post-comment {
                        & { display: flex; align-items: center; justify-content: center; gap: 8px; }
                        img { height: 32px; width: 32px; cursor: pointer; }
                    }
                    .post-delete {
                        & { margin-left: auto; display: flex; align-items: center; justify-content: center; gap: 8px; }
                        img { height: 32px; width: 32px; cursor: pointer; }
                    }
                    }
            
                    .add-comment {
                    & { padding: 16px 24px; display: flex; align-items: center; justify-content: center; gap: 8px; }
                    .user-avatar {
                        & { height: 32px; width: 32px; border-radius: 100%; }
                        img { height: 32px; width: 32px; border-radius: 100%; object-fit: cover; }
                        span { height: 32px; width: 32px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                    }
                    .comment-input {
                        & { width: calc(100% - 40px); height: 32px; padding-left: 10px; background: #FFFFFF; border: 1px solid #CACACA; border-radius: 5px; }
                        &:focus { outline: none; border: 1px solid #4a9fff; }
                    }
                    }
                }
                .newsfeed {
                    p {
                      & { padding: 0 20px; }
                    }
                    a {
                      & { color: #2275B9; }
                    }
                }
                .hubfeed {
                    .post-actions {
                      & { display: flex; justify-content: space-between; }
                      h4 {
                        & { color: black; }
                      }
                      button {
                        & { background:#CD4A4A; width: 160px; padding: 10px 0;}
                      }
                    }
                }
                .streamfeed{
                    .live-creator-details {
                    & { background:#ffffff; color: #000000; font-size: 20px; font-weight: 700; height: 65px; width: 100%; display: flex; align-items: center; justify-content: flex-start; padding-left: 1rem; }
                    }
                    .watch-recording {
                    & { background:#CD4A4A; color: #FFF; gap: 10px; font-size: 18px; height: 65px; width: 100%; display: flex; align-items: center; justify-content: center; border-radius: 0 0 10px 10px; }
                    
                    }
                }
            }
        }

        .columns-display{
            &{display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; gap: 20px;}
            .tile{ 
                &{width: 45%; display: flex; justify-content: space-between; align-items: center; gap: 20px; min-width: 310px;}
                .content{
                    &{display: flex; justify-content: center; align-items: center; gap: 10px;}
                    img{ width: 48px; height: 48px; border-radius: 100%; object-fit: cover; }
                    .network-img{border-radius: 5px;}
                    .profile-img{border-radius: 50px;}
                }
                .action-btn{background-color: #CD4A4A; color: #ffffff; padding: 5px 20px; border-radius: 5px; font-weight: normal; min-width: 120px;}
                .disabled{background-color: #D9D9D9; color: #9B9B9B; border: none;}
            }
            .network-details {
              & {  }
              .title {
                & { color: #000; font-weight: 600; }
              }
              .network-desc {
                & { font-weight: 400; width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 14px; color: #9e9e9e; }
              }
            }
        }
    }
}

@media screen and (max-width: 712px) {
    .columns-display .tile{ width: 90% !important; margin: auto; }
}
@media screen and (max-width: 640px) {
    .card-info .links { justify-content: center !important; } 
}