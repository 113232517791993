@media screen and (max-width: 480px) {
    .featured-container{
        .dashboard-container{
            &{ height: auto !important; padding: 30px 10px !important; }
            .dashboard{
                .dash-tabs{
                    &{ flex-direction: column; gap: 20px; }
                    .popular-networks{
                        &{ width: auto !important; }
                        .card-container{
                            &{ justify-content: flex-start !important; gap: 10px;}
                            .card-create{
                                &{ width: 90px !important; }
                            }
                        }
                    }
                }
                .engaged-users{
                    &{ width: auto !important;}
                    .card-container{
                        &{ gap: 10px; }
                        .card{
                            &{ width: 90px !important; }
                        }
                    }
                }

                .network-earning{
                    &{ width: auto !important; height: auto !important; }
                    .become-creator{
                        &{ width: auto !important; height: auto !important; padding: 15px 7px; }
                    }
                    .become-creator-btn{
                        &{ width: auto !important; }
                    }
                }               
            }
        }
        .featured-tabs-container{
            &{ padding: 0 14px !important;}
            .featured-nav-container{
                &{ gap: 10px !important; }
                span{
                    &{ font-size: 16px !important; }
                }

                .create-post{
                    span{ display: none !important; }
                }
            }
            .home-featured{
                .activities{
                    &{ display: none !important; }
                }
                .featured{
                    &{ width: auto !important; }
                    .hub-tab-container{
                        .featured-networks{
                            .networks-container::-webkit-scrollbar{
                                &{ display: none; }
                            }
                        }
                    }
                    .profile-container::-webkit-scrollbar{
                        &{ display: none; }
                    }
                    .networks{
                        .hub-img{
                            &{min-width: auto !important;}
                            img{ min-width: auto !important; width: 100% !important; }
                        }
                        .network-text{
                            &{ width: auto !important; padding: 0 10px !important; }
                            h2{ font-size: 18px !important; margin-bottom: 0 !important; }
                            h4{ margin-bottom: 0 !important; height: auto !important; }
                            .network-info{
                                &{ width: 100% !important; justify-content: space-between !important; }
                                span{
                                    &{ font-size: 14px !important; }
                                }
                            }
                        }
                    }
                }
            }
            .following-container{
                .discover-container{
                    &{ flex-direction: column; }
                    .posts-container{
                        &{ width: 100% !important; }
                    }
                    .recommend-container{
                        &{ width: 100% !important; }
                        .recommend-people{
                            &{ width: 100% !important; padding: 10px !important; }
                        }
                    }
                }
                .find-friend-container{
                    .find-friend{
                        &{ flex-direction: column; }
                        .search-bar{
                            &{ width: auto !important; }
                        }
                    }
                }
            }
            .discover-container{
                &{ flex-direction: column; }
                .posts-container{
                    &{ width: 100% !important; }
                    .post{
                        &{ width: 100% !important; }
                    }
                }
                .recommend-container{
                    &{ width: 100% !important; }
                    .recommend-people{
                        &{ width: 100% !important; }
                        .user{
                            &{ font-size: 16px !important;}
                        }
                    }
                }
            }
            .topic{
                .article-container::-webkit-scrollbar{
                    &{ display: none; }
                }
            }
        }
    }
    .user-profile-container{
        &{ width: auto !important; }
        .profile-container{
            &{ height: auto !important; margin-bottom:0 !important; }
        }
        .profile-container::-webkit-scrollbar{
            &{ display: none; }
        }
        .action-btns{
            .red-btn{ width: 130px !important; }
        }
    }
    .add-goal-lightbox{
        .create-post-container{
            &{ width: auto !important; height: 100% !important; }
        }
        .post-comments{
            &{ width: auto !important; height: 100% !important; }
        }
    }
}