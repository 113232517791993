html,
body {
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.custom-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 12px;
}

.main-content {
  color: #000;

  .custom-title {
    color: #000000;
    font-size: 48px;
    margin-bottom: 20px;
    line-height: 50px;
    font-weight: 600;
  }
  .position-relative {
    position: relative;
  }
  .position-absolute {
    position: absolute;
  }
  .d-flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }
  .text-white {
    color: #fff;
  }

  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .w-100 {
    width: 100%;
  }
  .w-90 {
    width: 90%;
  }
  .w-80 {
    width: 80%;
  }
  .section-padding {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.nav-container {
  .banner-social {
    display: flex;
    justify-content: center;
    .banner-social-item {
      margin: 0 10px;
    }
  }
}

.banner-section {
  position: relative;
  padding: 60px 0 190px;
  text-align: center;
  color: #fff;
  .banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 1600px;
    width: 100%;
  }
  .banner-title {
    margin-bottom: 20px;
    font-size: max(min(60px, 12vw), 1.5rem);
  }
  .banner-social {
    margin-bottom: 50px;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      gap: 15px;
    }
    .banner-social-item {
      margin: 0 10px;
    }
  }
  .banner-images {
    .banner-image-item {
      padding: 0 30px;
      .banner-image {
        min-width: 250px;
        width: 13vw;
        max-width: 290px;
      }
    }
  }
}
.community-section {
  .community-group {
    margin-left: -12px;
    margin-right: -12px;
    > .col {
      width: 50%;
      padding-left: 12px;
      padding-right: 12px;
    }
    .community-item-two {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .community-text {
      font-size: 20px;
    }
    .community-bg-image {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: 430px;
      z-index: -1;
    }
  }
}
.feature-section {
  .feature-group {
    margin-left: -12px;
    margin-right: -12px;
    > .col {
      width: 33.33%;
      padding-left: 12px;
      padding-right: 12px;
    }
    .feature-item {
      height: 100%;
      padding: 50px 30px;
      border-radius: 10px;
      background-color: #fff;
      .feature-img {
        display: inline-block;
        margin-bottom: 15px;
      }
      .feature-title {
        color: #000000;
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: 600;
      }
    }
  }
}

.contact-section {
  .contact-block {
    background-color: #fff;
    border-radius: 10px;
    padding: 50px;
  }
  .contact-text {
    display: block;
    font-size: 24px;
  }
  .contact-button {
    cursor: pointer;
    display: inline-block;
    background-color: #cd4a4a;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 5px;
    color: #ffffff;
    font-size: 24px;
    text-decoration: none;
    padding: 10px 15px;
    white-space: nowrap;
  }
}
.footer-section {
  padding-top: 4rem;
  padding-bottom: 2rem;
  background: #27252d;

  .footer-block {
    margin: 0 auto 50px;
    max-width: 500px;
    color: #fff;
    .footer-image {
      display: block;
      width: 100%;
      margin-bottom: 30px;
    }

    .footer-link {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: #fff;
        font-weight: 600;
        font-size: 24px;
      }
    }
  }
  .footer-copyright {
    text-align: center;
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .main-content {
    color: #000;

    .custom-title {
      font-size: 36px;
      line-height: 40px;
    }
    .section-padding {
      padding: 0;
    }
  }
  .community-section {
    .community-group {
      text-align: center;
      > .col {
        width: 100%;
      }
      .community-item-two {
      }
      .community-image {
        max-width: 400px;
      }
    }
  }


  .feature-section {
    .feature-group {
      > .col {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .contact-section {
    .contact-block {
      flex-wrap: wrap;

      .contact-text {
        margin-bottom: 30px;
      }
    }
  }
}
