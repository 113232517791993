/* You can add global styles to this file, and also import other style files */
@use './Styles/LoginNew.scss';
@use './Styles/Invite.scss';
@use './Styles/Live.scss';
@use './Pages/Networks/Networks.scss';
@use './Styles/Responsive/HomeResponsive.scss';
@use './Styles/Responsive/LoginResponsive.scss';
@use './Styles/Responsive/ContactusResponsive.scss';
@use './Styles/Responsive/PrivacyPolicyResponsive.scss';
@use "./Styles/Responsive/TermsOfUseResponsiveness.scss";
@use "./Styles/Responsive/profiledetailsresponsive.scss";
@use "./Styles/Responsive/Network-topics.scss";
@use "./Styles/Responsive/ProfileResponsive.scss";
@use "./Styles/Responsive/Navbar_Responsive.scss";
@use "./Styles/Responsive/FooterResponsive.scss";
@use "./Styles/Responsive/FeaturedResponsive.scss";
@use "./Styles/Responsive/NetworkResponsive.scss";
@use "./Styles/NetworkCreated.scss";
@use "./Styles/DirectMessages.scss";
@use "./Styles/ProfileEdit.scss";
@use "./Styles/ProfileDetails.scss";
@use "./Styles/Common.scss";
@use "./Styles/Navbar.scss";


// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$mainfont: 'Lato', sans-serif;
$mulish: 'Mulish', sans-serif;
$work-sans: 'Work Sans', sans-serif;
$lato: 'Lato', sans-serif;

$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189, 100, 0, 0.6);

// Theme Colours
$themecolor1: #f5f7fb; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #f1f2f6;
$menudividerdark: #ced6e0;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #57606f;
$titlecolor2: #227093;

// dark theme
// $themecolor1: #aaa69d;
// $themecolor2: #f7f1e3;

* {
  box-sizing: border-box;
  // outline: 1px solid #001aff;
}

// Global styles
html {
  height: 100%;
}
body {
  margin: 0px !important;
  font-family: $mainfont, sans-serif;
}
h1 {
  font-size: 26px;
  font-weight: 700;
  color: $titlecolor1;
}

// Miscellaneous
.toolbarspacer {
  flex: 1 1 auto;
}
.clear {
  clear: both;
}

// Button Styles
.redbtn {
  background: $buttoncolor1;
  color: #fff;
}
.redbtn:hover {
  color: #fff;
  background: $buttonaccentcolor1;
}
.greybtn {
  background: $buttoncolor2;
  color: $titlecolor1;
}
.greybtn:hover {
  color: $titlecolor1;
}
.filterbtn {
  background: transparent !important;
  color: $titlecolor1;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
  box-shadow: 0 0 0 30px $themecolor2 inset !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #d5d5d5;
  border: 0px none #d5d5d5;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cdcdcd;
}
::-webkit-scrollbar-thumb:active {
  background: #c7c7c7;
}
::-webkit-scrollbar-track {
  background: #efefef;
  border: 0px none #ffffff;
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  background: #efefef;
}
::-webkit-scrollbar-track:active {
  background: #efefef;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


.MuiChip-label {
  font-family: $mainfont;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
}

.horizontal-layout,
div.main {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $themecolor1 !important;
}
.mat-ink-bar {
  height: 4px;
}
.mat-tab-label-content {
  text-transform: uppercase;
  font-family: $mainfont;
}
.mat-tab-labels {
  background: #fff;
}

.top-navbar-container { height: 56px; }

.aside {
  & {
    font-family: $mainfont;
    width: 90%;
    background: #ecf0f1;
    padding: 0;
  }

  .header {
    width: 100%;
    padding: 15px 2%;
    margin: 0;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
  }
  .content {
    margin: 20px;
    text-align: justify;
  }
  .actionBtn {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
  }
}

.navbar {
  & {
    display: flex;
    flex-direction: column;
    width: 340px;
    background: #ced6e0;
  }

  a {
    & {
      display: block;
      width: 80%;
      margin: 0 auto;
      color: #fff;
      text-decoration: none;
      font-size: 1.2em;
      padding: 5px 10px;
      border-bottom: 1px solid #3d8fb6;
      border-top: 1px solid #277499;
      font-family: $mainfont;
    }

    &:first-of-type {
      border-top: none;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      color: #ecf0f1;
    }
  }
}
//the overdlow is set to hidden so the the app don't have to scroll uselessly
.App {
  font-family: $mainfont, sans-serif; /* overflow: hidden; */
  height: 100vh;
}
.main-container{
  display: flex;
  height: calc(100% - 56px);
}

/* --  Nav Bar  -- */
/* -- ========= -- */


/* -- Menu Main -- */
/* -- ========= -- */
.menu {
  & {
    height: 100vh;
    flex: 0 1 300px;
    background: $themecolor1;
    color: #fff;
    transition: 250ms;
    border-right: 2px solid #eee;
  }
  .company-logo {
    text-align: center;
    margin: 10px auto;
  }
  .company-logo img {
    max-width: 70%;
  }

  .profile-wrapper {
    & {
      color: $titlecolor1;
      margin: 25px 5px;
    }

    .profile {
      & {
        position: relative;
        margin: 0 auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #eee;
      }

      .profilePicture {
        & {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          position: relative;
          border-radius: 40%;
          background-color: #ddd;
        }
        img {
          width: 100%;
          border-radius: 45%;
          cursor: pointer;
        }
        .notify {
          position: absolute;
          right: 8px;
          bottom: 5px;
          background-color: #007c89;
          width: 10px;
          height: 10px;
          border: 2px solid #fff;
          border-radius: 50%;
        }
      }
      span {
        margin-bottom: 10px;
        cursor: pointer;
        text-align: center;
      }

      .edit {
        & {
          position: absolute;
          top: 0px;
          right: 0px;
        }
        .MuiSvgIcon-root {
          cursor: pointer;
          color: $titlecolor1;
          transition: 250ms ease-in-out;
        }
        .MuiSvgIcon-root:hover {
          color: #000000;
        }
      }
    }
  }

  nav {
    margin: 20px 12%;
  }

  nav a {
    & {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 auto;
      color: $titlecolor1;
      text-decoration: none;
      font-size: 1em;
      font-weight: 600;
      padding: 15px 10px;
      background: none;
      transition: 250ms ease-in-out;
    }
  }
  nav a:last-child {
    margin-top: auto;
  }

  nav .MuiSvgIcon-root {
    & {
      margin-right: 25px;
      background-color: #f2f2f2;
      border-radius: 40%;
      padding: 7px;
      box-shadow: 0px 0px 25px -8px #ffffff;
      transition: 250ms ease-in-out;
    }

    &:hover {
      background-color: #ffffff;
    }
  }

  nav a:first-of-type {
    border-top: none;
  }
  nav a:last-of-type {
    border-bottom: none;
  }
  nav a:hover {
    color: #000000;
  }
  //nav a span {display: none;}

  .menulogo {
    height: 32px;
    margin: 6px 0 2px -20px;
  }

  /*.container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 90%;margin: 0 auto;color: #fff;text-decoration: none;font-size: 1.1em;font-weight: 300;padding: 15px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .profile {background: $themeaccentcolor1;padding: 20px;text-align: center;font-size: 1.4em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 47px;border-radius: 50%;background: $themecolor1;padding-top: 13px;}
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px;}
    nav .profile .profiledetails span {font-size: 12px;}
    nav .submenu {padding: 5px 5px 5px 20px;font-size: 0.9em;border-top: 1px solid $submenudividerlight;border-bottom: 1px solid $submenudividerdark;}
    nav .sub {background: $themeaccentcolor1;}
    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #ecf0f1;}
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}
    .logo { padding: 15px 10px; width: 90%; border: none; line-height: 0; }
    .logo img { width: 150px; }*/
}

.login-register {
  & {
    width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 40px 80px;
  }
  .login {
    & {
      width: 500px;
      box-shadow: inset 0px 20px 50px rgba(222, 226, 230, 0.25);
      border-radius: 24px;
      background-color: #fff;
      padding: 50px 50px;
    }
    .form-heading {
      margin-top: 10px;
      color: #718194;
      font-size: 40px;
      font-weight: 600;
      line-height: 32px;
    }
    .form-wrapper {
      & {
        margin: 40px 0px 0px;
      }
      form {
        margin-bottom: 0px;
      }
      .MuiFormLabel-root {
        font-size: 14px;
      }
      .MuiOutlinedInput-input {
        font-size: 14px;
      }
      .field-label {
        margin-bottom: 5px;
      }
    }
    .hide {
      & {
        cursor: pointer;
        width: 35px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .remember-forgot-wrapper {
      & {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      a {
        color: #007c89;
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
      }
      .circle-checkbox {
        & {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .checkbox-round {
          margin-right: 10px;
          width: 15px;
          height: 15px;
          background-color: white;
          border-radius: 50%;
          vertical-align: middle;
          border: 1px solid #ddd;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
        }
        .checkbox-round:checked {
          background-color: #007c89;
        }
      }
      .forgot-password {
        color: #007c89;
      }
    }
    .no-account-wrapper {
      & {
        margin-top: 50px;
        color: #292f36;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      a {
        color: #007c89;
        text-decoration: none;
      }
    }
    .visibility {
      cursor: pointer;
    }
  }
  .freq-logo {
    img {
      width: 250px;
    }
  }
}

// register
.register-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-container {
  & {
    width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 40px 80px;
  }
  .register {
    & {
      width: 500px;
      box-shadow: inset 0px 20px 50px rgba(222, 226, 230, 0.25);
      border-radius: 24px;
      background-color: #fff;
      padding: 50px 50px;
    }
    .form-heading {
      margin-top: 10px;
      color: #718194;
      font-size: 40px;
      font-weight: 600;
      line-height: 32px;
    }
    .form-wrapper {
      & {
        margin: 40px 0px 0px;
      }
      form {
        margin-bottom: 0px;
      }
      .MuiFormLabel-root {
        font-size: 14px;
      }
      .MuiOutlinedInput-input {
        font-size: 14px;
      }
      .field-label {
        margin-bottom: 5px;
      }
    }
    .hide {
      & {
        cursor: pointer;
        width: 35px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .remember-forgot-wrapper {
      & {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      a {
        color: #007c89;
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
      }
      .circle-checkbox {
        & {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .checkbox-round {
          margin-right: 10px;
          width: 15px;
          height: 15px;
          background-color: white;
          border-radius: 50%;
          vertical-align: middle;
          border: 1px solid #ddd;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
        }
        .checkbox-round:checked {
          background-color: #007c89;
        }
      }
      .forgot-password {
        color: #007c89;
      }
    }
    .no-account-wrapper {
      & {
        margin-top: 50px;
        color: #292f36;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      a {
        color: #007c89;
        text-decoration: none;
      }
    }
    .visibility {
      cursor: pointer;
    }
  }
  .freq-logo {
    img {
      width: 250px;
    }
  }
}

.main {
  & {
    width: 75%;
    height: 100%;
    flex: 1 1 auto;
    background: #f6f7fa;
    color: $titlecolor1;
  }
  h1 {
    color: $titlecolor1;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 96px) !important;
  }

  .container {
    & {
      width: 100%;
      height: 100%;
    }

    header {
      & {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .header-cta {
        display: block;
        cursor: pointer;
        background: #007c89;
        color: #fff;
        padding: 6px 14px;
        border-radius: 3px;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 14px;
      }
    }
  }

  .MuiTable-root {
    color: $titlecolor1;
    font-family: $mainfont, sans-serif;
  }
  .MuiTableCell-head {
    color: $titlecolor1;
    font-weight: 700;
    font-family: $mainfont, sans-serif;
  }
  .MuiTableCell-body {
    color: $titlecolor1;
    font-family: $mainfont, sans-serif;
  }

  .table-tool {
    cursor: pointer;
  }

  .paywall {
    overflow-y: scroll;
  }

  .payment {
    button {
      background: #5469d4;
      font-family: Arial, sans-serif;
      color: #ffffff;
      border-radius: 4px;
      border: 0;
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      display: block;
      transition: all 0.2s ease;
      box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      width: 100%;
    }
    button:hover {
      filter: contrast(115%);
    }
    button:disabled {
      opacity: 0.5;
      cursor: default;
    }

    /* spinner/processing state, errors */
    .spinner,
    .spinner:before,
    .spinner:after {
      border-radius: 50%;
    }
    .spinner {
      color: #ffffff;
      font-size: 22px;
      text-indent: -99999px;
      margin: 0px auto;
      position: relative;
      width: 20px;
      height: 20px;
      box-shadow: inset 0 0 0 2px;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
    }
    .spinner:before,
    .spinner:after {
      position: absolute;
      content: '';
    }
    .spinner:before {
      width: 10.4px;
      height: 20.4px;
      background: #5469d4;
      border-radius: 20.4px 0 0 20.4px;
      top: -0.2px;
      left: -0.2px;
      -webkit-transform-origin: 10.4px 10.2px;
      transform-origin: 10.4px 10.2px;
      -webkit-animation: loading 2s infinite ease 1.5s;
      animation: loading 2s infinite ease 1.5s;
    }
    .spinner:after {
      width: 10.4px;
      height: 10.2px;
      background: #5469d4;
      border-radius: 0 10.2px 10.2px 0;
      top: -0.1px;
      left: 10.2px;
      -webkit-transform-origin: 0px 10.2px;
      transform-origin: 0px 10.2px;
      -webkit-animation: loading 2s infinite ease;
      animation: loading 2s infinite ease;
    }

    @keyframes loading {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @media only screen and (max-width: 600px) {
      form {
        width: 80vw;
        min-width: initial;
      }
    }
  }
}

.drawer {
  & {
    width: 80vw;
  }
  header {
    width: calc(100% - 60px);
    padding-left: 30px;
  }

  .MuiTabs-flexContainer {
    background: #efefef;
  }

  section {
    background: #efefef;
    padding: 30px;
    min-height: calc(100vh - 130px);
    width: calc(100% - 60px);
    overflow-y: scroll;
  }

  .double-column-form {
    & {
      display: flex;
      align-items: center;
    }
    .MuiInputBase-fullWidth {
      width: 98%;
    }
    #file-type {
      top: -5px;
      left: 15px;
    }
  }

  .margin-top {
    margin-top: 15px;
  }
  .aligned-right {
    justify-content: flex-end;
    margin-right: 1%;
  }
}

// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {
  // & { width: 1}

  // Main toolbar
  .pagetitle {
    background: transparent !important;
    width: 100%;
  }
  .mat-toolbar {
    background: transparent !important;
  }
  .mat-toolbar-row h1 {
    margin: 10px 0 10px 0;
    font-size: 1.5em;
    font-weight: 800;
    text-transform: uppercase;
    color: $titlecolor1;
    font-family: $mainfont;
  }
  .mat-toolbar-row button {
    font-weight: 800;
    font-family: $mainfont;
    text-transform: uppercase;
    margin-right: 5px;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    background: $themecolor4;
    border-top: 2px solid $themecolor1;
  }

  // Mat Table
  // ---------
  .mat-table {
    font-family: $mainfont;
    overflow: auto;
    max-height: 80vh;
  }
  .mat-header-row {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1000;
  }
  .mat-header-cell {
    font-size: 1em;
    color: $themecolor1;
    font-family: $mainfont;
    text-transform: uppercase;
    font-weight: 800;
  }
  .mat-menu-item {
    font-family: $mainfont;
    letter-spacing: 0;
    font-size: 12px;
    padding: 0 8px !important;
    height: auto !important;
    line-height: 36px !important;
    margin-bottom: 0;
  }

  .draft {
    background: #e55039;
    color: #fff;
    font-weight: 800;
    padding: 2px 5px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
  }
  .running {
    background: #78e08f;
    color: #fff;
    font-weight: 800;
    padding: 2px 5px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
  }
  .closed {
    background: none;
    color: #78e08f;
    font-weight: 800;
    padding: 2px 5px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
  }
}

.mat-column-responses {
  flex: 0 0 160px !important;
  text-align: center !important;
}
.mat-column-status {
  flex: 0 0 160px !important;
  text-align: center !important;
}
.mat-column-tools {
  flex: 0 0 80px !important;
  text-align: right;
}

app-lightbox {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  font-family: $mainfont;
  left: 0px;
  overflow-y: scroll;
}

.openlightbox {
  opacity: 1 !important;
}

.lightbox {
  & {
    position: absolute;
    border-radius: 30px;
    text-align: center;
    padding: 0px;
    background: #fff;
    max-width: 680px;
    z-index: 10001;
    min-height: 100px;
    top: 64px;
    font-family: $mainfont;
    left: 50%;
    transform: translate(-50%, 0px);
  }

  h1 {
    color: #1f4e96;
    font-size: 1.5em;
    margin: 0px;
    padding: 20px 0px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }

  h2 {
    font-size: 1.4em;
    color: #1f4e96;
    font-weight: 800;
    font-family: 'proxima-nova', sans-serif;
  }

  .lightboxfooter {
    justify-content: space-between !important;
    padding: 15px 30px;
  }
  .lightboxfooter button {
    font-family: $mainfont;
    background: #f1f2f6;
    font-weight: 600;
  }

  form {
    margin-bottom: 0px;
  }
  .mat-form-field {
    width: 100%;
  }
}

.defaultLightbox > div {
  padding: 30px;
  font-family: $mainfont;
  &:last-of-type {
    display: flex;
    justify-content: flex-end;
  }
  > p {
    text-align: justify;
  }
}

// Snackbar container
// ----------------
.mat-snack-bar-container {
  margin: 20px !important;
}
.mat-snack-bar-container-failure {
  background-color: $bg-failure;
}
.mat-snack-bar-container-success {
  background-color: $bg-success;
}
.mat-snack-bar-container-pending {
  background-color: $bg-pending;
  color: #fff;
}

// ========================= Smiler CSS ============================== //

// =================== Global CSS ===================

// theme colors
$smilercolor1: #1c97aa;

// background
$bgmain: #f6f7fa; // body

// form
$formtext: #1f4e96;
$formbg: #eff5ff;

// classes
body {
  background-color: $bgmain;
  font-family: $mainfont;
}
.full-frame {
  max-width: 1440px;
  margin: 0 70px;
  width: calc(100% - 140px);
}
.medium-frame {
  max-width: 991px;
  margin: 0 70px;
  width: calc(100% - 140px);
}
.cdk-overlay-container {
  z-index: 10002;
}

// spinner
.matspinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

// buttons
.yellowBtn {
  cursor: pointer;
  border: none;
  width: 95%;
  background-color: $smilercolor1 !important;
  color: #ffffff !important;
  font-family: $mainfont;
}
.outlineBtn {
  cursor: pointer;
  border: none;
  width: 100%;
  background-color: $smilercolor1 !important;
  color: #ffffff !important;
  font-family: $mainfont;
}
.yellowBtn.mat-button-disabled {
  opacity: 0.5;
}
.plainBtn {
  cursor: pointer;
  background-color: #ffffff !important;
  color: #007c89 !important;
  font-family: $mainfont;
}
.plainBtn.mat-button-disabled {
  opacity: 0.5;
}
.btn-large {
  cursor: pointer;
  box-shadow: none !important;
  border-radius: 12px;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 30px;
}
.btn-small {
  cursor: pointer;
  box-shadow: none !important;
  min-width: 120px !important;
  border-radius: 12px !important;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
}

.smiler-button-loader {
  & {
    transition: 0.1s !important;
  }
  &.smiler-button-loading {
    min-width: 50px !important;
    height: 50px !important;
    border-radius: 100% !important;
    padding: 0px !important;
  }
  .submit-loader {
    width: 40px;
  }
}

// form field
.form-field {
  .field-label {
    color: #292f36;
    font-size: 16px;
    font-weight: 500;
  }
  .mat-form-field {
    width: 100%;
  }
  input[type='password'],
  input[type='text'] {
    border: none !important;
    padding: 0px;
    height: auto;
    background-color: transparent;
  }
  .mat-form-field {
    & {
      width: 100%;
      font-family: $mainfont;
    }
    // .mat-form-field-wrapper { margin: 0; padding: 0; }
    .mat-form-field-infix {
      padding: 12px 0 20px 0;
      border-top-width: 12px;
    }
    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      border-width: 0;
    }
    input::placeholder {
      font-size: 14px;
      color: #d0d5db;
      font-family: $mainfont;
    }
    input {
      font-size: 14px;
      color: $formtext !important;
      font-family: $mainfont;
    }
    textarea {
      border: none;
      font-size: 14px;
      color: $formtext;
    }
    textarea::placeholder {
      color: #d0d5db;
    }
    .mat-form-field-outline {
      background: #f2f4fc;
      border-radius: 12px;
    }
    .mat-form-field-outline-thick .mat-form-field-outline-start,
    .mat-form-field-outline-thick .mat-form-field-outline-end,
    .mat-form-field-outline-thick .mat-form-field-outline-gap {
      border-width: 0px;
    }
    .mat-form-field-suffix {
      top: 7px;
    }
    .mat-form-field-suffix .material-icons {
      color: #e2e7f4;
    }
    .mat-form-field-suffix .material-icons.valid {
      color: #718194;
    }
    .mat-error {
      font-weight: 600;
    }
  }
}

// form field - input

.register-input {
  & {
    display: flex;
    align-items: center;
    border: 2px solid #fff;
    background-color: $themecolor1;
    border-radius: 10px;
    padding: 10px 15px;
  }
  input[type='text'] {
    & {
      width: 100%;
      border: none;
      background-color: $themecolor1;
      margin-right: 15px;
      font-family: $mainfont;
      font-size: 16px;
    }
    &:focus {
      outline: none;
    }
    &::-webkit-input-placeholder {
      color: #4e4e4e;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #4e4e4e;
    }
  }
  input[type='password'] {
    & {
      width: 100%;
      border: none;
      background-color: $themecolor1;
      margin-right: 15px;
      font-family: $mainfont;
      font-size: 16px;
    }
    &:focus {
      outline: none;
    }
    &::-webkit-input-placeholder {
      color: #4e4e4e;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #4e4e4e;
    }
  }
  .tick {
    & {
      width: 35px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .MuiSvgIcon-root {
      width: 35px;
      color: #c7c9d9;
    }
  }
}

// checkbox
.circle-checkbox {
  .mat-checkbox-frame {
    border-color: #c7c9d9;
    border-radius: 100%;
    border-width: 1px;
    background-color: #f2f2f5;
  }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #fffd00;
    border-radius: 100%;
  }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    background: #fffd00;
  }
  .mat-checkbox-checkmark-path {
    stroke: #292f36 !important;
  }
  .mat-checkbox-label {
    font-weight: 400;
    color: #4d5865;
    font-size: 14px;
  }
}

.circle-large-checkbox {
  .mat-checkbox-frame {
    border-color: #007c89;
    border-radius: 100%;
    border-width: 2px;
  }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #007c89;
    border-radius: 100%;
  }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    background: #007c89;
  }
  .mat-checkbox-checkmark-path {
    stroke: #fff !important;
  }
  .mat-checkbox-inner-container {
    height: 24px;
    width: 24px;
  }
}

// slider
.yellow-slider.mat-slider-horizontal {
  & {
    padding: 0px;
  }
  .mat-slider-wrapper,
  .mat-slider-track-wrapper,
  .mat-slider-track-background,
  .mat-slider-ticks-container,
  .mat-slider-ticks {
    height: 4px;
  }
  .mat-slider-track-fill {
    height: 6px;
  }
  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: #ffdb15;
  }
  .mat-slider-thumb {
    width: 30px;
    height: 30px;
    bottom: -15px;
  }
  .mat-slider-track-background {
    background-color: #fff7ad;
  }
  .mat-slider-track-wrapper {
    border-radius: 4px;
  }
}

// =================== Global CSS ===================

// =================== Header ===================

.header1 {
  & {
    position: fixed;
    background: #fff;
    z-index: 99;
  }
  .header-toolbar {
    & {
      padding: 0;
      justify-content: space-between;
    }
    // logo
    img.logo {
      margin-top: -10px;
    }
    // search
    .mat-form-field {
      & {
        background: $formbg;
        width: 320px;
        border-radius: 5px;
      }
      .mat-form-field-wrapper {
        margin: 0;
        padding: 0;
      }
      .mat-form-field-infix {
        padding: 2px 0 14px 0;
        border-top-width: 10px;
      }
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-width: 0;
      }
      input,
      input::placeholder {
        font-size: 14px;
        color: $formtext;
        font-family: $mulish;
      }
      .input-search {
        margin-right: 5px;
      }
    }
    // header right
    .header-right {
      & {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      img {
        margin: 0px 12px;
        cursor: pointer;
      }
    }
  }
  .full-frame {
    width: calc(100% - 140px);
  }
}
.header-spacer {
  padding-top: 64px;
}
.public-header {
  & {
    text-align: right;
    margin-top: 30px;
  }
  img {
    width: 50px;
  }
}

.app-wrapper {
  & {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
  }
  app-menu {
    flex: 0 0 300px;
    margin-right: 15px;
  }
  router-outlet + * {
    flex: 1 0 auto;
  }
}

// ========== menu ==========
.layout-menu {
  & {
    width: 250px;
  }
  .user-info {
    & {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid #c6c6c6;
      padding-bottom: 15px;
    }
    img {
      max-width: 52px;
      border-radius: 40%;
    }
    .user-name {
      margin-left: 10px;
      font-size: 14px;
      flex: 1 0 auto;
    }
    .menu-icon {
      & {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
      }
      span {
        width: 3px;
        height: 3px;
        border-radius: 100%;
        border: 1px solid #3483fa;
        margin: 0px 1px;
      }
    }
  }
  .smiler-menu {
    & {
      margin-top: 15px;
    }
    .menu-item {
      & {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 15px 0px;
        cursor: pointer;
      }
      .menu-text {
        margin-left: 15px;
        color: #656565;
        font-size: 16px;
        flex: 1 0 auto;
      }
      .menu-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0.2) 100%
        );
        box-shadow: 0px 12px 40px rgba(202, 202, 238, 0.4);
      }
      .menu-notifications {
        color: #1f4e96;
        font-size: 12px;
        background-color: #fffd00;
        padding: 2px 8px;
        border-radius: 10px;
        font-weight: 700;
      }
      &.active {
        .menu-icon {
          background: linear-gradient(90deg, #fffd00 0%, #ffffb3 100%);
          box-shadow: 0px 8px 16px rgba(255, 253, 0, 0.3);
        }
        .menu-text {
          font-weight: 600;
          color: #323232;
        }
      }
    }
  }
}

// =================== register ===================
.smiler-register {
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
  }
  .register-left {
    & {
      max-width: 1000px;
      min-width: 1000px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 40px 80px;
    }
    .register-wrapper {
      width: 400px;
      box-shadow: inset 0px 20px 50px rgba(222, 226, 230, 0.25);
      border-radius: 24px;
      background-color: #fff;
      padding: 50px 50px;
    }
    .form-heading {
      color: #718194;
      font-size: 40px;
      font-weight: 600;
      line-height: 32px;
    }
    .form-wrapper {
      & {
        margin: 30px 0px 0px;
      }
      form {
        margin-bottom: 0px;
      }
      .field-label {
        margin-bottom: 5px;
      }
    }
    .yellowBtn {
      margin-top: 30px;
    }
    .name-wrapper {
      & {
        display: flex;
        flex-wrap: wrap;
      }
      .form-field {
        flex: 0 0 calc(50% - 5px);
        width: calc(50% - 5px);
      }
      .first-name {
        margin-right: 5px;
      }
      .last-name {
        margin-left: 5px;
      }
    }
  }
  .register-right {
    & {
      flex: 0 0 55%;
      text-align: center;
    }
    .right-title {
      color: #4d5865;
      font-size: 20px;
      font-weight: 300;
    }
  }

  // logo
  .freq-logo {
    img {
      width: 250px;
    }
  }
}





// article container

.article-container {
  & {
    height: 100%;
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 95%;
    border: 0;
  }
  .MuiButtonBase-root {
    background: #007c89;
    color: #fff;
    margin: 0;
    margin-top: -6px;
    border-radius: 0px;
  }
  .MuiButton-root:hover {
    background-color: rgb(1, 113, 126);
  }
}


.emoji-lightbox {
  .emoji-box {
    & {
      position: relative;
      padding-top: 50px;
    }
    .close-emoji {
      & {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 25px;
        height: 25px;
        width: 25px;
        background: #eee;
        border-radius: 5px;
      }
    }
  }
}

.hidden {
  display: none;
}

.red-btn {
  & { text-transform: capitalize; font-size: 20px; width: 211px; height: 40px; color: #FFF; background: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
}
.unfollow-btn {
  & { text-transform: capitalize; font-size: 20px; width: 211px; height: 40px; color: #7d7d7d !important; background: #ffffff !important; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
}
.white-bg-btn {
  & { background: #FFF; color: #cd4a4a; }
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}



/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.StripeElement {
  height: auto;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@media only screen and (max-width: 1366px) {
  .live-container .live-stream-catalog .stream-container .stream .view-count {
    right: 5%;
  }
  .live-container .live-stream-catalog .stream-container .stream .we-are-live {
    right: 5%;
  }
  .live-container .live-stream-catalog .stream-container .stream .profile {
    left: 5%;
  }
}

// @media only screen and (max-width: 1368px) {
//   .network-wrapper .network-details-wrapper .overview-container .member-activity .members { width: 634px; }
// }

@media only screen and (max-width: 1000px) {
  // usermenu in dm
  .usermenu {
    width: 55px;
  }
  .usermenu .menu-container .search-friends input {
    display: none;
  }
  .usermenu .menu-container .search-friends .search-icon {
    cursor: pointer;
    display: block;
    height: 40px;
    width: 40px;
    margin-bottom: 25px;
    border: 2px solid #eee;
    padding: 10px;
    background-color: #fff;
    border-radius: 40px;
  }
  .usermenu .menu-container .friends {
    height: 50px;
    justify-content: center;
  }
  .usermenu .menu-container .friends img {
    margin: 0;
  }
  .usermenu .menu-container .friends span {
    margin: 0;
  }
  .usermenu .menu-container .friends .friend-name {
    display: none;
  }
  .usermenu .menu-container .friends .last-time {
    display: none;
  }
  .usermenu .menu-container .friends .active-span {
    height: 56px;
  }

  .dm-container .network-details-wrapper {
    width: 95%;
  }

  //home page
  //.home-container .nav-container nav .links {
  //  display: none;
  //}
  .home-container .nav-container nav .menu-icon {
    display: block;
  }

  .home-container .main-section-container .main-part-3-container {
    height: 800px;
  }
  .home-container .main-section-container .main-part-3-container .main-part-3 {
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding: 35px 25px;
  }
  .home-container
    .main-section-container
    .main-part-3-container
    .main-part-3
    .text-block {
    margin: 0;
  }
}

@media only screen and (max-width: 799px) {
  //featured

  .home-container .home-featured .featured-profile {
    flex-direction: column;
  }
  .home-container .home-featured .featured-profile .hr {
    display: none;
  }
  .home-container .home-featured .featured-hubs {
    height: 25%;
  }
  .home-container .home-featured .featured-profile {
    height: 75%;
  }
  .home-container .home-featured .featured-hubs .networks {
    height: 180px;
  }
  .home-container .home-featured .featured-hubs .networks .hub-img {
    height: 170px;
    width: 170px;
    min-width: 170px;
  }
  .home-container .home-featured .featured-hubs .networks .hub-img img {
    height: 170px;
    width: 170px;
    min-width: 170px;
  }
  .home-container .home-featured .featured-hubs .networks .hub-img .join-btn {
    width: 170px;
    min-width: 170px;
  }
  .home-container .home-featured .featured-profile .profile-wrapper {
    width: 100%;
    height: 35%;
  }
  .home-container .home-featured .featured-profile .hub-tab-container {
    width: 100%;
    height: 65%;
    padding: 10px;
  }
  .home-container .home-featured .featured-profile .profile-wrapper {
    gap: 0px;
  }
  .home-container
    .home-featured
    .featured-profile
    .profile-wrapper
    .profile-title {
    height: 15%;
  }
  .home-container
    .home-featured
    .featured-profile
    .profile-wrapper
    .profile-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 85%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .home-container
    .home-featured
    .featured-profile
    .profile-wrapper
    .profile-container
    .profile {
    margin: 0 10px;
    width: 120px;
    height: 120px;
  }
  .home-container
    .home-featured
    .featured-profile
    .profile-wrapper
    .profile-container
    .profile
    .profile-img {
    margin-inline: auto;
    width: 90px;
    height: 90px;
  }
  .home-container
    .home-featured
    .featured-profile
    .profile-wrapper
    .profile-container
    .profile
    .profile-img
    span {
    width: 90px;
    height: 90px;
  }
  .home-container
    .home-featured
    .featured-profile
    .profile-wrapper
    .profile-container
    .profile
    .profile-img
    img {
    width: 90px;
    height: 90px;
  }

  // home
  .home-container .nav-container nav .logo img {
    width: 200px;
  }

  .home-container .main-section-container .main-part-1-container .main-part-1 {
    flex-direction: column;
    gap: 0;
    padding: 0 25px;
  }
  .home-container
    .main-section-container
    .main-part-1-container
    .main-part-1
    .phone-img {
    width: 100%;
  }
  .home-container
    .main-section-container
    .main-part-1-container
    .main-part-1
    .text-content {
    width: 100%;
  }

  .home-container .main-section-container .main-part-2-container {
    height: 600px;
  }
  .home-container .main-section-container .main-part-2-container .main-part-2 {
    flex-direction: column-reverse;
    gap: 0;
    padding: 0 25px;
  }
  .home-container
    .main-section-container
    .main-part-2-container
    .main-part-2
    .com-img {
    width: 100%;
  }
  .home-container
    .main-section-container
    .main-part-2-container
    .main-part-2
    .text-content {
    width: 100%;
  }

  .home-container .main-section-container .main-part-3-container {
    height: 800px;
  }
  .home-container .main-section-container .main-part-3-container .main-part-3 {
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding: 35px 25px;
  }
  .home-container
    .main-section-container
    .main-part-3-container
    .main-part-3
    .text-block {
    margin: 0;
  }

  .home-container .main-section-container .main-part-4-container {
    height: 200px;
    padding: 0 25px;
  }
  .home-container .main-section-container .main-part-4-container .main-part-4 {
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    gap: 0;
    padding: 0;
  }
  .home-container
    .main-section-container
    .main-part-4-container
    .main-part-4
    h1 {
    text-align: center;
    font-size: 30px;
    width: 100%;
  }
  .home-container
    .main-section-container
    .main-part-4-container
    .main-part-4
    .MuiButtonBase-root {
    width: 190px;
    height: 45px;
  }

  .home-container .main-section-container .main-part-5-container {
    height: 150px;
  }
  .home-container footer .footer-container .terms a {
    text-decoration: underline;
  }

  // login page
  .login-register {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    gap: 25px;
  }

  // login page
  .register-container {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    gap: 25px;
  }

  //network sidebar
  .network-wrapper .network-sidebar .networks .network-info {
    display: none;
  }
  .network-wrapper .network-sidebar .networks .network-text {
    display: none;
  }
  .network-wrapper .network-sidebar {
    width: 20%;
    align-items: center;
  }
  .network-wrapper .network-sidebar .networks {
    width: 67px;
    border: 0px;
  }
  .network-wrapper .network-sidebar .networks img {
    border-radius: 15px;
  }
  .network-wrapper .network-sidebar .networks .initial {
    border-radius: 15px;
  }
  .network-wrapper .network-details-wrapper .overview-container .overview-img {
    height: 20%;
  }
  .network-wrapper .network-details-wrapper .network-details .network-info {
    width: 100%;
  }

  // network details
  .network-wrapper .network-details-wrapper {
    width: 82%;
  }
  .network-wrapper .network-details-wrapper .network-details {
    margin: 10px 5px;
    flex-direction: column-reverse;
  }
  .network-wrapper
    .network-details-wrapper
    .network-details
    .network-info
    .network-details-text {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .network-wrapper
    .network-details-wrapper
    .network-details
    .network-info-2
    .info-block
    img {
    height: 25px;
    width: 25px;
  }
  .network-wrapper
    .network-details-wrapper
    .network-details
    .network-info-2
    .info-block
    h1 {
    font-size: 25px;
  }
  .network-wrapper
    .network-details-wrapper
    .network-details
    .network-info-2
    .info-block
    h3 {
    font-size: 14px;
  }
  .network-wrapper .network-details-wrapper .network-details .network-info-2 {
    margin-top: 25px;
  }
  .network-wrapper .network-details-wrapper .overview-container .links {
    width: 100%;
  }

  // chat
  .network-wrapper
    .network-details-wrapper
    .chat-container
    .chat
    .messages
    .message {
    max-width: 100%;
  }
  .network-wrapper
    .network-details-wrapper
    .chat-container
    .chat
    .messages
    .message
    .message-text {
    padding: 5px;
  }

  // Chat box
  .network-wrapper .network-details-wrapper .chat-container .message-box {
    padding: 0;
    align-items: center;
  }

  // message
  .network-wrapper
    .network-details-wrapper
    .chat-container
    .chat
    .messages
    .message {
    gap: 10px;
    max-width: 92%;
  }
  .network-wrapper
    .network-details-wrapper
    .chat-container
    .chat
    .messages
    .message
    .chat-avatar
    .name {
    width: 40px;
  }
  .network-wrapper
    .network-details-wrapper
    .chat-container
    .message-box
    .input-style {
    padding: 0 10px;
    margin: 0 10px;
    border-radius: 5px;
  }
  .network-wrapper
    .network-details-wrapper
    .chat-container
    .message-box
    .input-style
    .input-style__control
    input {
    top: 0 !important;
  }
  .network-wrapper
    .network-details-wrapper
    .chat-container
    .message-box
    .common-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    margin-bottom: 0;
    padding: 0;
  }
  .network-wrapper .network-details-wrapper .chat-container .chat {
    min-height: 90%;
  }
  .network-wrapper
    .network-details-wrapper
    .chat-container
    .chat
    .messages
    .message
    .message-text
    .message-action
    .reaction {
    min-width: 25px;
  }

  .dm-container
    .network-details-wrapper
    .chat-container
    .chat
    .messages
    .message {
    gap: 10px;
    max-width: 92%;
  }
  .dm-container
    .network-details-wrapper
    .chat-container
    .chat
    .messages
    .message
    .chat-avatar
    .name {
    width: 40px;
  }
  .dm-container
    .network-details-wrapper
    .chat-container
    .message-box
    .input-style {
    padding: 0 10px;
    margin: 0 10px;
    border-radius: 5px;
  }
  .dm-container
    .network-details-wrapper
    .chat-container
    .message-box
    .input-style
    .input-style__control
    input {
    top: 0 !important;
  }
  .dm-container
    .network-details-wrapper
    .chat-container
    .message-box
    .common-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    margin-bottom: 0;
    padding: 0;
  }
  .dm-container .network-details-wrapper .chat-container .chat {
    min-height: 90%;
  }
  .dm-container
    .network-details-wrapper
    .chat-container
    .chat
    .messages
    .message
    .message-text
    .message-action
    .reaction {
    min-width: 25px;
  }

  //Dms
  .usermenu .menu-container .friends .active-span {
    height: 56px;
  }
  .usermenu {
    width: 15%;
  }
  .dm-container .network-details-wrapper {
    width: 85%;
  }

  // sub networks
  .network-wrapper .network-details-wrapper .sub-network-container {
    padding: 0 5px;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-networks
    .network {
    width: 100%;
    height: 85px;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-networks
    .network
    .initial {
    height: 100%;
    width: 85px;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-networks
    .network
    img {
    height: 100%;
    width: 85px;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-networks
    .network
    .network-info {
    gap: 5px;
    justify-content: space-evenly;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-network-header
    .new-network {
    margin: 0;
    width: 210px;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-network-header
    h2 {
    font-size: 16px;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-networks
    .network
    .network-info {
    width: 10%;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-networks
    .network
    .network-text {
    width: 65%;
    padding: 0 5px;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-networks
    .network
    .network-text
    h4 {
    max-height: 40px;
    overflow: hidden;
  }
  .network-wrapper
    .network-details-wrapper
    .sub-network-container
    .sub-networks
    .network
    .network-text
    h2 {
    height: 24px;
    overflow: hidden;
  }

  // attachment
  .network-wrapper
    .network-details-wrapper
    .attachment-container
    .attachments
    .attachment
    .att-info {
    width: 240px;
  }
  .network-wrapper
    .network-details-wrapper
    .attachment-container
    .attachment-header
    h2 {
    font-size: 18px;
  }
  .network-wrapper .network-details-wrapper .attachment-container {
    padding: 0 10px;
  }

  //lightbox
  .MuiBox-root {
    width: 90%;
    max-width: 90% !important;
    height: 85%;
    padding: 15px !important;
  }
  //message action
  .action-container {
    height: 100%;
    width: 100%;
  }

  .user-profile-container {
    width: 100%;
    height: 100%;
  }
  .user-profile-container .profile-container {
    height: 25%;
  }
  .user-profile-container nav {
    height: 5%;
  }
  .user-profile-container .tab-container {
    height: 70%;
  }
  .user-profile-container .profile-container .user-name {
    padding-left: 10px;
    width: 70%;
  }
  .user-profile-container .profile-container .user-img {
    height: 80px;
    width: 30%;
  }
  .user-profile-container .profile-container .user-img img {
    height: 80px;
    width: 80px;
  }
  .user-profile-container .profile-container .user-img span {
    height: 80px;
    width: 80px;
  }
  .user-profile-container nav .nav-container span {
    font-size: 14px;
  }
  .user-profile-container .profile-container .user-name .follow-btns button {
    width: 75px;
    height: 25px;
    font-size: 12px;
  }
  
  //create network
  .create-network-container {
    height: 100%;
    width: 100%;
  }
  .create-network-container .create-network .net-title-img .net-img {
    width: 85px;
    height: 85px;
    margin-left: 10px;
  }
  .create-network-container .create-network .net-title-img img {
    width: 85px;
    height: 85px;
    margin-left: 10px;
  }
  .create-network-container .create-network .net-title-img .net-title {
    flex-grow: 1;
    padding: 0 15px;
  }
  .create-network-container
    .create-network
    .net-title-img
    .net-title
    .net-title-input
    input {
    height: 30px;
  }
  .create-network-container
    .create-network
    .net-title-img
    .net-title
    .net-public-private {
    margin-top: 10px;
  }
  .create-network-container
    .create-network
    .net-title-img
    .net-title
    .net-public-private
    .net-public {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 30px;
  }
  .create-network-container
    .create-network
    .net-title-img
    .net-title
    .net-public-private
    .net-private {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 30px;
  }
  .create-network-container
    .create-network
    .net-topic
    .net-input
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 30px;
  }
  .create-network-container .create-network .net-headline .net-input input {
    height: 30px;
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }

  .home-container .nav-container nav .logo img {
    width: 145px;
  }
  .home-container
    .main-section-container
    .main-part-3-container
    .main-part-3
    .text-block {
    width: 300px;
  }

  //navbar
  .top-navbar-container .top-navbar {
    padding: 0 10px;
    height: 100%;
  }
  .nav-container {
    display: none;
  }
  .logo-freq img {
    width: 120px;
  }
  .nav-search {
    display: none;
  }
  .nav-icons .nav-icon:last-child {
    display: block;
  }

  //login
  .login-register {
    margin: 20px;
  }
  .login-register .login {
    width: 100%;
    padding: 30px 15px;
  }
  .register-input input[type='text'] {
    margin-right: 0px;
  }
  .login-register .login .form-heading {
    font-size: 30px;
  }

  //login
  .register-container {
    margin: 20px;
  }
  .register-container .register {
    width: 100%;
    padding: 30px 15px;
  }
  .register-input input[type='text'] {
    margin-right: 0px;
  }
  .register-container .register .form-heading {
    font-size: 30px;
  }

  //members
  .network-wrapper .network-details-wrapper .members-container {
    padding: 0 5px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members-header
    h2 {
    font-size: 18px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container {
    border: 1px solid #00000015;
    width: 100%;
    margin: 10px 0;
    height: 60px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-info
    .member-avatar {
    height: 40px;
    width: 40px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-info
    .member-avatar
    img {
    height: 40px;
    width: 40px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-info
    .member-avatar
    span {
    height: 40px;
    width: 40px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-info
    .member-name {
    padding-left: 5px;
    width: 100px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-info
    .member-name
    .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-info
    .member-name
    .user-name {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-action
    .owner {
    padding: 0 10px;
    margin-right: 0px;
    width: 65px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-action
    .member-btn {
    margin: 0;
    padding: 0;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-action {
    padding: 0 5px;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-action
    .member {
    width: 90px;
    padding: 0 10px;
    margin-right: 0;
  }
  .network-wrapper
    .network-details-wrapper
    .members-container
    .members
    .member-container
    .member-info {
    padding-right: 0px;
  }

  //network sidebar
  .network-wrapper .network-sidebar .networks .network-info {
    display: none;
  }
  .network-wrapper .network-sidebar {
    align-items: center;
  }
  .network-wrapper .network-sidebar .networks {
    width: 50px;
    border: 0px;
  }
  .network-wrapper .network-sidebar .networks img {
    border-radius: 10px;
    height: 50px;
    width: 50px;
    min-width: 50px;
  }
  .network-wrapper .network-sidebar .networks .initial {
    border-radius: 10px;
    height: 50px;
    width: 50px;
    min-width: 50px;
  }
}
