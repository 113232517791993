.login-register-page-desktop {
  & { position: relative; height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; overflow: hidden;}
  .login-register-container {
    & { position: relative; z-index: 10; width: 1200px; height: 640px; display: flex; align-items: center; justify-content: center; background: #ffffff; box-shadow: 0px 2px 10px #0000000F; border-radius: 20px; }

    .register {
      & { width: 50%; height: 100%; padding: 1rem; }
      .register-form {
        & { height: 100%; width: 100%; max-width: 400px; margin: 0 auto; padding: 1rem 0; }
        .logo {
          & { width: 100%; display: flex; align-items: center; justify-content: center; }
        }
        h1 {
          & { font-size: 34px; color: #000000; }
        }
        .register-inputs {
          & { display: flex; align-items: center; justify-content: center; gap: 1rem; flex-direction: column; }
        }
        .register-btn {
          & { font-size: 18px; font-weight: 700; margin-top: 4rem; width: 100%; height: 52px; background: #CD4A4A; border-radius: 5px; font-family: "Lato", sans-serif; }
        }
      }
    }

    .login {
      & { width: 50%; height: 100%; padding: 1rem; }
      .login-form {
        & { height: 100%; width: 100%; max-width: 400px; margin: 0 auto; padding: 1rem 0;  }
        .logo {
          & { width: 100%; display: flex; align-items: center; justify-content: center; margin-bottom: 4rem; }
        }
        h1 {
          & { font-size: 34px; color: #000000; margin-bottom: 4rem; }
        }
        .login-inputs {
          & { display: flex; align-items: center; justify-content: center; gap: 1rem; flex-direction: column; }
        }
        .login-btn {
          & { font-size: 18px; font-weight: 700; margin-top: 4rem; width: 100%; height: 52px; background: #CD4A4A; border-radius: 5px; font-family: "Lato", sans-serif; text-transform: uppercase; }
        }
      }
    }

    .overlay-wrapper {
      & { background-color: #ffffff; color: #FFF; position: absolute; height: 100%; width: 50%; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 5rem; transform: translateX(-50%); background: #000000 url("../assets/img/login/login-bg.png"); background-repeat: no-repeat; background-size: cover; background-position: 0 0; transition: 500ms cubic-bezier(0.91, 0.07, 0.48, 1); }
      h1 {
        & { font-size: 34px; color: #ffffff; }
      }
      p {
        & { width: 80%; font-size: 20px; color: #ffffff; text-align: center; }
      }
      .change-tab-btn {
        & { font-size: 18px; font-weight: 700; margin-top: 4rem; width: 100%; height: 52px; background: transparent; border: 2px solid #FFF; border-radius: 5px; font-family: "Lato", sans-serif;color: #fff; cursor: pointer; }
      }
    }
    .overlay-wrapper-login { border-radius: 19px 0 0 19px; transform: translateX(-50%); }
    .overlay-wrapper-register { border-radius: 0 19px 19px 0; transform: translateX(50%); }
  }

  .orange-sphere {
    & { position: absolute; top: -130px; right: -130px; width: 352px; height: 352px; border-radius: 100%; background: linear-gradient(175deg, rgba(205,74,85,1) 0%, rgba(219,81,69,1) 35%, rgba(239,98,48,1) 68%, rgba(245,135,39,1) 100%); }
  }

  .blue-sphere {
    & { position: absolute; bottom: -130px; left: -130px; width: 352px; height: 352px; border-radius: 100%; background: linear-gradient(175deg, rgba(27,160,162,1) 0%, rgba(28,147,170,1) 35%, rgba(29,136,177,1) 68%, rgba(57,115,172,1) 100%); }
  }

  .text-input {
    & { position: relative; height: 40px; width: 100%; margin: 8px 0; }
    .form-label {
      & { text-transform: capitalize; margin-bottom: 0.3rem; font-weight: 600; }
    }
    .form-input {
      & { height: 40px; width: 100%; display: flex; align-items: center; justify-content: center; }
      input {
        & { font-weight: 500; flex: 1; margin-right: auto; border: none; border-bottom: 1px solid #ededed; height: 40px; padding-left: .5rem; padding-right: 45px; background: #ffffff; font-size: 15px; font-family: 'Lato', sans-serif; }
        &:hover {
          & { border-bottom: 1px solid #858585; }
        }
        &:focus { outline: none; border-bottom: 1px solid #5c5c5c; }
      }
      img {margin: 0 .5rem;}
      .show-password {
        & { position: absolute; right: 5px; cursor: pointer; margin-right: 10px; background: transparent; }
      }
      .show-completion {
        & { position: absolute; right: 5px; margin-right: 10px; background: transparent; }
      }
    }
    .form-error {
      & { color: #D63F15; font-size: 12px; font-weight: 600; }
    }
  }

}

.login-register-page-mobile {
  & { overflow: hidden; position: relative; height: 100%; width: 100%; display: none; align-items: center; justify-content: center; padding: 1rem 0; }
  .login-register-container {
    & { position: relative; z-index: 10; width: 90%; max-height: 670px; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; background: #ffffff; box-shadow: 0px 2px 10px #0000000F; border-radius: 20px; }

    .mobile-forms {
      & { position: relative; height: 65%; width: 100%; display: flex; align-items: center; justify-content: center; min-height: 70vh; }
    }

    .register {
      & { position: absolute; top: 0; left: 0; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; padding: 1rem; transform: translateX(-200%); transition: 500ms cubic-bezier(0.91, 0.07, 0.48, 1); }
      .register-form {
        & { width: 100%; max-width: 400px; margin: 0 auto; padding: 1rem 0; }
        .logo {
          & { display: none; width: 100%; align-items: center; justify-content: center; }
        }
        h1 {
          & { font-size: 28px; color: #000000; }
        }
        .register-inputs {
          & { display: flex; align-items: center; justify-content: center; gap: 1rem; flex-direction: column; }
        }
        .register-btn {
          & { font-size: 18px; font-weight: 700; text-transform: uppercase; margin-top: 1rem; width: 100%; height: 52px; background: #CD4A4A; border-radius: 5px; font-family: "Lato", sans-serif; }
        }
      }
    }

    .login {
      & { position: absolute; display: flex; align-items: center; justify-content: center; top: 0; left: 0; width: 100%; height: 100%; padding: 1rem; transform: translateX(200%); transition: 500ms cubic-bezier(0.91, 0.07, 0.48, 1); }
      .login-form {
        & { width: 100%; max-width: 400px; margin: 0 auto; padding: 1rem 0;  }
        .logo {
          & { width: 100%; display: flex; align-items: center; justify-content: center; margin-bottom: 4rem; }
        }
        h1 {
          & { font-size: 28px; color: #000000; margin-bottom: 1rem; }
        }
        .login-inputs {
          & { display: flex; align-items: center; justify-content: center; gap: 1rem; flex-direction: column; }
        }
        .login-btn {
          & { font-size: 18px; font-weight: 700; text-transform: uppercase; margin-top: 1rem; width: 100%; height: 52px; background: #CD4A4A; border-radius: 5px; font-family: "Lato", sans-serif; }
        }
      }
    }
    .login-active {
      & { transform: translateX(0); }
    }
    .register-active {
      & { transform: translateX(0); }
    }

    .overlay-wrapper {
      & { background-color: #ffffff; color: #FFF;  height: 35%; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 1rem;  background: #000000 url("../assets/img/login/login-bg.png"); background-repeat: no-repeat; background-size: cover; background-position: 0 0; transition: 500ms cubic-bezier(0.91, 0.07, 0.48, 1); border-radius: 20px 20px 0 0; }
      h1 {
        & { font-size: 34px; color: #ffffff; }
      }
      p {
        & { display: none; width: 100%; font-size: 18px; color: #ffffff; text-align: center; }
      }
      .change-tab-btn {
        & { color: #FFF; font-size: 18px; font-weight: 700; text-transform: uppercase; margin-top: 1rem; width: 100%; height: 52px; background: transparent; border: 2px solid #FFF; border-radius: 5px; font-family: "Lato", sans-serif; }
      }
    }
  }

  .orange-sphere {
    & { position: absolute; top: -130px; right: -130px; width: 352px; height: 352px; border-radius: 100%; background: linear-gradient(175deg, rgba(205,74,85,1) 0%, rgba(219,81,69,1) 35%, rgba(239,98,48,1) 68%, rgba(245,135,39,1) 100%); }
  }

  .blue-sphere {
    & { position: absolute; bottom: -130px; left: -130px; width: 352px; height: 352px; border-radius: 100%; background: linear-gradient(175deg, rgba(27,160,162,1) 0%, rgba(28,147,170,1) 35%, rgba(29,136,177,1) 68%, rgba(57,115,172,1) 100%); }
  }

  .text-input {
    & { position: relative; height: 40px; width: 100%; margin: 8px 0; }
    .form-label {
      & { text-transform: capitalize; margin-bottom: 0.3rem; font-weight: 600; }
    }
    .form-input {
      & { height: 40px; width: 100%; display: flex; align-items: center; justify-content: center; }
      input {
        & { font-weight: 500; flex: 1; margin-right: auto; border: none; border-bottom: 1px solid #ededed; height: 40px; padding-left: .5rem; padding-right: 45px; background: #ffffff; font-size: 15px; font-family: 'Lato', sans-serif; }
        &:hover {
          & { border-bottom: 1px solid #858585; }
        }
        &:focus { outline: none; border-bottom: 1px solid #5c5c5c; }
      }
      img {margin: 0 .5rem;}
      .show-password {
        & { position: absolute; right: 5px; cursor: pointer; margin-right: 10px; background: transparent; }
      }
      .show-completion {
        & { position: absolute; right: 5px; margin-right: 10px; background: transparent; }
      }
    }
    .form-error {
      & { color: #D63F15; font-size: 12px; font-weight: 600; }
    }
  }

}



@media screen and (max-width: 600px) {
  .login-register-page-desktop { display: none !important; }
  .login-register-page-mobile { display: flex !important; }
  }  