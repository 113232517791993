.wrapper {
  .menu {
    z-index: 1;
    position: relative;
  }
  .wrapper {
    position: relative;
    .wrapper__main {
      display: flex;
      flex-direction: column;
      margin-bottom: 230px;
      .wrapper__main__bg {
        position: absolute;
        width: 100%;
        max-height: 300px;
        svg {
          height: 100vh !important;
        }
      }
      .wrapper__main__content {
        //position: absolute;
        z-index: 1;
        top: 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 60px;
          color: white;
          font-weight: 600;
          text-align: center;
          padding-top: 110px;
        }
        .updateInfo {
          font-size: 20px;
          color: white;
          text-align: center;
          padding-top: 16px;
        }
        .form {
          display: flex;
          align-self: center;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 90vw;
          max-width: 1400px;
          background-color: #fff;
          margin-top: 95px;
          border-radius: 10px;
          padding: 40px;
          p {
            font-size: 16px;
          }
          .policy {
            .policy__title {
              font-size: 24px;
              font-weight: bold;
            }
          }
          .summary {
            .summary__title {
              font-size: 24px;
              font-weight: bold;
            }
            .summary__subtitle {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}