:root {
    --speed: 0.4s;
}
.wrapper {
    position: relative;
    .wrapper__images {
        margin: 0 auto;
        padding-top: 50px;
        // height: 450px;
        height: 650px;
        width: 0;
        .wrapper__images__image {
            position: absolute;
            top: 50px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;

            .slider__slide__description {
                position: absolute;
                bottom: -120px;
                width: 100%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 10px #0000000F;
                border-radius: 10px;
                padding: 15px;
                
                height: 110px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;

                p {
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    font-size: 14px;
                    color: black;
                    width: 94%;
                }

                h1 {
                    padding: 0;
                    margin: 0;
                    font-size: 20px;
                    background: transparent linear-gradient(90deg, #1B9FA3 0%, #267EB1 20%, #6B559E 39%, #B04570 60%, #D24C50 79%, #F58726 100%) 0% 0% no-repeat padding-box;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .wrapper__images__one {
            transform: translateX(-140%);
        }
        .wrapper__images__two {
            transform: translateX(0%) scale(1.25);
            z-index: 2;
        }
        .wrapper__images__three {
            transform: translateX(140%);
        }
        .wrapper__images__four {
            transform: translateX(0%) scale(0.75);
        }
    }
}

.toRight__transform {
    position: absolute;
    animation: transformToRight linear var(--speed);
    animation-fill-mode: both;
    z-index: 3;
}

.toLeft__transform {
    animation: transformToLeft linear var(--speed);
    animation-fill-mode: both;
    z-index: 1;
}

.toCenter__transform {
    animation: transformToCenter linear var(--speed);
    animation-fill-mode: both;
    z-index: 2;
}

.toBehind__transform {
    animation: transformToBehind linear var(--speed);
    animation-fill-mode: both;
    z-index: 0;
}



@keyframes transformToRight {
    0% {
        transform: translateX(0%) scale(1.25);
    }
    50% {
        transform: translateX(70%) scale(1.2);
    }
    100% {
        transform: translateX(140%) scale(1);
    }
}

@keyframes transformToLeft {
    0% {
        transform: translateX(0%) scale(0.75);
    }
    50% {
        transform: translateX(-70%) scale(0.8);
    }
    100% {
        transform: translateX(-140%) scale(1);
    }
}

@keyframes transformToCenter {
    0% {
        transform: translateX(-140%) scale(1);
    }
    50% {
        transform: translateX(-70%) scale(1.2);
    }
    100% {
        transform: translateX(0%) scale(1.25);
    }
}

@keyframes transformToBehind {
    0% {
        transform: translateX(140%) scale(1);
    }
    50% {
        transform: translateX(70%) scale(0.8);
    }
    100% {
        transform: translateX(0%) scale(0.75);
    }
}
