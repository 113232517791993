.dm-container {
    & { height: 100%; display: flex; }
    .direct-message-chat-wrapper {
      & { width: calc(100% - 320px); height: 100%; display: flex; flex-direction: column; justify-content: space-between;}
      .direct-messages-chat-container {
        & { position: relative; width: 100%; height: 100%; overflow: hidden; display: flex; flex-direction: column; }
        .chat {
          & { position: relative; margin: 0 auto; width: 100%; max-width: 1000px; flex: 1; display: flex; align-items: flex-end; justify-content: flex-end; }
          .chat-filter-setting-icon {
            & { cursor: pointer; background: #ffffff; display: flex; align-items: center; justify-content: center; border-radius: 5px; padding: 10px; position: absolute; top: 10px; right: 35px; z-index: 100; }
          }
          .chat-filter-setting {
            & { height: 75vh; width: 400px; background: #ffffff; border-radius: 5px; position: absolute; top: 10px; right: 35px; z-index: 101; }
            .filter-box{ & { width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; } 
              .filter-header { & { padding: 5px 20px; width: 100%; padding-top: 20px; margin-bottom: -10px; position: relative; } 
                p { font-weight: 700; font-size: 22px; line-height: 0px; color: #000000; }
                img { position: absolute; width: 30px; top: 20px; right: 20px; cursor: pointer; }
              }
              .filter-content { & { width:100%; height: 100%; flex-shrink: 1; overflow-y: scroll; } 
                .single-filter { & { border-bottom: 1px solid #D9D9D9; padding: 0 20px; }
                  .filter-name { & { display: flex; justify-content: space-between; align-items: center; padding: 10px 0; cursor: pointer; } 
                    p { font-size: 18px; line-height: 0px; font-weight: 600; color: #000000; }
                    input { width: 15px; height: 15px; accent-color: #cd4a4a;}
                  }
                  .filter-section { & { width: 100%; } 
                    .input-box { & { position: relative; } 
                      input { background-color: #EEEEEE; border-radius: 5px; padding: 10px; width: 100%; outline: none; border: none; font-size: 16px; font-weight: 400; }
                      img { width: 30px; position: absolute; top: 5px; right: 10px; }
                    }
                    .suggestion-heading { font-size: 18px; font-weight: 600; color: #000000; }
                    .user-box{ & {display: flex; justify-content: space-between; align-items: center; margin: 20px 0;}
                      .user-details { & {display: flex; justify-content: flex-start; align-items: center;}
                      .initial {border-radius: 50px; width: 60px; height: 60px; display: flex; justify-content: center; align-items: center; margin-right: 10px;}
                        .user-info {
                          .name { font-size: 18px; font-weight: 600; color: #000000; }
                          .username { font-size: 16px; color: #9b9b9b;}
                        }
                        img{width: 60px; height: 60px; border-radius: 100px; margin-right: 10px;}
                      }
                      input { width: 15px; height: 15px; accent-color: #cd4a4a;}
                    }
                  }
                }
              }
              .filter-footer { & { width: 100%; height: fit-content; padding: 20px;}
                button { background: #CD4A4A; color: white; cursor: pointer; width: 100%; text-align: center; font-size: 16px; padding: 10px;}
              }
            }
          }
    
          .add-reaction {
            & { height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; z-index: 100; background: #ffffff; position: absolute;   bottom: -20px; left: 60px; box-shadow: 0 0 10px #aaaaaa8e; }
    
            .reactions {
              & { position: relative; height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; }
              img {
                & { cursor: pointer; width: 28px; height: 28px; transition: transform 200ms ease; }
                &:hover { transform: scale(1.1); }
              }
              .close-reaction { height: 28px; width: 28px; cursor: pointer; color: #000000; margin-left: 1rem; }
            }
          }
          .messages {
            & { position: relative; width: 100%; min-height: 100%; max-height: 100%; overflow-y: scroll; overflow-x: hidden; display: flex; flex-direction: column-reverse; align-items: flex-start; }
            &::-webkit-scrollbar { display: none; }
            .message {
              & { position: relative; max-width: 70%; margin: 0 10px 35px 0; display: flex; flex-direction: row-reverse; align-content: flex-end; align-items: center; justify-content: flex-end; gap: 25px; font-size: 15px;  }
              &:last-child { margin-top: 1rem; }
              .message-text {
                & { position: relative; padding: 10px; display: flex; flex-direction: column; gap: 5px; align-items: flex-start; word-break: break-word; box-shadow: 0px 2px 10px #0000000F; }
                strong { cursor: pointer; color: #3c80ff; display: contents; }
                .reply-msg {
                  & { height: 100%; width: 100%; }
                  .reply-content {
                    & { font-size: 15px; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 0 5px 0; border-left: 4px solid #007c8950; }
                    .name {
                      color: #3483fa;
                      font-weight: 700;
                    }
                    .content { width: 100%; max-height: 60px; overflow: hidden; text-overflow: ellipsis; }
                  }
                }
                .message-emote-time {
                  & { position: absolute; bottom: -28px; left: 0; right: 0; width: 275px; height: 25px; display: flex; align-items: center; justify-content: flex-start; gap: 5px; }
                  .time { font-size: 12px; color: #9e9e9e; }
                  .reaction {
                    & { background: none; height: 25px; display: flex; align-items: center; min-width: 30px; border-radius: 5px; margin: 0 2px; }
                    img { width: 20px; height: 20px; }
                    .reaction-count { height: 25px !important; background: none; font-size: 12px; padding: 0; margin: 0; }
                  }
                }
              }
              .message-emote-action {
                & { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); right: -125px; display: flex; align-items: center; justify-content: space-evenly; }
                .css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
                &:hover { display: flex !important; }
              }
    
              &:hover {
                .message-emote-action { display: flex !important; }
              }
    
              .chat-avatar {
                & { cursor: pointer; position: relative; width: 35px; height: 35px; border-radius: 20px; align-self: flex-start; }
                span { width: 35px; height: 35px; border-radius: 20px; }
                img { width: 35px; height: 35px; border-radius: 20px; object-fit: cover; }
                .name { cursor: pointer; width: 50px; position: absolute; bottom: -20px; left: 0; right: 0; font-size: 12px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
              }
              .chat-attachment { object-fit: contain; width: 100%; max-height: 500px; max-width: 500px; }
              span {
                & { position: relative; padding: 5px; min-height: 30px; border-radius: 7px; display: flex; align-items: center; justify-content: center; background-color: #ffffff; }
                .play-btn {
                  & { cursor: pointer; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; }
                  .css-i4bv87-MuiSvgIcon-root { color: #fff; height: 35px; width: 35px; transition: transform 250ms ease;
                    &:hover {
                      transform: scale(1.2);
                    }
                  }
                }
              }
            }
  
            .message-mine {
              & { align-self: flex-end; flex-direction: row; }
              .chat-avatar { display: none; }
              .message-emote-action {
                & { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); left: -40px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-evenly; }
                .css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
                &:hover { display: flex !important; }
              }
    
              &:hover {
                .message-emote-action { display: flex !important; }
              }
              .message-text {
                & { position: relative; max-height: 50%; word-break: break-word; background: #CBEFD7; }
                strong { cursor: pointer; color: #3c80ff; display: contents; }
                .message-emote-time {
                  & { position: absolute; bottom: -28px; left: auto; right: 0px; width: 240px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start; }
                  .time { font-size: 12px; }
                }
              }
              .add-reaction {
                & { height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; z-index: 100; background: #ffffff; position: absolute;   bottom: -20px; right: 0px; left: auto; box-shadow: 0 0 10px #aaaaaa8e; }
      
                .reactions {
                  & { position: relative; height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; }
                  img {
                    & { cursor: pointer; width: 28px; height: 28px; transition: transform 200ms ease; }
                    &:hover { transform: scale(1.1); }
                  }
                  .close-reaction { height: 28px; width: 28px; cursor: pointer; color: #000000; margin-left: 1rem; }
                }
              }
            }
    
            .no-messages {
              & { color: #989898; user-select: none; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; }
              .top-span { font-size: 20px; display: flex; align-items: center; justify-content: center; gap: 10px; }
            }
          }
        }
        .message-box-container {
          & {  width: 100%; background-color: #dadada; display: flex; align-items: center; justify-content: center; }
          .msg-edit {
            & { width: 100%; max-height: 65px; height: 65px; padding: 5px; top: -63px; left: 0; right: 0; background-color: #dadada; margin-top: 10px; }
            .reply-content {
              & { font-size: 15px; max-width: 1000px; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 auto; border-left: 4px solid #007c8950; }
              .name {
                & { width: 100%; color: #3483fa; font-weight: 700; display: flex; align-items: center; justify-content: space-between; }
                .MuiSvgIcon-root { margin: 0; cursor: pointer; color: #00000090; border: 2px solid #00000090; border-radius: 5px; }
              }
              .content { width: 90%; max-height: 40px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
            }
          }
        }
        .message-box {
          & { position: relative; min-height: 65px; width: 100%; margin: 0 auto; }
          .message-text-box {
            & { min-height: 65px; width: 100%; max-width: 1000px; margin: 0 auto; padding: 10px 0; padding-bottom: 12px; display: flex; align-items: flex-end; flex-shrink: 0;  }
            .input-style {
              & { max-width: 100%; max-height: 175px; margin-inline: 10px; word-break: break-word; flex: 1; -moz-appearance: none; -webkit-appearance: none; appearance: none; background-color: #fff; border-radius: 10px; border-width: 0; }
            &:empty::before {content: 'Type a message';font-size: 16px;opacity: 0.5;}
            &:focus { outline: none; }
            .emoji-icon { cursor: pointer; position: absolute; top: 8px; right: 5px; }
            & .input-style__control { max-width: 100%; min-height: 100% !important; width: 100%; padding: 0; border-radius: 10px; }
            & .input-style__control input { border: 0px !important; top: 10px !important; padding: 2px 13px !important; outline: none; max-width: 100%; min-width: 100%; }
            & .input-style__control textarea { overflow-y: scroll !important; max-height: 175px; border: 0px !important; outline: none; border-radius: 10px; }
            & .input-style__control textarea:empty::before { content: 'Type a message'; font-size: 16px; opacity: 0.5; }
            & .input-style__control input:empty::before { content: 'Type a message'; font-size: 16px; opacity: 0.5; }
            & .input-style__control textarea:focus { border: 0px !important; outline: none; }
            & .input-style__control input:focus { border: 0px !important; outline: none; }
            & .input-style__control .input-style__highlighter > strong { padding: 2px 0px; border-radius: 5px; font-weight: 700; }
            .input-style__highlighter { max-height: 175px; }
            .input-style__suggestions { display: flex; overflow: scroll !important; top: -165px !important; height: 150px; max-height: 150px; min-width: 150px !important; }
            .input-style__suggestions ul { overflow: scroll !important; width: 100%; }
            }
            .css-i4bv87-MuiSvgIcon-root { margin: auto; }
            
            //.input-style { padding: 7px 30px 7px 10px; width: calc(100% - 40px); border-radius: 3px; border: 1px solid #999; margin-bottom: 10px;}
            
            .attach-media {
              & { position: absolute; left: 1px; right: 0; top: -267px; width: 120px; background: #fff; border-radius: 3px; padding: 15px 10px; box-shadow: 3px 3px 15px #00000015; }
              .attach-btns {
                & { position: relative; }
                .arrow { position: absolute; bottom: -35px; left: 35px; right: 0;
                  div { height: 27px; width: 27px; background: #fff; transform: rotateZ(45deg); }
                }
                .icon-img {
                  & { cursor: pointer; margin: 10px 0; display: flex; flex-direction: column; align-items: center; transition: transform 250ms ease; }
                  span { font-size: 14px; color: #9f9fa1; font-weight: bold; }
                  
                  &:hover { transform: scale(1.05); }
                }
              }
            }
            .dm-textbox-option {
              & { cursor: pointer; position: relative; width: 40px; height: 40px; color: #000000; display: flex; align-items: center; justify-content: center; }
              &:hover,
              &:focus { outline: none; .icon { filter: grayscale(1); }}
              .icon { 
                & { display: block; width: 28px; height: 28px; font-size: 18px; text-align: center; filter: grayscale(1) opacity(0.5); transition: 0.2s; }
                &>svg { height: 28px; width: 28px; }
              }
            }
          }
        
          .textbox {
            & { min-height: 30%; padding: 15px; background-color: #fff; }
            
            textarea {
              & { resize: none; width: 100%; border: none; }
              &:focus { outline: none; }
            }
          }
        }
      }
    }
  }

  .friend-list-container {
    & { position: relative; width: 320px;}
    .search-friends {
      & { position: relative; z-index: 11; height: 70px; display: flex; align-items: center; justify-content: center;  }
      .search-icon {  }
      input[type=text] { font-size: 16px; height: 35px; width:250px; border: 2px solid #eee; padding:10px; background-color:#fff; border-radius:40px;}
      input[type=text]:focus {outline:none;}
    }
    .friend-list { 
      & { width:100%; height: calc(100% - 70px); display: flex; flex-direction: column; align-items: center; justify-content: flex-start; overflow-y: scroll; overflow-x: hidden;}
      .friends {
        & { position: relative; cursor: pointer; width: 100%; height: 55px; min-height: 55px; display:flex; align-items: center; justify-content: flex-start; gap: 12px; padding: 0 10px; border: 1px solid #eee;  }
        &:hover { background: #007C8935; }
        .user-avatar { object-fit: cover; width: 40px !important; height: 40px !important; border-radius: 100%;}
        .initial { border: 1px solid #fff; width: 40px !important; height: 40px !important; border-radius: 100%; display: flex; align-items: center; justify-content: center;}
        .unread-msg { position: absolute; right: 10px; display: flex; align-items: center; justify-content: center; height: 20px !important; width: 20px !important; border-radius: 50%; font-size: 12px; background: #ff0101bd; color: #fff; font-weight: 600; }
        .friend-name-container { 
          & { width: 200px !important; }
          .friend-name-time {
            & { display: flex; align-items: center; justify-content: flex-start; gap: 10px; }
            .friend-name { 
              & { max-width: 130px; font-size: 16px; font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
            }
            .last-time { font-size: 12px; }
          }
          .last-message { font-size: 12px; font-weight: 400; margin: 0; display: block; height: auto !important; margin-top: 2px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
        }
        .active-span { 
          & { position: absolute; right: -33px; height: 58px; }
          img { margin: 0;  height: 100% !important; }
        }
      } 
      .active { background-color: #007C89; color: #fff; }
      .active:hover { background-color: #007C89; color: #fff; }

      .searched-friends {
        & { position: relative;  width: 100%; height: 55px; display:flex; align-items: center; justify-content: flex-start; gap: 12px; padding: 10px; border: 1px solid #eee; }
        &:hover { background: #007C8935; }
        .user-avatar { cursor: pointer; object-fit: cover; width: 40px !important; height: 40px !important; border-radius: 100%;}
        .initial { cursor: pointer; width: 40px !important; height: 40px !important; border-radius: 100%; display: flex; align-items: center; justify-content: center;}
        .friend-name-container { 
          & { width: 200px !important;  }
          .friend-name { 
            & { cursor: pointer; max-width: 160px; font-size: 16px; font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
          }
          .friend-username { 
            & { cursor: pointer; max-width: 160px; font-size: 14px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
          }
        }
        .add-to-dm {
          & { cursor: pointer; }
        }
      }
    }

    .members-loading {
      & { z-index: 10; background: #f6f7fa; height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; position: absolute; top: 0; left: 0; }
    }
  }

  
  