@media screen and (max-width:1000px){
    .wrapper .card-container{
        .preferred-network{
            h1{
                font-size: 18px;
            }

            h2{
                font-size: 16px;
            }

        }
    }
}
@media screen and (max-width:700px){
    .wrapper .card-container{
        width: 70% !important;
    }
}
@media screen and (max-width:480px){
    .wrapper .card-container{
        &{ width: 90% !important; max-width: none !important; height: 75% !important; }
        
        .change-avatar-container{
            &{ min-width: auto !important; min-height: auto !important; height: 108px !important; width: 108px !important; }
        }

        .gradient-header{
            &{ min-height: auto !important; }
        }
        
        .card-info{
            &{ padding-top: 95px !important; }
        }
        .preferred-network{
            h1{
                font-size: 16px;
            }

            h2{
                font-size: 14px;
            }

        }
    }

    .profile-update-container{
        &{ height: auto !important; align-items: flex-start !important; }
    }

    .profile-update-container .notification-settings .notification-preference .item .item-details{
        &{ width: 100% !important; }
    }

    .profile-update-container .notification-settings .notification-preference .item .item-details .item-main-text{
        &{ font-size: 14px !important; }
    }
    
    .profile-update-container .notification-settings .notification-preference .item .item-details .item-secondary-text{
        &{ font-size: 14px !important; }
    }
}