// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$mainfont : 'Lato', sans-serif;
$mulish : 'Mulish', sans-serif;
$work-sans : 'Work Sans', sans-serif;
$lato :  'Lato', sans-serif;

$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #F5F7FB; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #f1f2f6;
$menudividerdark: #ced6e0;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #57606f;
$titlecolor2: #227093;

.top-navbar-container {
    // & {height: 8%;}
    .top-navbar { box-shadow: 0px 0px 6px #0000000d; position: relative; z-index: 100; display: flex; align-items: center; justify-content: space-between; background-color: #ffffff; padding: 0 30px;}
  }

  .nav-container {
    & { display: flex; align-items: center; justify-content: flex-start; width: 100%; }
    ul { list-style-type: none; display: flex; justify-content: flex-start; align-items: center; width: 100%; padding: 0; padding-left: 2rem; font-weight: bold; gap: 20px; margin: 0; padding: 8px 0; }
    li {  text-align: center;  }
    a { text-decoration: none; color: #4e4e4e; font-size: 14px; }
    .nav-search {
      & { position: relative; border: 2px solid #4e4e4e1e; border-radius: 5px; display: flex; align-items: center; max-width: 900px; margin-left: auto; }
      .MuiSvgIcon-root { margin: 0 10px; width: 34px; color: #4e4e4e1e; height: 34px; }
      input { flex: 1; font-size: 16px; outline: none; border: none; padding: 10px 0; border-radius: 20px; }
  
      .search-results-mask { position: fixed; z-index: 10; height: 100%; width: 100%; top: 50px; left: 0; }
  
      .search-results {
        & { position: absolute; margin-inline: auto; top: 40px; right: 0; left: 0; padding-bottom: 10px; height: auto; width: 85%; background: #fff; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; z-index: 100; box-shadow: 0px 30px 50px #00000045; }
  
        .sr-networks {
          .heading { margin: 5px 25px;  h1 { font-size: 18px; margin: 0; font-weight: 800; } }
          .network-container {
            & { display: flex; width: 95%; margin-inline: auto; align-items: center; height: 40%; flex-wrap: nowrap; gap: 20px; overflow-x: auto; overflow-y: hidden; padding: 0 15px; }
            .networks {
              & { margin: 5px 0; height: 130px; max-width: 100px; position: relative; display: flex; flex-direction: column; align-items: center; }
              .hub-img {
                  & { cursor: pointer; background: #00000060; display: flex; align-items: center; justify-content: center; position: relative; height: 90px; width: 90px; min-width: 90px; object-fit: contain; border-radius: 20px; }
                  img { height: 90px; width: 90px; min-width: 90px; object-fit: cover; border-radius: 20px; background-color: #fff; }
              }
              .initial { height: 90px; width: 90px; min-width: 90px; border-radius: 20px; display: flex; align-items: center; justify-content: center; }
              .network-text {
                  & { width: 100%; height: 35px; margin-top: 5px; display: flex; flex-direction: column; align-items: center; justify-content: center;}
                  span { text-align: center; height: 100%; font-size: 12px; font-weight: 700; overflow: hidden; }
              }
            }
            .no-network {
              & { margin: 5px 0; height: 130px; width: 100%; position: relative; display: flex; align-items: center; justify-content: center; }
            }
          }
        }
        
        .sr-profiles {
          .heading { margin: 5px 25px; h1 { font-size: 18px; margin: 0; font-weight: 800; } }
          .profile-container {
            & { display: flex; width: 95%; margin-inline: auto; align-items: center; height: 40%; flex-wrap: nowrap; gap: 20px; overflow-x: auto; overflow-y: hidden; padding: 0 15px; }
            .profile {
              & { margin: 5px 0; height: 130px; max-width: 90px; position: relative; display: flex; flex-direction: column; align-items: center; }
              .profile-img {
                  & { cursor: pointer; background: #00000060; display: flex; align-items: center; justify-content: center; position: relative; height: 90px; width: 90px; min-width: 80px; object-fit: contain; border-radius: 100%; }
                  img { height: 90px; width: 90px; min-width: 90px; object-fit: cover; border-radius: 100%; background-color: #fff; }
                  span { height: 90px; width: 90px; min-width: 90px; display: flex; align-items: center; justify-content: center; object-fit: cover; border-radius: 100%; }
              }
              .initial { height: 80px; width: 80px; min-width: 80px; border-radius: 20px; display: flex; align-items: center; justify-content: center; }
              .profile-name {
                & { width: 130px; height: 35px; margin-top: 5px; display: flex; flex-direction: column; align-items: center; justify-content: center;}
                span { text-align: center; height: 100%; font-size: 12px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; }
              }
            }
            .no-user {
              & { margin: 5px 0; height: 130px; width: 100%; position: relative; display: flex; align-items: center; justify-content: center; }
            }
          }
        }
  
        .sr-dms {
          .heading { margin: 5px 25px; h1 { font-size: 18px; margin: 0; font-weight: 800; } }
          .dm-container {
            & { display: flex; width: 95%; margin-inline: auto; align-items: center; height: 40%; flex-wrap: nowrap; gap: 20px; overflow-x: scroll; overflow-y: hidden; padding: 0 15px; }
            .dms {
              & { height: 90px; width: 200px; position: relative; display: flex; align-items: center; gap: 15px; }
              .dm-img {
                  & { cursor: pointer; background: #00000060; display: flex; align-items: center; justify-content: center; position: relative; height: 60px; width: 60px; min-width: 60px; object-fit: contain; border-radius: 100%; }
                  img { height: 60px; width: 60px; min-width: 60px; object-fit: contain; border-radius: 20px; background-color: #fff; }
              }
              .initial { height: 60px; width: 60px; min-width: 60px; border-radius: 20px; display: flex; align-items: center; justify-content: center; }
              .dm-text { 
                  & { display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
                  .dm-user { height: 15px; width: 120px; text-align: start; height: 100%; font-size: 14px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; }
                  .dm-last { color: #797979; height: 13px; width: 120px; text-align: start; height: 100%; font-size: 12px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; }
              }
            }
          }
        }
      }
    
      ::placeholder {
        color: #4e4e4e40;
        font-family: "lato", sans-serif;
        font-weight: bold;
      }
  
      :-ms-input-placeholder {
        color: #4e4e4e40;
      }
      
      ::-ms-input-placeholder {
        color: #4e4e4e40;
      }
    }
  }


.logo-freq {
    img {width: 150px; cursor: pointer;}
  }


  .search {
    & {width: 100%; display: flex; align-items: center; background-color: $themecolor1; border-radius: 10px; padding: 10px 30px; }
    input[type=text] {
      & {width: 100%; border: none; background-color: $themecolor1; margin-right: 15px;}
      &:focus {outline: none;}
      &::-webkit-input-placeholder {color: #4e4e4e; }
      &::-moz-placeholder { /* Firefox 19+ */ color: #4e4e4e; }
    }
  }

  .nav-icons {
    & { position: relative; display: flex; align-items: center; justify-content: center; }
    .MuiSvgIcon-root { margin: 0 10px; cursor: pointer; color: #007c89; }
    img { cursor: pointer;}

    .add { svg { border: 2px solid #007c89; border-radius: 100%;} }

    .hub-actions {
        & { z-index: 100; position: absolute; left: -45px; right: 0; top: 47px; width: 130px; background: #fff; border-radius: 3px; padding: 15px 10px; box-shadow: 0px -10px 20px #00000025;}
        .action-btns {
          & { position: relative; }
          .arrow {
            & { position: absolute; top: -30px; left: 42px; right: 0; }
            div { height: 27px; width: 27px; background: #fff; transform: rotateZ(45deg); }
          }
          .icon-img {
            & { cursor: pointer; margin: 15px 0; display: flex; flex-direction: column; align-items: center; gap: 5px; transition: transform 250ms ease; }
            span { font-size: 14px; color: #9f9fa1; font-weight: bold; }

            &:hover { transform: scale(1.05); }
          }
        }
  }

    .nav-icon {
      & { position: relative; height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; }
      a { height: 40px; width: 40px; text-decoration: none; }
      img {width: 40px; height: 40px; object-fit: cover; border-radius: 100%;}
      .avatar-text {width: 40px; height: 40px;  border-radius: 100%;}
      .nav-icon-notification {
        color: #CD4A4A;
        z-index: 1000000;
      }
      &:last-child { display: none; margin-left: 10px; }
      .open-menu { 
        & {  height: 100%; width: 100%; }
        .MuiSvgIcon-root { width: 40px; height: 40px; margin: 0; }
      }
      .notify {position: absolute; right: -2px; bottom: 2px; background-color: #007c89; width: 10px; height: 10px; border: 2px solid #fff; border-radius: 50%;}
    }

    .activity-feed-modal {
      position: fixed;
      z-index: 100000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      .activity-feed-modal-mask {
        position: absolute;
        z-index: 90000;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
      }

      .activity-feed-modal-container {
        position: absolute;
        height: 70%;
        width: 40%;
        z-index: 100000;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 10px #00000014;
        border-radius: 10px;
      }

      .activities {
        & { 
          width: 100%; 
          margin: 30px 0; 
          display: flex; 
          flex-direction: column; 
          align-items: center; 
          gap: 32px; 
          position: absolute;
          width: 40%;
          z-index: 100000;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 2px 10px #00000014;
          border-radius: 10px;
        }
        .activity-feed {
          & { display: flex; flex-direction: column; width: 100%; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 3px 10px #0000000D; border-radius: 10px; }
          .activity-header {
            & { width: 100%; padding: 24px; display: flex; align-items: center; justify-content: space-between; }
            h1 { color: #000; font-size: 28px; margin: 0;  }
            .view-more { cursor: pointer; color: #9B9B9B; }
          }
          .activity-container {
            & { padding: 0 24px 24px 24px; }
            .activity {
              & { height: 80px; width: 100%; margin-top: 15px; display: flex; align-items: center; gap: 15px;  }
              .avatar {
                & { height: 80px; width: 80px; border-radius: 100%; }
                img { height: 80px; width: 80px; border-radius: 100%; object-fit: cover; }
                span { height: 80px; width: 80px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .hub-avatar {
                & { height: 80px; width: 80px; border-radius: 5px; }
                img { height: 80px; width: 80px; border-radius: 5px; object-fit: cover; }
                .initial { height: 80px; width: 80px; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
              }
              .activity-details {
                .user-name {
                  & { font-weight: 700; color: #17170F; }
                }
                .activity-description { 
                  & { max-width: 100%; min-width: 100%; display: flex; gap: 5px; align-items: center; justify-content: flex-start; }
                  .description { max-width: 160px; height: 20px; overflow: hidden; color: #17170F; font-size: 14px; text-overflow: ellipsis; }
                  .dot { height: 5px; width: 5px; }
                  .time { color: #9B9B9B; font-size: 12px; }
                 }
              }
            }
          }
        }
  
        .recent-messages {
          & { display: flex; flex-direction: column; width: 332px; max-height: 535px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 3px 10px #0000000D; border-radius: 10px; }
          .messages-header {
            & { width: 100%; padding: 24px; display: flex; align-items: center; justify-content: space-between; }
            h1 { color: #000; font-size: 24px; margin: 0;  }
            .view-more { cursor: pointer; color: #9B9B9B; }
          }
          .messages-container {
            & { padding: 0 24px 24px 24px; overflow: hidden;  }
            .message {
              & { height: 60px; width: 100%; margin-top: 15px; display: flex; align-items: flex-start; gap: 15px;  }
              .avatar {
                & { position: relative; height: 40px; width: 40px; border-radius: 100%; }
                img { height: 40px; width: 40px; border-radius: 100%; object-fit: cover; }
                span { height: 40px; width: 40px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                .online { position: absolute; width: 10px; height: 10px; right: 1px; bottom: 1px; background: #1AB53F; border: 1px solid #fff; border-radius: 100%;}
              }
              .messages-details {
                & { position: relative; width: calc(100% - 40px); }
                .user-name {
                  & { display: flex; align-items: center; gap: 15px; }
                  .name { font-weight: 700; color: #17170F; }
                  .time { font-size: 12px; color: #9B9B9B; }
  
                }
                .messages-text { 
                  & { font-size: 14px; height: 35px; width: 100%; color: #17170F; overflow: hidden; text-overflow: ellipsis; }
                 }
                 .message-count {
                  & { position: absolute; top: 0; right: 0; background: #E71D1D; color: #fff; font-size: 10px; height: 20px; width: 20px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                 }
              }
            }
          }
        }
      }
    }
    
    .drowdown-menu {
      position: absolute;
      z-index: 10000;
      top: 55px;
      right: 0;
      width: 260px;
      height: auto;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 10px #0000000D;
      border-radius: 5px;
      opacity: 1;

      display: flex;
      flex-direction: column;

      padding: 10px 20px;
      
      .drowdown-menu-element {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        h1 {
          text-align: left;
          font-family: 'Lato';
          font-size: 16px;
          font-style: normal;
          font-weight: medium;
          letter-spacing: 0px;
          color: #17170F;
          opacity: 1;
        }

        img {
          height: 24px;
          width: 24px;
          margin-right: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {.nav-search{display:none !important;}}
