.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .wrapper__element{
    z-index: 1;
    position: relative;
  }
  .wrapper__tip-left {
    top: 50% !important;
    right: calc(100% + 30px);
    transform: translateY(-50%);
    &::before {
      right: -21px !important;
      top: 50% !important;
      left: auto !important;
      transform: translateY(-50%) rotateZ(90deg) !important;
    }
  }
  .wrapper__tip {
    width: 420px;
    background-color: #fff;
    box-shadow: 0 3px 10px #0000001C;
    border-radius: 5px;
    position: absolute;
    padding: 16px;
    z-index: 1;
    top: calc(100% + 30px);
    &::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 10px solid transparent;
      border-bottom: 25px solid white;
      border-right: 10px solid transparent;
      left: 50%;
      transform: translateX(-50%);
      top: -24px;
      border-top-right-radius: 100px;
    }
    .wrapper__tip__title,
    .wrapper__tip__subtitle {
      color: black;
      text-align: center;
    }
    .wrapper__tip__title {
      font-size: 20px;
      font-weight: bold;
      padding: 16px 0;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 40px;
      button {
        height: 40px;
        width: 75px;
        font-family: "Lato", sans-serif;
      }
      button:first-child {
        color: #cd4a4a;
        border-color: #cd4a4a;
      }
      .counter {
        white-space: nowrap;
        color: black;
        font-size: 20px;
        font-weight: bold;
      }
      button:last-child {
        background-color: #CD4A4A;
        color: white;
        font-weight: 600;
      }
    }
  }
}