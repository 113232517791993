.network-created-container{
    &{ width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; }
    img{ width: 100%; height: 100%; }
    .network-created-box{
        &{ background: white; width: 400px; padding: 20px; text-align: center; border-radius: 10px; }
        .network-created-tickmark-img{
            &{ width: 65%; margin: auto; }
        }
        .network-created-msg{
            h1{
                font-size: 24px; margin-bottom: 0;
            }
            h5{
                font-size: 18px; margin-top: 5px;
            }
        }
    }
}