.create-network-page-container {
  & { padding: 1rem 0; width: 100%; height: 100%;  overflow-x: scroll; }
  .create-network {
    & { display: flex; flex-direction: column; min-height: 600px; padding-bottom: 10px; margin: 0 auto; width: 800px; background: #FFF; position: relative; border-radius: 15px; box-shadow: 0 0 20px rgba(204, 204, 204, 0.4196078431); }

    .header-steps-container {
      h2 {
        & { padding: 1rem; color: #000000; }
      }
      .steps {
        & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
        .step {
          & { position: relative; flex: auto; display: flex; align-items: center; justify-content: center; height: 43px; }
          &.active {background: #FFDFDF; color: #CD4A4A; font-weight: 600; }
          &.active::after { z-index: 100; position: absolute; right: -17px; content: ''; height: 30px; width: 30px; border-right: 2px solid #fff; border-top: 2px solid #fff; background: #FFDFDF; transform: rotate(45deg);}
          &.active-last {background: #FFDFDF; color: #CD4A4A; font-weight: 600; }
          &:last-child::after { display: none; }
        }
      }
    }


    .create-network-all-steps-content-container{
      &{ overflow-y: scroll; margin-bottom: 50px; display: flex; flex-direction: column; justify-content: center; }
      
        .network-details-container {
          .network-image-container {
            & { padding: 0 1rem; margin-top: 1.5rem; height: 275px; display: flex; align-items: center; justify-content: center; }
            .image-container {
              & { cursor: pointer; height: 270px; width: 270px; background: #EEEEEE; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
            }
          }
          .network-name-status-container {
            & { padding: 0 1rem; margin-top: 1.5rem; display: flex; align-items: center; justify-content: space-between; gap: 1rem; }
            .network-name-input {
              & { width: 50%; height: 40px; border: none; border-bottom: 2px solid #ccc; padding: 0 .5rem; transition: 250ms ease; }
              &:focus { outline: none; border-bottom: 2px solid #959595; }
            }
            .network-status {
              & { width: 50%; display: flex; align-items: center; justify-content: center; }
              .status-public {
                & { cursor: pointer; height: 40px; width: 125px; background: #EEEEEE; color: #9B9B9B; border-radius: 4px 0 0 4px; display: flex; align-items: center; justify-content: center; gap: 10px; }
                &.active { background: #1AB53F; color: #FFF; }
              }
              .status-private {
                & { cursor: pointer; height: 40px; width: 125px; background: #EEEEEE; color: #9B9B9B; border-radius: 0 4px 4px 0; display: flex; align-items: center; justify-content: center; gap: 10px; }
                &.active { background: #1AB53F; color: #FFF; }
              }
            }
          }
    
          .select-topic-container {
            & { padding: 0 1rem; margin-top: 1.5rem; width: 100%; display: flex; align-items: center; justify-content: center;  }
          }
    
          .headline-description-container {
            & { padding: 0 1rem; margin-top: 1.5rem; display: flex; align-items: center; justify-content: space-between; gap: 1rem; }
            .network-headline-input {
              & { width: 50%; height: 40px; border: none; border-bottom: 2px solid #ccc; padding: 0 .5rem; transition: 250ms ease; }
              &:focus { outline: none; border-bottom: 2px solid #959595; }
            }
            .network-description-input {
              & { width: 50%; height: 40px; border: none; border-bottom: 2px solid #ccc; padding: 0 .5rem; transition: 250ms ease; }
              &:focus { outline: none; border-bottom: 2px solid #959595; }
            }
          }
    
          .network-type-container {
            & { padding: 0 1rem; margin-top: 1.5rem; display: flex; align-items: center; }
          }
    
        }
    
        .network-moderation-container {
          & { padding: 0 2rem; }
          .moderation-flagged {
            & { margin-top: 1.5rem; }
            .title { font-weight: 600; }
            .range-slider {
              & { margin: 1rem 0; }
              .MuiSlider-valueLabelOpen {
                & { top: 50px; background: transparent; color: #000000; }
              }
    
            }
            .comment-container {
              & { display: flex; align-items: center; gap: 1rem; }
              .profile {
                & { display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 8px; }
                .profile-image {
                  & { height: 30px; width: 30px; }
                  img { height: 100%; width: 100%; border-radius: 100%; }
                }
                .profile-name {
                  & { font-size: 10px; }
                }
              }
              .message-text { 
                & { font-weight: 500; background: #FFF; box-shadow: 0px 3px 6px #00000023; padding: 8px 20px; border-radius: 3px; }
              }
            }
          }
          .moderation-removed {
            & { margin-top: 2rem; }
            .title { font-weight: 600; }
            .range-slider {
              & { margin: 1rem 0; }
              .MuiSlider-valueLabelOpen {
                & { top: 50px; background: transparent; color: #000000; }
              }
    
            }
            .comment-container {
              & { display: flex; align-items: center; gap: 1rem; }
              .profile {
                & { display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 8px; }
                .profile-image {
                  & { height: 30px; width: 30px; }
                  img { height: 100%; width: 100%; border-radius: 100%; }
                }
                .profile-name {
                  & { font-size: 10px; }
                }
              }
              .message-text { 
                & { font-weight: 500; background: #FFF; box-shadow: 0px 3px 6px #00000023; padding: 8px 20px; border-radius: 3px; }
              }
            }
          }
        }
    
        .network-invite-members-container {
          & { flex: auto; padding: 0 1rem; margin-top: 1.5rem; }
    
          .invite-member-tabs {
            & { display: flex; align-items: center; justify-content: center; gap: 1rem; }
            .tab-item {
              & { font-weight: 600; width: 30%; height: 35px; display: flex; align-items: center; justify-content: center; cursor: pointer; }
              &.active { border-bottom:2px solid #cd4a4a; color: #cd4a4a; }
              &:hover { background: #f5f5f5; }
              &.active:hover { background: #FFF; border-bottom:2px solid #cd4a4a; color: #cd4a4a; }
            }
          }
    
          .invite-member-tab {
            & { margin-top: 1rem; }
            .invite-member-container {
              & { position: relative;  }
              h1 { font-size: 30px; color: #718194; margin: 5px 0; }
    
              .invite-by-link {
                & { margin: 10px 0; }
                span { font-size: 20px; color: #707070; font-weight: 700; }
                .link { 
                  & { margin-top: 5px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
                  .link-box { width: 80%; height: 35px; color: #898787; font-weight: 600; font-size: 16px; border: 1px solid #AEAEAE; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
                  .link-copy { 
                    & { cursor: pointer; width: 18%; height: 35px; padding: 0 10px; color: #606060; font-weight: 700; border: 1px solid #AEAEAE; border-radius: 5px; display: flex; align-items: center; justify-content: space-around; }
                    img { height: 25px; width: 25px; opacity: 65%; }
                  }
                }
              }
    
              .invite-members {
                & { margin: 10px 0 16px 0; }
                span { font-size: 20px; color: #707070; font-weight: 700; }
                .search-member { 
                  & {  margin-top: 5px; width: 100%; display: flex; align-items: center; justify-content: space-evenly; border: 1px solid #AEAEAE; border-radius: 5px; }
                  .search-icon { color: #A7A7A7; cursor: pointer; height: 25px; width: 25px;}
                  input {
                    & { width: 90%; height: 35px; font-size: 16px; border: 0px; outline: none; }
                  }
                  input:focus { outline: none; }
                  ::-webkit-input-placeholder { color: #C9C9C9; }
                  ::placeholder { color: #C9C9C9; }
                }
              }
    
              .frequency-members {
                & { position: relative; margin: 25px 0; max-height: 300px; overflow-y: scroll; margin-bottom: 25px; }
                .member {
                  & { height: 60px; width: 100%; margin: 5px 0; display: flex; align-items: center; justify-content: space-between; }
                  .member-profile { 
                    & { height: 100%; width: 150px; display: flex; align-items: center; justify-content: space-around;  }
                    .member-img {
                      & { width: 40%; }
                      img { height: 55px; width: 55px; border-radius: 100%; object-fit: cover; }
                      span { display: inline-block; height: 55px; width: 55px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                    }
    
                    .member-deets {
                      & { width: 60%; display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
                      .user { color: #707070; font-size: 16px; font-weight: 700; }
                      .username { color: #A9A9A9; font-size: 12px; }
                    }
                  }
                  .invite-btn { color: #fff; width: 100px; height: 40px; font-size: 16px; background: #1C97AA; border-radius: 5px; text-transform: none; }
                }
    
                .more-btn { cursor: pointer; color: #A39F9F; position: fixed; bottom: 240px; left: 50%; right: 50%; z-index: 10; height: 20px; width: 20px; display: flex; align-items: center; justify-content: center; }
                .loading { height: 200px; display: flex; align-items: center; justify-content: center; }
              }
    
              .invite-by-email {
                & { margin: 10px 0; }
                span { font-size: 20px; color: #707070; font-weight: 700; }
                .add-email { 
                  & { margin-top: 5px; width: 100%; max-height: 120px; overflow-y: scroll; display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start; border: 1px solid #AEAEAE; border-radius: 5px; }
                  .email { 
                    & { height: 30px; color: #fff; padding: 3px 5px; margin: 10px 5px; background: #77C6D2; border-radius: 5px; position: relative; }
                    .remove-email { 
                      & { cursor: pointer; height: 17px; width: 17px; background: #F00; color: #fff; position: absolute; top: -7px; right: -7px; display: flex; align-items: center; justify-content: center; border-radius: 100%;  }
                      .rm-email { height: 15px; width: 15px; }
                    }
                }
                  input {
                    & { padding: 5px; height: 30px; width: 100%; font-size: 16px; border: 0px; outline: none; }
                  }
                  input:focus { outline: none; }
                  ::-webkit-input-placeholder { color: #C9C9C9; }
                  ::placeholder { color: #C9C9C9; }
                }
              }
              .send-btn-container {
                & { width: 100%; height: 40px; display: flex; justify-content: flex-end; margin: 10px 0; }
                .send-btn { color: #fff !important; width: 100px; height: 40px; font-size: 16px; background: #BEBEBE; border-radius: 5px; text-transform: none; }
                .active { background: #1C97AA; }
              }
              .close-btn { cursor: pointer; border: 2px solid #656565; color: #656565; border-radius: 5px; position: absolute; top: 0; right: 0; }
            }
          }
        }
    
        .paid-network-toggle-container{
          &{ display: flex; flex-direction: column; align-items: center; justify-content: center;}
          .paid-network-heading{
            &{ font-size: 1.3rem; }
          }
          .css-xjimrv-Switch{
            &{ width: 60px; }
          }
        }
        
        .paid-network-content-container{
          &{ width: 80%; margin: 30px auto; }
          input{
            &{ border: none; border-bottom: 1px solid #9b9b9b; outline: none; width: 100%; padding-bottom: 5px; }
          }
    
          .payment-type-radiogroup-container{
            &{ display:flex; justify-content: space-between; flex-direction: row;}
          }
    
          .recurring-payment-type-radiogroup-container{
            &{}
            .recurring-payment-type-flexbox{
              &{ display: flex; align-items: center; justify-content: space-between; }
              .recurring-payment-type-heading{
                &{ font-size: 1rem; color: black; font-weight: 500; }
              }
            }
            .features-container{
              &{ display: flex; align-items: center; justify-content: space-between; }
              button{
                &{ background-color: #cd4a4a; display: flex; align-items: center; gap: 15px; padding: 0px 10px; font-weight: 100;}
                span{
                  &{ font-size: 2rem; font-weight: 100; }
                }
              }
            }
            .features-render-container{
              &{ display: flex; align-items: center; justify-content: space-between; }
              h3{ color: black; font-weight: 500; font-size: 1rem; }
            }
            .recurring-payment-frequency-option{
              &{ margin-right: 0 !important; }
            }
          }
    
          .one-time-payment-container{
            .features-container{
              &{ display: flex; align-items: center; justify-content: space-between; }
              button{
                &{ background-color: #cd4a4a; display: flex; align-items: center; gap: 15px; padding: 0px 10px; font-weight: 100;}
                span{
                  &{ font-size: 2rem; font-weight: 100; }
                }
              }
            }
          }
        }
        
        .invite-to-team-button { 
          & { display: flex; align-items: center; justify-content: flex-end; }
          .btn { width: 100%;  padding: 8px 24px; background-color: #cd4a4a; font-weight: bold; border: 1px solid #cd4a4a; border-radius: 5px; font-family: "Lato", sans-serif; text-transform: uppercase; }
        }  
    
      }
      .navigate-btn-container {
        & { padding: 0 1rem; margin: 1.5rem 0; display: flex; align-items: center; position: absolute; justify-content: space-between; bottom: 0; width: 100%; left: 0; background: white; }
        .prev-btn {
          & { font-weight: 600; text-transform: capitalize; width: 100px; height: 40px; color: #CD4A4A; border: 2px solid #CD4A4A; }
        }
        .next-btn {
          & { margin-left: auto; font-weight: 600; text-transform: capitalize; width: 100px; height: 40px; background: #CD4A4A; color: #FFF; }
        }
      }
    }

}