$mainfont : 'Lato', sans-serif;



@keyframes slideLeft {
	0% { right: 0%; width: 50%; }
	50% { right: 25%; width: 50%; }
	100% { right: 50%; width: 50%; }
}

@keyframes slideRight {
	0% { left: 0; width: 75%; }
	// 30% { left: 35%; width: 75%; }
	// 70% { left: 35%; width: 75%; }
	50% { width: 65%; }
	100% { left: 50%; width: 50%; }
}

.main-login { width: 100%; height: 100vh; display: flex; align-items: center; }
// .login-wrapper { display: flex; align-items: center; justify-content: center; }

.login-page-wrapper { 
	& { width: 100%; max-width: 1250px; max-height: 650px; display: flex; padding: 15px; margin: 0 auto; justify-content: center; align-items: center; height: 100vh; }
	& * { box-sizing: border-box; }
	.form { box-sizing: border-box; height: 250px; padding: 15px; margin: 15px auto; text-align: center; transition: all 1s; position: relative; width: 500px; background-color: white; 
		/* animation: slideRight 1.2s infinite; */ }
	.text { box-sizing: border-box; height: 250px; padding: 15px; margin: 15px auto; text-align: center; transition: all 1s; position: relative; width: 500px; background-color: yellowgreen; 
		/* animation: slideLeft 1.2s infinite; */ }
	// .login-form { transition: all 1.2s; position: relative; width: 50%; background-color: white; }
	// .login-text { transition: all 1.2s; position: relative; width: 50%; background-color: yellowgreen; }
	// .signup-form { transition: all 1.2s; position: relative; width: 50%; background-color: white; }
	// .signup-text { transition: all 1.2s; position: relative; width: 50%; background-color: yellowgreen; }

	// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

	.container h1 { font-size: 34px; font-weight: bold; margin: 15px auto 30px; padding: 0 15px; color: #000000; font-family: $mainfont; }
	.overlay-container h1 { font-size: 36px; font-weight: bold; margin: 0; color: #FFFFFF; font-family: $mainfont; }

	h2 { text-align: center; }
	p { font-size: 20px; max-width: 28ch; font-weight: 300; line-height: 28px; letter-spacing: 0.5px; margin: 20px 0 30px; }
	span { font-size: 12px; }
	a { color: #333; font-size: 14px; text-decoration: none; margin: 15px 0; }

	button { border-radius: 5px; border: 1px solid #CD4A4A; background-color: #CD4A4A; width: 300px; width: 80%; color: #ffffff; font-size: 12px; font-weight: bold; padding: 15px 45px; letter-spacing: 1px; text-transform: uppercase; transition: transform 80ms ease-in; }
	button.sign-up-btn { margin-top: 15px; }
	button:active { transform: scale(0.95); }
	button:focus { outline: none; }
	button.ghost { background-color: transparent; border-color: #ffffff; width: 300px; width: 80%; }
	form { background-color: #ffffff; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 0 50px; height: 100%; text-align: center; }

	input { background-color: #eee; border: none; padding: 12px 15px; margin: 8px 0; width: 80%; }

	.container { background-color: #fff; border-radius: 20px; box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); position: relative; overflow: hidden; width: 1250px; max-width: 100%; min-height: 480px; min-height: 650px; }

	.form-container { & { position: absolute; top: 0; height: 100%; transition: all 0.6s ease-in-out; }
		.logo { margin: 15px auto 45px; }
		.placeholder-img-input { & { width: 100%; display: flex; justify-content: center; align-items: center; }
			.input-img { position: relative; left: 30px; height: 18px; width: auto; color: #999999; }
			input::placeholder { color: #999999; }
			// input { outline: none; border: 2px solid transparent; border-radius: 3px; }
			// input:focus { outline: none; border: 2px solid #3AB19B; }
		}
	}

	// changing classes for login & signup pages
	// .sign-in-container { left: 0; width: 50%; z-index: 2; }
	.sign-up-container { left: 0; width: 50%; z-index: 2; }

	.container.right-panel-active .sign-up-container { transform: translateX(100%); }

	// .sign-up-container { left: 0; width: 50%; opacity: 0; z-index: 1; }
	.sign-in-container { left: 0; width: 50%; opacity: 0; z-index: 1; }

	.container.right-panel-active .sign-in-container { transform: translateX(100%); opacity: 1; z-index: 5; animation: show 0.6s; }

	@keyframes show {
		0%,
		49.99% {
			opacity: 0;
			z-index: 1;
		}

		50%,
		100% {
			opacity: 1;
			z-index: 5;
		}
	}

	.overlay-container { position: absolute; top: 0; height: 100%; overflow: hidden; z-index: 100; left: 50%; width: 50%; 
		// transition: transform 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
	}

	@keyframes widthAnimation {
		0% { width: 40%; }
		35% { width: 60%; }
		65% { width: 60%; }
		100% { width: 40%; }
	}

	.container.right-panel-active .overlay-container { transform: translateX(-100%); }

	.overlay {
		/* background: #FF416C;
		background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
		background: linear-gradient(to right, #FF4B2B, #FF416C); */ 
		
		// background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c); background: linear-gradient(to right, #ff4b2b, #ff416c); background: #3AB19B; 
		// background: url("../assets/img/login-bg.png"); background-repeat: no-repeat; background-size: cover; background-position: 0 0; 
		color: #ffffff; position: relative; left: -100%; height: 100%; width: 200%; transform: translateX(0); transition: all 0.6s ease-in-out; }

	.container.right-panel-active .overlay { transform: translateX(50%); }

	.overlay-panel { position: absolute; height: 100%; display: flex; align-items: center; justify-content: center;  flex-direction: column; padding: 0 40px; text-align: center; top: 0; width: 50%; transform: translateX(0); transition: transform 0.6s ease-in-out; }

	.overlay-left { transform: translateX(0%); background: url("../assets/img/login/signup-bg.png"); background-repeat: no-repeat; background-size: cover; background-position: 0 0; }
	.container.right-panel-active .overlay-left { transform: translateX(0); }

	.overlay-right { right: 0; transform: translateX(0); background: url("../assets/img/login/login-bg.png"); background-repeat: no-repeat; background-size: cover; background-position: 0 0; }
	.container.right-panel-active .overlay-right { transform: translateX(0%); z-index: 5; }
	// .container.right-panel-active .overlay-container { animation: widthAnimation 0.6s ease-in-out; }
	// .container.left-panel-active .overlay-container { animation: widthAnimation 0.6s ease-in-out; }
	.social-container { margin: 20px 0; display: flex; }

	.social-container a { border: 1px solid #dddddd; border-radius: 50%; display: inline-flex; justify-content: center; align-items: center; margin: 0 5px; height: 40px; width: 40px; }
	footer { background-color: #222; color: #fff; font-size: 14px; bottom: 0; position: fixed; left: 0; right: 0; text-align: center; z-index: 999; }
	footer p { margin: 10px 0; }
	footer i { color: red; }
	footer a { color: #3c97bf; text-decoration: none; }
}