@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

.profile-wrapper { 
    &{height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;}

  .card-container {
      &{height: 600px;min-height: 600px;width: 50%;max-width: 800px;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 10px #0000000D;border-radius: 10px;opacity: 1;display: flex;flex-direction: column;align-items: center;}

      .card-body{
          &{width: 100%;height: 600px;flex-shrink: 1;}
          .gradient-header {
            &{position: relative;height: 40%;min-height: 225px;width: 100%;border-radius: 10px 10px 0px 0px;background: transparent linear-gradient(90deg, #1B9FA3 0%, #267EB1 20%, #6B559E 39%, #B04570 60%, #D24C50 79%, #F58726 100%) 0% 0% no-repeat padding-box;display: flex;justify-content: center;}
              h1 {
                  &{text-align: center;font-family: 'Lato';font-size: 28px;font-style: normal;font-weight: bold;letter-spacing: 0px;color: #FFFFFF;opacity: 1; margin-top: 50px;}
                }
            }
    
            .card-info {
                &{width: 100%;padding-top: 140px;display: flex;flex-direction: column;align-items: center;}
                .name-input {
                    &{width: 80%;color: #B6B6B6;height: 19px;}
                }
            }
        }
        .preferred-network{
            &{display: flex;flex-direction: column;flex-shrink: 1;align-items: center;color: #000000;-ms-overflow-style: none;scrollbar-width: none; overflow: hidden;} 
           .heading {
              &{ width: 100%; display: flex; align-items: center;justify-content: center; height: 88px;}
              h1{
                &{width: 100%;margin-top: 30px;margin-bottom: 30px;color: #000000;text-align: center;}
              }
            }
            .list{
                &{width: 80%;display: flex;flex-direction: column; overflow-y: scroll; height: 300px;}

                .row{
                    &{width: 100%;display: flex;justify-content: start;align-items: center;gap: 10px; height: 50px;}

                    h2{
                        &{width: 100%;flex-shrink: 1;font-weight: 500;}
                    }
                    input{
                        &{width: 2em;height: 2em;accent-color: #CD4A4A;}
                    }
                }
            }

        }

        .preferred-network::-webkit-scrollbar{
            &{display: none;}
        }
    
        .actions {
            &{display: flex;flex-direction: column;align-items: center;height: 240px;width: 100%;}
            .name-save-btn {
                &{margin-top: 20px;width: 80%;background-color: #CD4A4A;}
            }
            .selector-dots {
                &{position: relative;margin: 25px 0;width: 80%;display: flex;justify-content: space-between;z-index: 0; padding: 0;}
                .btns {
                    &{width: 75px;height: 40px;background-color: #CD4A4A;color: white;border: 1px solid #CD4A4A;}
                }
                .back-btn{
                    &{background-color: white;color: #CD4A4A;}
                }
                .filler {
                    &{width: 75px;height: 40px;}
                }
                .MuiMobileStepper-dotActive {
                    &{background-color: #CD4A4A;}
                }
            }
        }


        .change-avatar-container {
            &{position: absolute;bottom: 0;min-height: 160px;min-width: 160px;max-width: 230px;max-height: 230px;transform: translateY(50%);height: 13vw;width: 13vw;border-radius: 50%;background: #EEEEEE 0% 0% no-repeat padding-box;display: flex;justify-content: center;align-items: center;overflow: hidden;cursor: pointer;}
            .change-avatar-icon {
                &{color: #CACACA; font-size: 40px;}
            }
            img {
              & {width: 100%;}
            } 
        }
    }
}


