@media screen and (max-width: 480px) {
    .Contact_wrapper__c-cyA .Contact_wrapper__main__ctIc4 .Contact_wrapper__main__content__1oNvo .Contact_form__CFzp-{
        &{ width: 90% !important; margin: 20px auto; min-width: auto !important; }
    }
    
    .Contact_wrapper__c-cyA .Contact_wrapper__main__ctIc4 .Contact_wrapper__main__content__1oNvo .Contact_title__Eb9lN{
        &{ font-size: 35px !important; display: none; }
    }

    .Contact_form__name__41Nw-{
        &{ font-size: 35px !important; }
    }

    .Contact_form__name__41Nw-{
        &{ font-size: 35px !important; }
    }

    .Contact_wrapper__c-cyA .Contact_wrapper__main__ctIc4 .Contact_wrapper__main__content__1oNvo .Contact_form__CFzp- .Contact_submit-button__Z2IBv{
        &{ font-size: 18px !important; }
    }

    .Contact_form__helper__qu2-5{
        &{ text-align: center; }
    }

    .Contact_wrapper__c-cyA .Contact_wrapper__main__ctIc4 .Contact_wrapper__main__content__1oNvo .Contact_form__CFzp- .Contact_form__persons__Vtl1W .Contact_form__person__D3Oy\+ .Contact_form__person__image__-lnKa{
        &{ width: 60px !important; height: 60px !important;}
    }

    .Contact_wrapper__c-cyA .Contact_wrapper__main__ctIc4 .Contact_wrapper__main__content__1oNvo .Contact_form__CFzp- .Contact_submit-button__Z2IBv{
        &{ padding: 6px 10px !important; margin-top: 20px !important; }
    }

    .Contact_wrapper__c-cyA .Contact_wrapper__main__ctIc4 .Contact_wrapper__main__content__1oNvo .Contact_form__CFzp- .Contact_form__persons__Vtl1W{
        gap: 20px;
    }
}