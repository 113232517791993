// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$mainfont : 'Lato', sans-serif;
$mulish : 'Mulish', sans-serif;
$work-sans : 'Work Sans', sans-serif;
$lato :  'Lato', sans-serif;

$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #F5F7FB; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #f1f2f6;
$menudividerdark: #ced6e0;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #57606f;
$titlecolor2: #227093;


//! modal root - do not change the css for the root modal without consulting 
.modal-root-div {
  & { padding: 48px 0 80px; align-items: flex-start; background-color: #000000db; display: none; height: 100%; justify-content: center; left: 0; overflow-y: auto; position: fixed; top: 0; width: 100%; z-index: 999; }
  .modal-container {
    & { margin: auto; overflow: hidden; position: relative; z-index: 25;  }
  }
}
//! modal root - do not change the css for the root modal without consulting 


// upload image modal
.upload-image-modal-container {
  & { height: 80vh; width: 60vw; display: flex; flex-direction: column; align-items: center; justify-content: space-between; background: #fff; border-radius: 8px; padding: 1rem; }

  .title { 
    & { height: 50px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
    h2 { margin: 0; color: #000; }
    .MuiSvgIcon-root { color: #000; cursor: pointer;}
  }

  .crop {
    & {  height: calc(100% - 130px); padding: 10px 0; position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    .d-n-d { 
      & { height: 80%; width: 90%; border: 2px dashed #929292; border-radius: 10px; display: flex; align-items: center; justify-content: center; }
      button { background-color: #D94242; color: white; padding: 8px 12px;}
    }
  }

  .crop-btns {
    & { position: relative; height: 80px; width: 100%; display: flex; align-items: flex-start; justify-content: space-between; margin-bottom: 12px; padding-top: 8px; }
    .tips {
      & { position: absolute; bottom: 6px; left: 0px; display: flex; align-items: center; justify-content: flex-start; }
      &>span {
        & { color: #4E4E4E; font-size: 14px; padding-left: 16px; }
      }
    }
    .btn-primary { background-color: #1BA0A2; color: #fff; padding: 8px 12px; margin: 0 24px;}
  }
}

// create course
.create-course-container {
  & { width: 600px; padding: 1rem; background: #fff; border-radius: 8px; }
  .create-course{
    & { width: 100%; display: flex; flex-direction: column; }
    .header{
    & { display: flex; justify-content: space-between; font-family: $lato; }
    h3 { margin: 0; font-size: 24px; font-weight: 700; font-family: $lato; }
    img{ cursor: pointer;}
    }
    .content{
      & { margin-top: 30px; z-index: 10; }
      p { font-weight: 500; }
      .thumbnail-btn{
        & { display: flex; align-items: center; justify-content: center; background-color: #EEEEEE; border-radius: 4px; width: 100%; height: 250px;}
        img { width: 100%; height: 250px; object-fit: cover; padding: 10px; border-radius: 4px; }
        .camera-image { color: #464646;}
      }
      .course-title-input {
        & {margin-top: 20px; margin-bottom: 20px;}
        input { font-size: 16px; border: none; border-bottom: 2px solid #EEEEEE; width: 100%; }
        input:focus{ outline: none; border-bottom: 2px solid #464646;}
      }
      .course-description-text-editor{
        & { display: flex; flex-direction: column; width: 100%; border: 1px solid #ECECEC; border-radius: 4px;}
        .custom-toolbar {
          & {padding-top: 4px; padding-bottom: 4px; height: 45px; display: flex; justify-content: space-around; align-items: center; background-color: #ECECEC;}
          
          .ql-font span[data-label="Lato"]::before {
            font-family: "Lato";
          }
          .ql-font span[data-label="Mulish"]::before {
            font-family: "Mulish";
          }
          .ql-font span[data-label="WorkSans"]::before {
            font-family: "Work Sans";
          }
          .ql-font span[data-label="Nunito"]::before {
            font-family: "Nunito";
          }

          .ql-size span[data-label="Normal"]::before {
            font-size: "Normal";
          }
          .ql-size span[data-label="Heading"]::before {
            font-size: "Heading";
          }
          .ql-size span[data-label="SubHeading"]::before {
            font-size: "SubHeading";
          }
          .ql-size span[data-label="Small"]::before {
            font-size: "Small";
          }
          
          .bold{ cursor: pointer; }
          .italic{ cursor: pointer; }
          .underline{ cursor: pointer; }
          .color-picker{
            & { position: relative; }
            .colorSketchPicker{ position: absolute; z-index: 20; left: -150px; top: 28px; }
          }
        }
        .editor { height: 180px; }
      }

      .ql-size-Normal {
        font-size: 16px;
      }
      .ql-size-Heading {
        font-size: 32px;
      }
      .ql-size-SubHeading {
        font-size: 24px;
      }
      .ql-size-Small {
        font-size: 12px;
      }

      .ql-font-Lato {
        font-family: "Lato";
      }
      .ql-font-Mulish {
        font-family: "Mulish";
      }
      .ql-font-WorkSans{
        font-family: "Work Sans";
      }
      .ql-font-Nunito{
        font-family: 'Nunito';
      }
      
  }
  .course-create-btn{
    & { margin-top: 30px; border-radius: 4px; background-color: $buttoncolor1; }
    .btn {
      & { height: 45px; cursor: pointer; display: flex; justify-content: center; color: white; font-size: 24px; padding-top: 10px; padding-bottom: 10px; }
    }
  }
}
}

// create post
.create-post-container {
    & { width: 610px; height: 650px; padding: 1rem; background-color: #fff; border-radius: 8px; }
    .create-post {
      & { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; }
      .post-header {
        & { width: 100%; height: 50px; margin-bottom: 32px; display: flex; align-items: center; justify-content: space-between; }
        h3 { margin: 0; font-size: 24px; font-weight: 700; font-family: $lato; }
        .close { cursor: pointer; }
      }
      .cover-over {
        & { z-index: 1000; height: 80%; width: 100%; backdrop-filter: blur(1px); position: absolute; background: #ffffff90; }
      }
      .profile-container {
        & { width: 100%; margin-bottom: 32px; display: none; }
        .profile {
          & { width: 100%; display: flex; align-items: center; gap: 10px; }
          .profile-img {
            & { height: 48px; width: 48px; border-radius: 100%; }
            img { height: 48px; width: 48px; border-radius: 100%; object-fit: cover; }
            span { height: 48px; width: 48px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
          }
          .profile-name {
            & { display: flex; flex-direction: column; }
            .user { font-family: $lato; font-size: 16px; font-weight: 700; color: #000;  }
            .username { font-family: $lato; font-size: 10px; color: #9B9B9B;  }
          }
        }
      }
      .post-text {
        & { width: 100%; margin-bottom: 24px; height: 95px; border: 1px solid #CACACA; border-radius: 5px; }
        .post-input {
          & { width: 100%; height: 100%; padding: 10px; border: 0px; font-size: 16px; font-family: $lato; resize: none;}
          &:focus { outline: none; }
        }
      }
      .import-media {
        & { position: relative; flex-grow: 1; width: 100%; height: 315px; display: flex; align-items: center; justify-content: center; flex-direction: column; margin-bottom: 24px; border: 1px solid #CACACA; border-radius: 5px; }
        .import-close { z-index: 100; cursor: pointer; position: absolute; top: 16px; right: 60px; background: #ffffff60; border-radius: 100%; }
        h3 { margin: 0; font-size: 20px; font-weight: 700; font-family: $lato; margin-bottom: 16px;  }
        .import-btn { width: 203px; height: 32px; color: #FFF; background: #CD4A4A; font-size: 16px; text-transform: capitalize; border-radius: 5px; }
        .info-btn { margin-top: 1rem; z-index: 100; }
        .file-input { display: none; }
  
        .carousel-container {
          & { height: 100%; width: 100%; }
          .file-preview-holder {
            & { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; }
          }
          .file-preview-image {
            & { height: 100%; width: 100%; object-fit: contain; }
          }
          .file-preview-video {
            & {  }
          }
          .carousel-root { height: 100%; width: 100%; }
            .carousel .carousel-status { font-family: $lato; font-size: 10px; text-shadow: none; right: 5px; top: 7px; background: #00000080; border-radius: 5px; }
            .carousel .thumbs-wrapper { display: none; }
            .carousel.carousel-slider { height: 100%; }
            .carousel.carousel-slider .control-arrow { opacity: 0.8; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; border-radius: 100%; background: #ffffff; right: 16px; top: 50%; }
            .carousel.carousel-slider .control-arrow::before { border-left: 8px solid #000000; }
            .carousel .control-prev.control-arrow { opacity: 0.8; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; border-radius: 100%; background: #ffffff; left: 16px; top: 50%; }
            .carousel .control-prev.control-arrow::before { margin-right: 10px; border-left: 0; border-right: 8px solid #000000; }
            .carousel .control-disabled.control-arrow { opacity: 0; }
            .carousel .slider-wrapper { height: 100%; }
            .carousel .control-dots { bottom: 0px; }
            .carousel .control-dots .dot { height: 7px; width: 7px; border: 1px solid #FFF; background: transparent; opacity: 1; box-shadow: 0 0 3px #919191; }
            .carousel .control-dots .dot.selected { background: #CD4A4A !important; }
            .carousel .slider-wrapper.axis-horizontal .slider { height: 100%; border-radius: 10px; }
            .carousel .slider-wrapper.axis-horizontal .slider .slide { height: 100%; border-radius: 10px; }
            .carousel .slider-wrapper.axis-horizontal .slider .slide .final-img-container { position: relative; height: 100%; border-radius: 10px; }
            .carousel.carousel-slider .control-arrow:hover { background: rgba(0,0,0,0.1); }
        }
  
        .d-n-d {
          & { position: absolute; height: 100%; width: 100%; opacity: 0; top: 0; left: 0; right: 0; bottom: 0; }
          .presentation {
            & { height: 100%; }
            .text-center {
              & { display: flex; flex-direction: column;  }
            }
          } 
          .dropzone {
            & { height: 100%; }
          }
          
        }
  
      }
      .import-media-null { & { flex-grow: 1; width: 100%; margin-bottom: 24px; }}
      .add-to-post {
        & { height: 52px; width: 100%; padding: 0 24px; margin-bottom: 20px; display: flex; gap: 24px; align-items: center; justify-content: flex-end; border: 1px solid #CACACA; border-radius: 5px;  }
        span { margin-right: auto; font-size: 20px; font-weight: 700; font-family: $lato; }
        img { cursor: pointer; }
      }
      .post-btn {
        & { display: flex; align-items: center; justify-content: center; height: 40px; width: 100%; background: #CD4A4A; color: #FFF !important; border-radius: 5px; box-shadow: none !important; margin-bottom: 8px;}
        button { height: 40px; width: 100%; background: #CD4A4A; color: #FFF !important; border-radius: 5px; box-shadow: none !important; }
        button:disabled { background: #CACACA; color: #888; }
      }
      .tag-users {
        & { width: 100%; max-height: 344px; flex-grow: 1; }
        .tag-people-container {
          & { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; }
          .tag-people-header {
              & { height: 10%; width: 100%; display: flex; align-items: center; justify-content: center; }
              .back { cursor: pointer; }
              h3 { margin: auto; font-size: 24px; font-weight: 700; }
          }
      
          .search-bar {
              & { width: 100%; min-height: 40px; padding: 0 10px; margin: 24px 0; margin-top: 0; border: 1px solid #CACACA; border-radius: 5px; display: flex; align-items: center; justify-content: space-between; }
              input { font-family: $lato; font-size: 16px; background: transparent; width: 90%; height: 100%; border: 0px; }
              input:focus { outline: none; }
              input::placeholder { color: #9B9B9B; }
              .search-icon { color: #9B9B9B; cursor: pointer; }
          }
          .tag-members {
            & { position: relative; height: 100%; width: 100%; margin: 16px 0; overflow-y: scroll; margin-bottom: 25px; }
            .member {
              & { height: 60px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
              .member-profile { 
                & { height: 100%; width: auto; display: flex; gap: 1rem; align-items: center; justify-content: flex-start; cursor: pointer; }
                .member-img {
                  & { width: auto; }
                  img { height: 55px; width: 55px; border-radius: 100%; object-fit: cover; }
                  span { display: inline-block; height: 55px; width: 55px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                }
        
                .member-deets {
                  & { width: 60%; display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
                  .user { color: #707070; font-size: 16px; font-weight: 700; }
                  .username { color: #A9A9A9; font-size: 12px; }
                }
              }
              .invite-btn { color: #fff; width: 100px; height: 40px; font-size: 16px; background: #1C97AA; border-radius: 5px; text-transform: none; }
            }
        
            .more-btn { cursor: pointer; color: #A39F9F; position: fixed; bottom: 240px; left: 50%; right: 50%; z-index: 10; height: 20px; width: 20px; display: flex; align-items: center; justify-content: center; }
            .loading { flex-grow: 1; display: flex; align-items: center; justify-content: center; }
          }
        }
      }
    }
    .tag-people-container {
        & { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; }
        .tag-people-header {
            & { height: 10%; width: 100%; display: flex; align-items: center; justify-content: center; }
            .back { cursor: pointer; }
            h3 { margin: auto; font-size: 24px; font-weight: 700; }
        }
    
        .search-bar {
            & { width: 100%; min-height: 40px; padding: 0 10px; margin: 24px 0; border: 1px solid #CACACA; border-radius: 5px; display: flex; align-items: center; justify-content: space-between; }
            input { font-family: $lato; font-size: 16px; background: transparent; width: 90%; height: 100%; border: 0px; }
            input:focus { outline: none; }
            input::placeholder { color: #9B9B9B; }
            .search-icon { color: #9B9B9B; cursor: pointer; }
        }
        .tag-members {
          & { position: relative; flex-grow: 1; width: 100%; margin: 16px 0; overflow-y: scroll; margin-bottom: 25px; }
          .member {
            & { height: 60px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
            .member-profile { 
              & { height: 100%; width: 150px; display: flex; align-items: center; justify-content: space-around;  }
              .member-img {
                & { width: 40%; }
                img { height: 55px; width: 55px; border-radius: 100%; object-fit: cover; }
                span { display: inline-block; height: 55px; width: 55px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
      
              .member-deets {
                & { width: 60%; display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
                .user { color: #707070; font-size: 16px; font-weight: 700; }
                .username { color: #A9A9A9; font-size: 12px; }
              }
            }
            .invite-btn { color: #fff; width: 100px; height: 40px; font-size: 16px; background: #1C97AA; border-radius: 5px; text-transform: none; }
          }
      
          .more-btn { cursor: pointer; color: #A39F9F; position: fixed; bottom: 240px; left: 50%; right: 50%; z-index: 10; height: 20px; width: 20px; display: flex; align-items: center; justify-content: center; }
          .loading { flex-grow: 1; display: flex; align-items: center; justify-content: center; }
        }
    
        .suggestions-container {
            & { height: 75%; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; }
            
            .suggestions-header {
            & { height: 5%; width: 100%; margin: 10px 0; }
            }
    
            .suggestions {
            & { height: 95%; width: 100%; overflow-y: scroll; }
            .friend {
                & { height: 48px; width: 100%; margin-top: 16px; display: flex; align-items: center; justify-content: space-between;  }
                .profile {
                & { display: flex; align-items: center; gap: 10px; }
                .profile-img {
                    & { height: 48px; width: 48px; border-radius: 100%; }
                    img { height: 48px; width: 48px; border-radius: 100%; object-fit: cover; }
                    span { height: 48px; width: 48px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                }
                .profile-name {
                    & { display: flex; flex-direction: column; }
                    .user { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                    .username { font-family: $lato; font-size: 10px; color: #9B9B9B;  }
                }
                }
                .tag-input {
                & { width: 24px; height: 24px; color: #CD4A4A; vertical-align: middle; -webkit-appearance: none; background: none; border: 0; outline: 0; flex-grow: 0; border-radius: 50%; background-color: #FFFFFF; transition: background 300ms; cursor: pointer; }
                
                &::before { content: ""; color: transparent; display: block; width: inherit; height: inherit; border-radius: inherit; border: 0; background-color: transparent; background-size: contain; box-shadow: inset 0 0 0 2px #9B9B9B;}
    
    
                /* Checked */
    
                &:checked { background-color: currentcolor; }
    
                &:checked::before { box-shadow: none; background-image: url("data:image/svgxml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");}
                }
            }
            }
        }
        }
        .add-location-container {
        & { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; }
        .add-location-header {
            & { height: 10%; width: 100%; display: flex; align-items: center; justify-content: center; }
            .back {  }
            h3 { margin: auto; }
        }
    
        .search-bar {
            & { width: 100%; height: 40px; padding: 0 10px; margin: 24px 0; border: 1px solid #CACACA; border-radius: 5px; display: flex; align-items: center; justify-content: space-between; }
            input { font-family: $lato; font-size: 16px; background: transparent; width: 90%; height: 100%; border: 0px; }
            input:focus { outline: none; }
            input::placeholder { color: #9B9B9B; }
            .search-icon { color: #9B9B9B; cursor: pointer; }
        }
    
        .locations-container {
            & { height: 75%; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; }
    
            .location {
            & { height: 95%; width: 100%; overflow-y: scroll; }
            
            }
        }
        }
  }

  
// lightbox styles

// invite team members

.invite-team-container {
    & { position: relative; height: 650px; }
    h1 { font-size: 30px; color: #718194; margin: 5px 0; }
  
    .invite-by-link {
      & { margin: 10px 0; }
      span { font-size: 20px; color: #707070; font-weight: 700; }
      .link { 
        & { margin-top: 5px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
        .link-box { width: 80%; height: 35px; color: #898787; font-weight: 600; font-size: 16px; border: 1px solid #AEAEAE; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
        .link-copy { 
          & { cursor: pointer; width: 18%; height: 35px; padding: 0 10px; color: #606060; font-weight: 700; border-radius: 5px; display: flex; align-items: center; justify-content: space-around; }
          img { height: 25px; width: 25px; opacity: 65%; }
        }
      }
    }
  
    .invite-members {
      & { margin: 10px 0 16px 0; font-family: $lato; }
      span { font-size: 20px; color: #707070; font-weight: 700; }
      .search-member { 
        & {  margin-top: 5px; width: 100%; display: flex; align-items: center; justify-content: space-evenly; border: 1px solid #AEAEAE; border-radius: 5px; }
        .search-icon { color: #A7A7A7; cursor: pointer; height: 25px; width: 25px;}
        input {
          & { width: 90%; height: 35px; font-size: 16px; border: 0px; outline: none; font-family: $lato; }
        }
        input:focus { outline: none; }
        ::-webkit-input-placeholder { color: #C9C9C9; }
        ::placeholder { color: #C9C9C9; }
      }
    }
  
    .frequency-members {
      & { position: relative; height: 225px; overflow-y: scroll;}
      .member {
        & { height: 40px; width: 100%; margin: 5px 0; display: flex; align-items: center; justify-content: space-between; }
        .member-profile { 
          & { height: 100%; display: flex; align-items: center; justify-content: space-around;  }
          .member-img {
            & { margin-right: 15px; }
            img { height: 30px; width: 30px; border-radius: 100%; object-fit: cover; }
            span { display: inline-block; height: 30px; width: 30px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
          }
  
          .member-deets {
            & { display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
            .user { color: #707070; font-size: 16px; font-weight: 700; }
            .username { color: #A9A9A9; font-size: 12px; }
          }
        }
        .invite-btn { color: #fff; width: 100px; height: 40px; font-size: 16px; background: #1C97AA; border-radius: 5px; text-transform: none; }
      }
  
      .more-btn { cursor: pointer; color: #A39F9F; position: fixed; bottom: 240px; left: 50%; right: 50%; z-index: 10; height: 20px; width: 20px; display: flex; align-items: center; justify-content: center; }
      .loading { height: 200px; display: flex; align-items: center; justify-content: center; }
      .laoding-network { height: 244px; display: flex; align-items: center; justify-content: center; }
    }
  
    .invite-by-email {
      & { display: flex; align-items: center; justify-content: space-between; margin: 0px !important; margin: 10px 0; }
      span { font-size: 20px; color: #707070; font-weight: 700; }
      .add-email { margin-top: 0px !important; }
        
      .add-email { 
        & { width: 100%; max-height: 120px; overflow-y: scroll; display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start; border: 1px solid #AEAEAE; border-radius: 15px; padding: 0 10px; }
        .email { 
          & { height: 24px; color: #fff; padding: 3px 5px; margin: 10px 5px; background: #77C6D2; border-radius: 5px; position: relative; font-size: 14px; }
          .remove-email { 
            & { cursor: pointer; height: 17px; width: 17px; background: #F00; color: #fff; position: absolute; top: -7px; right: -7px; display: flex; align-items: center; justify-content: center; border-radius: 100%;  }
            .rm-email { height: 15px; width: 15px; }
          }
        }
        input {
          & { padding: 5px; height: 35px; width: 100%; font-size: 16px; border: 0px; outline: none; font-family: 'Lato', sans-serif; }
        }
        input:focus { outline: none; }
        ::-webkit-input-placeholder { color: #C9C9C9; }
        ::placeholder { color: #C9C9C9; }
      }
      .send-btn-container {
        & { width: auto !important; margin: 0px !important; height: 40px; }
        .send-btn { width: auto !important; height: auto !important; text-transform: uppercase; }
      }
      .active { background: #cd4a4a !important; }
      .email { background: #ffdfdf !important; color: #000 !important; }
      .remove-email { height: 14px !important; width: 14px !important; top: -5px !important; right: -5px !important; }
      
    }
    .send-btn-container {
      & { width: 100%; height: 40px; display: flex; justify-content: flex-end; margin: 10px 0; }
      .send-btn { color: #fff !important; width: 100px; height: 40px; font-size: 14px; background: #BEBEBE; border-radius: 5px; text-transform: none; }
      .active { background: #1C97AA; }
    }
    .close-btn { cursor: pointer; border: 2px solid #656565; color: #656565; border-radius: 5px; position: absolute; top: 0; right: 0; }
  }
  
  //create network modal
  
  .user-profile-container {
    & { position: relative; width: 600px; padding: 2rem; background: #fff; border-radius: 8px; }
    // .profile-container {
    //   & { height: 33%; display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-evenly; }
  
    //   .user-name {
    //     & { width: 50%; text-align: center; display: flex; align-items: flex-start; gap: 5px; flex-direction: column; }
    //     .name { padding-left: 10px; text-align: start; font-size: 18px; font-weight: 800; }
    //     .username { padding-left: 10px; text-align: start; font-size: 14px; font-weight: 700; }
    //     .follow-btns { 
    //       & { display: flex; align-items: center; justify-content: space-evenly; }
    //       button { 
    //         & { margin: 0 5px; background-color: #1C97AA; height: 30px; width: 90px; border-radius: 15px; padding: 0; font-size: 14px; font-family: $mainfont; }
    //         &:disabled { background-color: #1C97AA90; }
    //       }
    //     }
    //   }    
  
    //   .user-img {
    //     & {  width: 50%; display: flex; align-items: center; justify-content: center; width: 150px; height: 150px; border-radius: 100%; }
    //     img { width: 150px; height: 150px; object-fit: cover; border-radius: 100%; }
    //     span { display: flex; align-items: center; justify-content: center; width: 150px; height: 150px; border-radius: 100%; }
    //   }
    // }

    .profile-container {
      & { display: flex; flex-direction: column; align-items: center; gap: 8px; margin-bottom: 32px; }
      .user-img {
        & { height: 165px; width: 165px; }
        img { height: 165px; width: 165px; border-radius: 100%; object-fit: cover; }
        span { display: flex; align-items: center; justify-content: center; height: 165px; width: 165px; border-radius: 100%; }
      }
      .user-name {
        & {  }
        .name { font-size: 24px; font-weight: 700; color: #17170F; text-align: center; }
        .username { font-size: 16px; font-weight: 500; color: #9B9B9B; text-align: center; }
      }
    }
  
    nav {
      & { height: 7%; }
      .nav-container { 
        & { border-bottom: 0px; width: 100%; height: 100%; display: flex; align-items: center; justify-content: space-evenly; }
        span { 
          & { cursor: pointer; font-weight: 700; border-bottom: 3px solid #fff; transition: 250ms ease; }
        }
        .active { border-bottom: 3px solid #1C97AA; }
      }
    }
  
    .tab-container {    
      & { width: 100%; height: 60%;}
  
      .networks-container { 
        & { width: 100%; border-radius: 5px; display: flex; flex-wrap: wrap; justify-content: space-between; height: 100%; overflow-y: scroll; gap: 5px; } 
        .no-networks { margin: 25px auto; color: #656565; }
          // .networks {
          //   & { cursor: pointer; position: relative; display: flex; align-items: center; background-color: #fff; width: 95%; height: 70px; border-radius: 15px; margin: 8px 5px; border: 2px solid #ddd; }
          //   img { height: 67px; width: 67px; min-width: 67px; border-top-left-radius: 15px; border-bottom-left-radius: 15px; object-fit: cover; }
          //   .initial { height: 67px; width: 67px; min-width: 67px; border-top-left-radius: 15px; border-bottom-left-radius: 15px; display: flex; align-items: center; justify-content: center; }
          //   .network-text {
          //     & { padding: 5px 10px;  }
          //     h2 {font-size: 14px; margin: 0; width: 175px;  color: #17170F; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
          //     h4 {font-size: 12px; margin: 0; text-overflow: clip; max-height: 50px; color: #17170F;} 
          //   }
          // }
          .networks {
            & { margin: 5px 0; height: 120px; width: 120px; position: relative; display: flex; flex-direction: column; align-items: center; }
            .hub-img {
                & { cursor: pointer; background: #00000060; display: flex; align-items: center; justify-content: center; position: relative; height: 90px; width: 90px; min-width: 90px; object-fit: contain; border-radius: 20px; }
                img { height: 90px; width: 90px; min-width: 90px; object-fit: contain; border-radius: 20px; background-color: #fff; }
            }
            .initial { height: 90px; width: 90px; min-width: 90px; border-radius: 20px; display: flex; align-items: center; justify-content: center; }
            .network-text {
                & { width: 100%; height: 35px; margin-top: 5px; display: flex; flex-direction: column; align-items: center; justify-content: center;}
                h2 { text-align: center; height: 100%; font-size: 12px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; }
            }
          }
    }
      .invite-container { 
        & { width: 100%; border-radius: 5px; display: flex; flex-wrap: wrap; justify-content: space-between; height: 100%; overflow-y: scroll; gap: 5px; } 
        .no-networks { margin: 25px auto; color: #656565; }
          .networks {
            & { margin: 5px 0; height: 120px; width: 120px; position: relative; display: flex; flex-direction: column; align-items: center; }
            .hub-img {
                & { cursor: pointer; background: #00000060; display: flex; align-items: center; justify-content: center; position: relative; height: 90px; width: 90px; min-width: 90px; object-fit: contain; border-radius: 20px; }
                img { height: 90px; width: 90px; min-width: 90px; object-fit: cover; border-radius: 20px; background-color: #fff; }
            }
            .initial { height: 90px; width: 90px; min-width: 90px; border-radius: 20px; display: flex; align-items: center; justify-content: center; }
            .network-text {
                & { width: 100%; height: 35px; margin-top: 5px; display: flex; flex-direction: column; align-items: center; justify-content: center;}
                h2 { text-align: center; height: 100%; font-size: 12px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; }
            }
          }
    }
      .follower-container { 
        & { width: 100%; border-radius: 5px; display: flex; flex-wrap: wrap; justify-content: space-between; height: 100%; overflow-y: scroll; gap: 5px; } 
        .no-follower { margin: 25px auto; color: #656565; }
          .follower {
            & { margin: 5px 0; height: 120px; width: 120px; position: relative; display: flex; flex-direction: column; align-items: center; }
            .follower-img {
                & { cursor: pointer; background: #00000060; display: flex; align-items: center; justify-content: center; position: relative; height: 90px; width: 90px; min-width: 90px; object-fit: contain; border-radius: 100%; }
                img { height: 90px; width: 90px; min-width: 90px; object-fit: cover; border-radius: 100%; background-color: #fff; }
            }
            .initial { height: 90px; width: 90px; min-width: 90px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
            .follower-name {
                & { width: 100%; height: 35px; margin-top: 5px; display: flex; flex-direction: column; align-items: center; justify-content: center;}
                h2 { text-align: center; height: 100%; font-size: 12px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; }
            }
          }
    }
      .following-container { 
        & { width: 100%; border-radius: 5px; display: flex; flex-wrap: wrap; justify-content: space-between; height: 100%; overflow-y: scroll; gap: 5px; } 
        .no-following { margin: 25px auto; color: #656565; }
          .following {
            & { margin: 5px 0; height: 120px; width: 120px; position: relative; display: flex; flex-direction: column; align-items: center; }
            .following-img {
                & { cursor: pointer; background: #00000060; display: flex; align-items: center; justify-content: center; position: relative; height: 90px; width: 90px; min-width: 90px; object-fit: contain; border-radius: 100%; }
                img { height: 90px; width: 90px; min-width: 90px; object-fit: cover; border-radius: 100%; background-color: #fff; }
            }
            .initial { height: 90px; width: 90px; min-width: 90px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
            .following-name {
                & { width: 100%; height: 35px; margin-top: 5px; display: flex; flex-direction: column; align-items: center; justify-content: center;}
                h2 { text-align: center; height: 100%; font-size: 12px; font-weight: 700; overflow: hidden; text-overflow: ellipsis; }
            }
          }
    }
  
  
    }

    .networks-container {
      & { margin-bottom: 32px; }
      h2 { margin: 10px 0; }
      .networks {
        & { height: 200px; width: 100%; overflow-x: hidden; overflow-y: scroll; padding: 16px; margin-bottom: 16px; }
        .network {
          & { width: 100%; display: flex; align-items: center; margin-bottom: 16px; justify-content: space-between;}
          .network-side-a {
            & { display: flex; flex-direction: column; align-items: start; justify-content: center; width: 100%; }
          }
          .network-img {
            & { margin-right: 16px; cursor: pointer; }
            img { height: 48px; width: 48px; border-radius: 5px; object-fit: cover; }
            .initial { height: 48px; width: 48px; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
          }
          .network-info {
            & { width: 268px; display: flex; align-items: flex-start; flex-direction: column;}
            .network-name { cursor: pointer; font-size: 16px; color: #000000; }
            .network-topic {
              & { font-size: 14px; color: #9b9b9b; }
            }
          }
          .join-btn {
            & { margin-left: auto; width: 77px; height: 32px; font-size: 16px; color: #FFF; background: #cd4a4a; }
          }
        }
      }
    }

    .action-btns {
      & { display: flex; align-items: center; justify-content: space-around; gap: 2rem; }
      .red-btn {
        & { text-transform: capitalize; font-size: 20px; width: 211px; height: 40px; color: #FFF; background: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
      }
      .white-bg-btn {
        & { background: #FFF; color: #cd4a4a; }
      }
    }

    .red-btn {
      & { text-transform: capitalize; font-size: 20px; width: 211px; height: 40px; color: #FFF; background: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
    }
    .unfollow-btn {
      & { text-transform: capitalize; font-size: 20px; width: 211px; height: 40px; color: #7d7d7d; background: #ffffff; font-weight: normal; border: 1px solid #7d7d7d; border-radius: 5px; }
    }
    .white-bg-btn {
      & { background: #FFF; color: #cd4a4a; }
    }
    .close { height: 24px; width: 24px; cursor: pointer; color: #656565; position: absolute; top: 10px; right: 10px; }
  }
  
  
  //create network modal
  
  .create-network-container {
    & { width: 625px; min-height: 90vh; padding: 1rem; background-color: #fff; border-radius: 8px; }
    .create-network {
      & { position: relative; width: 100%; height: 100%; display: flex; flex-direction: column; align-items: flex-start; }
      h2 { margin-top: 0; font-size: 18px;}
      .edit-network-steps {
        & { display: flex; align-items: center; justify-content: center; width: 100%; }
        h4{ margin: 0; background-color: #FFDFDF; color: #CD4A4A; font-weight: 600; padding: 0.5rem; margin-bottom: 1rem; }
      }
      .net-title-img {
        & { width: 100%; display: flex; align-items: center; justify-content: space-evenly; flex-direction: column  ; }
        .network-image-container { 
          & { position: relative; }
          .image-container {
            & { height: 160px !important; width: 160px !important; display: flex; align-items: center; justify-content: center; background-color: #EEEEEE; border-radius: 15px; cursor: pointer; }
          }
        }
        img { height: 160px; width: 160px; border-radius: 15px; border: 3px solid #707070; object-fit: cover; }
        .net-img {
          & { position: relative; height: 160px; width: 160px; border: 3px dashed #707070; border-radius: 15px; display: flex; align-items: center; justify-content: center; }
          .net-get-img { cursor: pointer; opacity: 0.0; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height:100%; }
          .MuiSvgIcon-root { height: 60px; width: 60px; color: #707070; }
        }
        .net-public-private {
          & { display: flex; align-items: center; justify-content: center; margin-top: 25px; width: 350px; font-size: 14px }
          .net-public { cursor: pointer; color: #17170F; font-weight: 700; text-transform: uppercase; width: 50%; height: 47px; display: flex; align-items: center; justify-content: center; border: 1px solid #707070; border-right: none; border-top-left-radius: 15px;  border-bottom-left-radius: 15px; }
          .net-private { cursor: pointer; color: #17170F; font-weight: 700; text-transform: uppercase; width: 50%; height: 47px; display: flex; align-items: center; justify-content: center; border: 1px solid #707070; border-left: none; border-top-right-radius: 15px;  border-bottom-right-radius: 15px; }
          .active { background: #41772D; color: #fff; }
        }
      
        .new-img {
          & { position: relative; height: 160px; width: 160px; border: 3px dashed #707070; border-radius: 15px; display: flex; align-items: center; justify-content: center;}
          .net-get-img { cursor: pointer; opacity: 0.0; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height:100%; }
          .remove-img { cursor: pointer; position: absolute; top: 10px; left: 10px; background: #eee; border-radius: 5px; }
        }
        .net-title {
          & { width: 100%; }
        .net-title-input { 
          & { display: flex; flex-direction: column; }
          label {color: #ccc; margin-bottom: 5px; }
          input { padding-left: 15px; border: none; height: 47px; border-bottom: 1px solid #ccc; }
          input:focus { outline: none; }
        }
        
      }
    }
    
    .net-topic {
      & { width: 100%; margin-top: 25px;}
      .net-input { 
        & { display: flex; flex-direction: column; }
        label {color: #ccc; margin-bottom: 5px; }
        input { padding-left: 15px; border: 1px solid #707070; height: 47px; border-radius: 5px; }
        input:focus { outline: none; }
        .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root { height: 47px; }
        .MuiOutlinedInput-notchedOutline legend { display: none;}
      }
    }
  
    .net-headline {
      & { width: 100%; margin-top: 10px; }
      .net-input { 
        & { display: flex; flex-direction: column; }
        label {color: #ccc; margin-bottom: 5px; }
        input { padding-left: 15px; border: none; height: 47px; border-bottom: 1px solid #ccc; }
        input:focus { outline: none; }
      }
    }
    
    .net-desc {
      & { width: 100%; margin-top: 10px; }
      .net-desc-input { 
        & { display: flex; flex-direction: column; }
        label {color: #ccc; margin-bottom: 5px; }
        textarea { resize: none; padding-left: 15px; border: 1px solid #ccc;  border-radius: 5px; outline: none; }
        input:focus { outline: none; }
      }
    }
    
    .net-join {
      & { width: 100%; margin-top: 10px; display: flex; flex-direction: column; align-items: flex-start; }
      .net-join-title { color: #17170F; font-weight: 700; }
      .MuiButtonBase-root { padding: 2px 10px; }
    }
    
    .create-network-btn { background: #FFDFDF; color: #CD4A4A; font-weight: 600; padding: 6px 16px !important; letter-spacing: 1.1px; width: 50%; margin-top: 15px; border-radius: 15px; margin: 10px auto; }
    
    .close-btn { cursor: pointer; position: absolute; top: 0; right: 0; color: #707070; }
  }
  }
  
  //message action modal
  .action-container {
    & { width: 300px; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; border-radius: 8px; background: #FFFFFF; padding: 1rem 0; }
    .actions {
      & { width: 100%; height: 100%; font-family: $lato; }
      .setting-header {
        & { width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 0.5rem 1rem;}
        h2 { margin: 8px 0; font-size: 20px; }
        .close { cursor: pointer; height: 20px; width: 20px; }
      }
      .MuiTypography-root { color: #9B9B9B; font-size: 16px; transition: 250ms ease; }
      .MuiListItem-root { 
        cursor: pointer; justify-content: center; border-bottom: 1px solid #CACACA; padding: 0; 
        &:hover .MuiTypography-root { color: #636363; }
        &:last-child { border-bottom: 0px; }
      }
      .setting-option {
        & { width: 100%; margin: 8px 0; display: flex; align-items: center; justify-content: flex-start; gap: 12px; padding-left: 2rem; }
        img { height: 24px; width: 24px; }
      }
  
      .update-text {
        & { width: 200px; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
      }
    }
  
    .cancel { width: 100px; margin-top: 15px; background: #00000020; color: #00000085; }
  }
  
  // video player modal
  .video-container {
    & { display: flex; flex-direction: column; align-items: center; justify-content: space-evenly;height: 70vh;width: 100%; padding: 18px; }
    .video {
      & { height: 90%; width: 100%; display: flex; align-items: center; justify-content: center; object-fit: cover; }
    }
  
    .close { width: 100px; height: 10%; background: none; color: #fff; font-weight: 700; font-size: 16px; text-decoration: none; }
  }
  
  //image crop modal
  .crop-container {
    & { padding: 2rem; width: 500px; height: 600px; display: flex; flex-direction: column; align-items: center; justify-content: space-between; background-color: #fff; border-radius: 8px; }
    .title { width: 100%; display: flex; align-items: center; justify-content: space-between; margin: 0 15px; margin-right: 25px;
      h2 { margin: 0; color: #4D5865; }
      .MuiSvgIcon-root { color: #929292; border: 2px solid #929292; border-radius: 5px; cursor: pointer;}
    }
    .easy-crop {
      & { margin: 10px 0; position: relative; height: 72%; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
      .d-n-d { 
        & { height: 70%; width: 90%; border: 2px dashed #929292; border-radius: 25px; display: flex; align-items: center; justify-content: center;}
        .MuiButtonBase-root { height: 38px; background: #007C89; color: #fff; border-radius: 15px; }
      }
      .reactEasyCrop_Container {
        & { height: 100%; border-radius: 10px; }
      }
    }
    .crop-btns {
      & { width: 100%; margin: 10px 0 10px 0; display: flex; align-items: center; }
      .sliders {
        & { flex: 1; }
        .slider {
          & { margin-left: 15px; width: 80%; }
          h3 { margin: 0; color: #4D5865; }
          .MuiSlider-root {
          & {  color: #007C89; height: 6px; padding: 0; }
        }
      }
    }
    .upload-btn {
      & { display: flex; flex-direction: column; }
      .MuiButtonBase-root {
        & { width: 110px; margin-top: 20px; height: 36px; background: #007C89; color: #fff; border-radius: 15px;  }
        .MuiCircularProgress-root { height: 30px !important; width: 30px !important; }
      }
    }
    }
  }
  
  //video upload modal
  .video-upload-container {
    & { padding: 1rem; width: 700px; height: 500px; display: flex; flex-direction: column; align-items: center; justify-content: space-between; background-color: #fff; border-radius: 8px; }
    .title { width: 100%; height: 10%; display: flex; align-items: center; justify-content: space-between; margin: 0 15px; margin-right: 25px;
      h2 { margin: 0; color: #4D5865; }
      .MuiSvgIcon-root { color: #929292; border: 2px solid #929292; border-radius: 5px; cursor: pointer;}
    }
  
    .video-input {
      & { height: 80%; width: 100%; display: flex; flex-direction: column; gap: 20px; align-items: center; justify-content: center; }
      .video-input-input { display: none; }
      video { max-width: 700px; max-height: 400px; }
      .upload-video-btn { width: 200px; height: 36px; background: #007C89; color: #fff; border-radius: 15px;  }
    }
  
    .upload-btn {
      & { height: 10%; width: 100%; display: flex; align-items: center; justify-content: center; }
      .upload-btn-btn { width: 200px; height: 36px; background: #007C89; color: #fff; border-radius: 15px;  }
    }
  
  }
  
  //gif upload modal
  .gif-upload-container {
    & { padding: 1rem; width: 700px; height: 500px; display: flex; flex-direction: column; align-items: center; justify-content: space-between; background-color: #fff; border-radius: 8px; }
    .title { width: 100%; height: 10%; display: flex; align-items: center; justify-content: space-between; margin: 0 15px; margin-right: 25px;
      h2 { margin: 0; color: #4D5865; }
      .MuiSvgIcon-root { color: #929292; border: 2px solid #929292; border-radius: 5px; cursor: pointer;}
    }
  
    .search-gifs-container {
      & { position: relative; width: 100%; height: calc(100% - 50px); display: flex; flex-direction: column; align-items: center; justify-content: center; }
      .search-gifs {
        & { height: 10%; width: 100%; display: flex; align-items: center; justify-content: center; gap: 15px; }
        input { padding: 0 0 0 5px; height: 30px; width: 83%; border: 1px solid #bbb; border-radius: 5px; outline: none; }
        input:focus { outline: none; }
        .search-gif-btn { width: 15%; height: 30px; background: #007C89; color: #fff; border-radius: 5px; }
      }
      .gif-results {
        & { height: 90%; width: 100%; overflow-y: scroll; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 5px; }
        .gif {
          & { cursor: pointer; height: 130px; width: 130px; border: 1px solid #bbb;  }
          img { height: 100%; width: 100%; object-fit: contain; }
        }
      }
      .search-the-gif {
        & { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
      }
      .gif-results-loading {
        & { height: 90%; display: flex; align-items: center; justify-content: center; }
      }
    }
  }

  .edit-about-container{
    &{max-width: 90%; width: 550px; padding: 20px; border-radius: 10px; background-color: #ffffff; margin: auto;}
    .header{width: 100%; display: flex; justify-content: space-between; align-items: center;}
    h1{color: #000;}
    textarea{width: 100%; padding: 10px; margin: 20px auto;}
    .buttons{&{width: 100%; display: flex; justify-content: space-between; align-items: center; gap: 20px;}
      .cancel-btn{ color: #CD4A4A; outline: 1px solid #CD4A4A; width: 100%; flex-shrink: 1; text-align: center; padding: 10px; border-radius: 5px; cursor: pointer; min-width: 200px;}
      .edit-btn{ color:#fff; background-color:  #CD4A4A; width: 100%; flex-shrink: 1; text-align: center; padding: 10px; border-radius: 5px; cursor: pointer; min-width: 200px;}
    }
  }
  @media screen and (max-width: 750px) {
    .edit-about-container{ margin-top: 22vh;}
  }
  @media screen and (max-width: 550px) {
    .edit-about-container .buttons{ & {display: block; }
      .cancel-btn{margin-bottom: 20px;}
    }
  }
    
  
  // Avatar text
  .avatar-text { height: 35px; width: 35px; border-radius: 100%; display: flex; align-items: center; justify-content: center;}
  
  .connect-setup {
  
    & { text-align: center; padding: 0 20px; }
    .MuiSvgIcon-fontSizeLarge { font-size: 100px; color: #badc58; }
    button { width: 200px; }
    button a { text-decoration: none; color: #fff; }
  }
  
  .paywall {
  
    header {
      & {display: flex; flex-direction: column; justify-content: center !important; }
      .network-img { 
       // & {  }
        img { border-radius: 25px; max-height: 200px; }
        .initial { border-radius: 25px; width: 200px; min-width: 200px; height: 200px; display: flex; align-items: center; justify-content: center; }
  
      }
      .network-desc {max-width: 350px; text-align: center;}
    }
    .network-info { width: 80%; max-width: 400px; margin: 0 auto; }
  
    .payment-box { display: flex; align-items: center; justify-content: center; margin: 0 auto; height: 100%; max-width: 400px; width: 100%;}
  
  }
  
// invite to team
.invite-to-team {
  & { font-family: $lato !important; width: 600px; padding: 1rem; background-color: #fff; border-radius: 8px;}
  .TabsListUnstyled-root button {
    & { font-family: $lato; }
  }
  .invite-team-heading {
    & { display: flex; width: 100%; align-items: center; justify-content: space-between; }
    h3 { font-weight: bold; font-size: 24px; margin: 10px 0; }
    .close-btn { cursor: pointer; }
  }
  .invite-team-container { height: auto; }
  .link-box { width: 100% !important; margin-right: 16px; padding-left: 16px; justify-content: initial !important; }
  .link-copy { width: auto !important; }
  .invite-by-email {
      & { margin: 10px 0; display: flex; align-items: center; justify-content: space-between; }
      // .add-email { margin-right: 16px; }
      .email { background: #ffdfdf !important; color: #000 !important; }
      .remove-email { height: 14px !important; width: 14px !important; top: -5px !important; right: -5px !important; }
    }
    .send-btn-container {
      & { width: auto !important; margin: 0px; height: 35px; margin-top: 1rem; }
      .send-btn { display: flex; align-items: center; justify-content: center; width: auto !important; height: auto !important; }
    }
    .active { background: #cd4a4a !important; }
  .followers-heading { font-weight: bold; margin-bottom: 0px; }
  .frequency-members { 
    & {margin-top: 10px; display: flex; align-items: center;justify-content: center; flex-direction: column;}
    p{font-size: 18px; font-weight:500;}
    .loading{ display: flex; height: 100%; align-items: center; justify-content: center; height: 270px; }
  }

  ::-webkit-scrollbar-thumb { background: none !important; }
  ::-webkit-scrollbar-track { background: none !important; }

  .invite-member-tabs {
    & { display: grid; grid-template-columns: repeat(3, 1fr); width: 100%; gap: 1rem; }
    .tab-item {
      & { width: 100%; height: 35px; display: flex; align-items: center; justify-content: center; border-radius: 50px; background: #efefef; cursor: pointer; }
      &.active { background-color: #cd4a4a; color: #FFF; }
      &:hover { background: #dcdcdc; }
      &.active:hover { background-color: #cd4a4a; color: #FFF; }
    }
  }
  .tab-panel {
    & { padding: 1rem 0; }
  }
}

.btn-cancel { width: 50%; border: 1px solid #cd4a4a; border-radius: 5px; background: #fff; color: #cd4a4a; font-weight: normal; padding: 8px 24px; }
.btn-invite { width: 50%; padding: 8px 24px; background-color: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
.invite-to-team-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
  border-radius: 0px 0px 10px 10px;
  font-family: "Lato", sans-serif;
  gap: 2rem;

   .btn-cancel {
      width: auto;
      border: 1px solid #cd4a4a;
      border-radius: 5px;
      background: #fff;
      color: #cd4a4a;
      font-weight: normal;
      padding: 8px 24px;
      font-family: "Lato", sans-serif;
      text-transform: uppercase;
   }

   .btn-invite {
      width: auto;
      // margin-right: 12px;
      margin-left: 20px;
      padding: 8px 24px;
      background-color: #cd4a4a;
      font-weight: bold;
      border: 1px solid #cd4a4a;
      border-radius: 5px;
      font-family: "Lato", sans-serif;
      text-transform: uppercase;
   }
}  

.invitation-container {
  & { width: 505px; height: 281px; font-family: $lato; }

  .invite-header {
    & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
    h2 { font-size: 24px; font-weight: 700; margin-bottom: 16px; }
    .close {
      & { cursor: pointer; height: 24px; width: 24px; }
    }
  }

  .profile-container {
    & { display: flex; flex-direction: column; align-items: center; gap: 8px; margin-bottom: 32px; }
    .user-img {
      & { height: 165px; width: 165px; }
      img { height: 165px; width: 165px; border-radius: 100%; object-fit: cover; }
      span { display: flex; align-items: center; justify-content: center; height: 165px; width: 165px; border-radius: 100%; }
    }
    .user-name {
      & {  }
      .name { font-size: 24px; font-weight: 700; color: #17170F; text-align: center; }
      .username { font-size: 16px; font-weight: 500; color: #9B9B9B; text-align: center; }
    }
  }

  p { font-size: 20px; color: #17170F; text-align: center; margin-bottom: 32px; font-weight: 600; }

  .action-btns {
    & { display: flex; align-items: center; justify-content: space-around; }
    .red-btn {
      & { text-transform: capitalize; font-size: 20px; width: 211px; height: 40px; color: #FFF; background: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
    }
    .white-bg-btn {
      & { background: #FFF; color: #cd4a4a; }
    }
  }
 
}

.notification-settings {
  & { width: 582px; height: 350px; display: flex; flex-direction: column; align-items: center; justify-content: center; background: #FFFFFF; padding: 1rem; border-radius: 8px; }
  .notification-header {
    & { height: 15%; width: 100%; text-align: center; margin-bottom: 40px; display: flex; align-items: center; justify-content: space-between; }
    h2 { margin: 0; font-size: 24px; color: #000; font-family: $lato; }
    .close { cursor: pointer; }
  }
  .notification-preference {
    & { height: 55%; width: 100%; display: flex; flex-direction: column; gap: 16px; }
    .item {
      & { display: flex; margin: 8px 0; }
      .item-details {
        & { width: 50%; }
        .item-main-text { font-size: 20px; color: #000; font-family: $lato; font-weight: bold; }
        .item-secondary-text { font-size: 16px; color: #000; font-family: $lato; }
      }
      .item-control {
        & { width: 50%; display: flex; justify-content: flex-end; padding-right: 10px; }
        .switch {
          font-size: 1em;
          position: relative;
          input { position: absolute; height: 1px; width: 1px; background: none; border: 0; overflow: hidden; padding: 0;
            + label {position: relative; min-width: 40px; border-radius: 16px; height: 25px; line-height: 32px; display: inline-block; cursor: pointer; outline: none; user-select: none; vertical-align: middle; text-indent: 96px; font-weight: bold; font-size: 28px; color: #333;}
            + label::before,
            + label::after { content: ""; position: absolute; top: 0; left: 0; width: 48px; bottom: 0; display: block; }
            + label::before { right: 0; background-color: #CACACA; border-radius: 24px; transition: .2s all; width: 48px; }
            + label::after { top: 2px; left: 3px; width: 21px; height: 21px; border-radius: 16px; background-color: #fff; transition: .2s all; }
            &:checked:hover + label::before,
            &:checked + label::before {
              background-color: #1AB53F;
            }
            &:checked + label::after {
              margin-left: 21px;
              background-color: #fff;
            }
            &:focus + label::before {
              outline: none;
            }
          }
        }
          input{
          &:checked + label::after { background-color: #FFF !important; }
          }
      }
    }
  }
  .invite-to-team-button-container {
    height: 20%;
    width: 100%;
    padding: 0;
    box-shadow: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 10px 10px;
 
    .btn-cancel {
        width: 50%;
        border: 1px solid #cd4a4a;
        border-radius: 5px;
        background: #fff;
        color: #cd4a4a;
        font-weight: normal;
        padding: 8px 24px;
    }
 
    .btn-invite {
        width: 50%;
        padding: 8px 24px;
        background-color: #cd4a4a;
        font-weight: normal;
        border: 1px solid #cd4a4a;
        border-radius: 5px;
    }
 }  
}


.confirm-delete {
  & { height: 200px; width: 450px; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; gap: 32px; background-color: #fff; border-radius: 8px; padding: 1rem; }
  .text { font-size: 20px; }  
  &>input {
    & { width: 100%; height: 30px; border: 2px solid #aaa; border-radius: 4px; }
  }
  .confirm-delete-btns {
    & { display: flex; width: 100%; gap: 1rem; }
  }
}
.confirm-delete-network {
  & { max-width: 500px; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; gap: 32px; background-color: #fff; border-radius: 8px; padding: 1rem; }
  .confirm-delete-btns {
    & { display: flex; width: 100%; gap: 1rem; }
  }
  .notice {
    & { display: flex; align-items: center; justify-content: center; gap: 1rem; background: #c9830050; padding: 1rem; border-radius: 5px; }
  }
  h3 { font-size: 22px; }
  li { font-size: 18px; }
}

.create-attachment {
  & { height: 70vh; width: 60vw; display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: #fff; border-radius: 8px; padding: 1rem; }
  .text { font-size: 20px; }  
  &>input {
    & { width: 100%; height: 30px; border: 2px solid #aaa; border-radius: 4px; }
  }

  .import-media {
    & { position: relative; flex-grow: 1; width: 100%; height: 80%; display: flex; align-items: center; justify-content: center; flex-direction: column; border: 1px solid #CACACA; border-radius: 5px; }
    .import-close { z-index: 100; cursor: pointer; position: absolute; top: 20px; right: 20px; background: #ffffff60; border-radius: 100%; }
    h3 { margin: 0; font-size: 20px; font-weight: 700; font-family: $lato; margin-bottom: 16px;  }
    .import-btn { width: 203px; height: 32px; color: #FFF; background: #CD4A4A; font-size: 16px; text-transform: capitalize; border-radius: 5px; }
    .info-btn { margin-top: 1rem; z-index: 100; }
    .file-input { display: none; }

    .carousel-container {
      & { height: 100%; width: 100%; }
      .file-preview-holder {
        & { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; }
      }
      .file-preview-image {
        & { padding: 1rem; height: 100%; width: 100%; object-fit: contain; }
      }
      .file-preview-video {
        & { padding: 1rem; height: 100%; width: 100%; object-fit: contain; }
      }
      .text-file-preview {
        & { padding: 1rem; height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; }
        .file-preview-text {
          & { font-size: 18px; font-weight: 600; color: #1d1d1d; }
        }
        .file-preview-type {
          & { font-size: 14px; font-weight: 600; color: #6b6b6b; }
        }
      }

    }

    .d-n-d {
      & { position: absolute; height: 100%; width: 100%; opacity: 0; top: 0; left: 0; right: 0; bottom: 0; }
      .presentation {
        & { height: 100%; }
      }
      .dropzone {
        & { height: 100%; }
      }
      // .text-center {
      //   & { display: none; }
      // }
    }

  }

  .attachment-rename-input {
    & { width: 100%; height: 40px; border: 2px solid #aaa; border-radius: 4px; padding: 0 1rem; font-size: 17px; font-weight: 500; }
    &:focus { outline: none; }
    &:active { outline: none; }
  }


  .confirm-delete-btns {
    & { width: 100%; height: 10%; display: flex; align-items: center; justify-content: flex-end; padding: 8px 0; border-radius: 0px 0px 10px 10px; font-family: "Lato", sans-serif; gap: 2rem; }
  
    .btn-border { width: auto; border: 1px solid #cd4a4a; border-radius: 5px; background: #fff; color: #cd4a4a; font-weight: normal; padding: 8px 24px; font-family: "Lato", sans-serif; text-transform: uppercase; }
    .btn-upload { width: auto; margin-left: 20px; padding: 8px 24px; background-color: #cd4a4a; font-weight: bold; border: 1px solid #cd4a4a; border-radius: 5px; font-family: "Lato", sans-serif; text-transform: uppercase; }
  }  
  .download-checkbox .MuiSwitch-track {
    & { background-color: #cd4a4a !important; }
  }
}


.create-event {
  & { height: auto; width: 550px; display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: #fff; border-radius: 8px; padding: 1rem; }
  .file-input { display: none; }
  .input-box {
    & {     font-size: 16px; height: 40px; width: 100%; border: 2px solid #aaa; border-radius: 3px; transition: 250ms ease-in-out; padding: 0 6px; margin-top: .5rem; }
    &:focus { outline: none; border: 2px solid #7d7d7d; }
    &:active { outline: none; border: 2px solid #7d7d7d; }
  }
  .event-details {
    & { height: 100%; width: 100%; }
    .event-image-title {
      & { display: flex; align-items: center; justify-content: center; }
      .event-image {
        & { height: 170px; width: 170px; border-radius: 5px; background: #eee; display: flex; align-items: center; justify-content: center; }
        img { height: 100%; width: 100%; object-fit: cover; border-radius: 8px; }
      }
      .title-details {
        & { width: calc(100% - 170px); height: 170px; padding: 1rem; padding-right: 0; }
        .net-public-private {
          & { display: flex; align-items: center; justify-content: center; margin-top: 25px; width: 250px; }
          .net-public { cursor: pointer; color: #17170F; font-weight: 700; text-transform: uppercase; width: 50%; height: 35px; display: flex; align-items: center; justify-content: center; border: 2px solid #b6b6b6; border-right: none; border-top-left-radius: 8px;  border-bottom-left-radius: 8px; }
          .net-private { cursor: pointer; color: #17170F; font-weight: 700; text-transform: uppercase; width: 50%; height: 35px; display: flex; align-items: center; justify-content: center; border: 2px solid #b6b6b6; border-left: none; border-top-right-radius: 8px;  border-bottom-right-radius: 8px; }
          .active { background: #3cbd4b; color: #fff; }
        }
      }
    }
    .event-details-input {
      & {  }
      .event-link {
        & { margin: 0.6rem 0; }
      }
      .event-description {
        & { margin: 0.6rem 0; }
      }
      .event-date {
        & { margin: 0.6rem 0; margin-top: 1rem; display: flex; align-items: center; justify-content: space-between; gap: 1rem; }
      }
    }
    .confirm-delete-btns {
      & { width: 100%; height: 10%; display: flex; align-items: center; justify-content: flex-end; padding: 8px 0; border-radius: 0px 0px 10px 10px; font-family: "Lato", sans-serif; gap: 2rem; }
    
      .btn-border { width: auto; border: 1px solid #cd4a4a; border-radius: 5px; background: #fff; color: #cd4a4a; font-weight: normal; padding: 8px 24px; font-family: "Lato", sans-serif; text-transform: uppercase; }
      .btn-upload { width: auto; margin-left: 20px; padding: 8px 24px; background-color: #cd4a4a; font-weight: bold; border: 1px solid #cd4a4a; border-radius: 5px; font-family: "Lato", sans-serif; text-transform: uppercase; }
    }  
  }

}

.post-comments {
  & {  width: 610px; height: 613px; display: grid; grid-template-rows: 50px 1fr 50px; }
  .comments-header {
    & { display: flex; align-items: center; justify-content: space-between; }
    .close { cursor: pointer; }
  }

  .comments {
    & { overflow-y: scroll; position: relative; }
    .no-comments {
      & { width: 51%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
    }
    .comment {
      & { display: flex; padding: 16px 0; border-bottom: 1px solid #e1e1e1; }
      &:last-child { border-bottom: none; }

      .comment-avatar {
        & { height: 40px; width: 40px; border-radius: 100%; }
        img { height: 40px; width: 40px; border-radius: 100%; object-fit: cover; }
        span { height: 40px; width: 40px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
      }
      .comment-details {
        & { padding: 0 1rem; width: calc(100% - 80px); }
        .username { font-family: $lato; font-size: 14px; font-weight: 700; color: #000; }
        .comment-text { font-family: $lato; font-size: 16px; color: #000; }
        .comment-actions {
          & { display: flex; align-items: center; justify-content: flex-start; gap: 1rem; }

          .comment-reply-btn { 
            & { height: 24px; width: 40px; background: #eee; margin: 5px 0; display: flex; align-items: center; justify-content: center; font-family: $lato; font-size: 12px; font-weight: 700; color: #9b9b9b; border-radius: 5px; cursor: pointer; transition: background 250ms ease-in-out, color 250ms ease-in-out; }
            &:hover { background: #CD4A4A; color: #FFF; }
          }
          .delete-comment-container { 
            & { height: 24px; margin: 5px 0; display: flex; align-items: center; justify-content: center; font-family: $lato; font-size: 12px; font-weight: 700; color: #9b9b9b; border-radius: 5px; cursor: pointer; }
            .delete-comment { 
              & { color: #aaa; transition: color 250ms ease; }
              &:hover { color: #CD4A4A; }
            }
          }
        }
        .comment-replies {
          & { width: calc(100% + 56px); }
          .reply-comment {
            & { display: flex; padding: 16px 0; border-bottom: 1px solid #e1e1e1; }
            &:last-child { border-bottom: none; }
            .reply-comment-avatar {
              & { height: 35px; width: 35px; border-radius: 100%; }
              img { height: 35px; width: 35px; border-radius: 100%; object-fit: cover; }
              span { height: 35px; width: 35px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
            }
            .reply-comment-details {
              & { padding: 0 1rem; width: calc(100% - 80px); }
              .username { font-family: $lato; font-size: 14px; font-weight: 700; color: #000; }
              .reply-comment-text { font-family: $lato; font-size: 16px; color: #000; }
              .comment-reply-btn { 
                & { height: 25px; width: 40px; margin: 5px 0; display: flex; align-items: center; justify-content: center; font-family: $lato; font-size: 12px; font-weight: 700; color: #9b9b9b; border-radius: 5px; cursor: pointer; transition: background 250ms ease-in-out; }
                &:hover { background: #00000020; }
              }
            }
            .reply-comment-likes {
              & { width: 30px; display: flex; flex-direction: column; align-items: center; justify-content: center; }
              .likes-icon {
                & { height: 16px; width: 16px; cursor: pointer; }
              }
              .likes-count {
                & { font-family: $lato; font-size: 12px; color: #9b9b9b; }
              }
            }
          }
        }
        .show-more-comment-btn { 
          & { height: 25px; width: 200px; margin: 5px 0; display: flex; align-items: center; justify-content: center; font-family: $lato; font-size: 13px; font-weight: 700; color: #9b9b9b; border-radius: 5px; cursor: pointer; transition: background 250ms ease-in-out; }
          &:hover { background: #00000020; }
        }
      }
      .comment-likes {
        & { width: 30px; height: 40px; display: flex; flex-direction: column; align-items: center; justify-content: center; }
        .likes-icon {
          & { height: 16px; width: 16px; cursor: pointer; }
        }
        .likes-count {
          & { font-family: $lato; font-size: 12px; color: #9b9b9b; }
        }
      }
    }
  }

  .add-comment {
    & { position: relative; display: flex; align-items: center; justify-content: center; gap: 8px; }
    .user-avatar {
      & { height: 40px; width: 40px; border-radius: 100%; }
      img { height: 40px; width: 40px; border-radius: 100%; object-fit: cover; }
      span { height: 40px; width: 40px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
    }
    .comment-input {
      & { width: calc(100% - 40px); height: 40px; padding-left: 10px; background: #FFFFFF; border: 1px solid #ccc; border-radius: 5px; }
      &:focus { outline: none; border: 1px solid #4a9fff; }
    }
    .post-btn {
      & { width: 95px; height: 40px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
    }
    .reply-user-details {
      & { position: absolute; width: 100%; background: #CD4A4A; top: -40px; color: #fff; height: 40px; border-radius: 5px; display: flex; justify-content: space-between; align-items: center; padding: 0 1rem;}
      .close-reply { cursor: pointer; }
    }
  }
}
.tagged-people-container {
  & {  width: 610px; height: 613px; display: grid; grid-template-rows: 50px 1fr; }
  .tagged-header {
    & { display: flex; align-items: center; justify-content: space-between; }
    .close { cursor: pointer; }
  }
  .tagged-people {
    & { position: relative; flex-grow: 1; width: 100%; margin: 16px 0; overflow-y: scroll; margin-bottom: 25px; }
    .member {
      & { height: 60px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
      .member-profile { 
        & { height: 100%; width: 150px; display: flex; gap: 1rem; align-items: center; justify-content: space-around;  }
        .member-img {
          // & { width: 40%; }
          img { height: 55px; width: 55px; border-radius: 100%; object-fit: cover; }
          span { display: inline-block; height: 55px; width: 55px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
        }

        .member-deets {
          & { width: 60%; display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
          .user { color: #707070; font-size: 16px; font-weight: 700; }
          .username { color: #A9A9A9; font-size: 12px; }
        }
      }
      // .invite-btn { color: #fff; width: 100px; height: 40px; font-size: 16px; background: #1C97AA; border-radius: 5px; text-transform: none; }
      .follow-btn {
        & { text-transform: capitalize; font-size: 16px; width: 100px; height: 30px; color: #FFF; background: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
      }
      .unfollow-btn {
        & { text-transform: capitalize; font-size: 16px; width: 100px; height: 30px; color: #7d7d7d; background: #ffffff; font-weight: normal; border: 1px solid #7d7d7d; border-radius: 5px; }
      }
      .white-bg-btn {
        & { background: #FFF; color: #cd4a4a; }
      }
    }

    .more-btn { cursor: pointer; color: #A39F9F; position: fixed; bottom: 240px; left: 50%; right: 50%; z-index: 10; height: 20px; width: 20px; display: flex; align-items: center; justify-content: center; }
    .loading { flex-grow: 1; display: flex; align-items: center; justify-content: center; }
  }
}



.MuiTooltip-tooltip .tooltip-for-posts { 
  & { padding-left: 16px; } 
  li { font-size: 14px; }
  }


  //react image crop
.react-crop-container {
  & { height: 100%; width: 100%; }
}

.create-network-modal-container {
  & { padding:  0; width: 700px; background: #ffffff; border-radius: 8px; }
  &::-webkit-scrollbar { display: none; }
  .create-network {
    & { display: flex; flex-direction: column; min-height: 100%; padding-bottom: 10px; margin: 0 auto; width: 100%;  position: relative; box-shadow: 0 0 20px rgba(204, 204, 204, 0.4196078431); }

    .header-steps-container {
      & {  }
      .modal-header
      {
        &{display: flex;justify-content: space-between;align-items: center;}
        h2 {
          & { padding: 1rem; color: #000000; }
        }
        .close{
          &{margin-right: 1rem;}
        }
      }
      .steps {
        & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
        .step {
          & { position: relative; flex: auto; display: flex; align-items: center; justify-content: center; height: 43px; }
          &.active {background: #FFDFDF; color: #CD4A4A; font-weight: 600; }
          &.active::after { z-index: 100; position: absolute; right: -17px; content: ''; height: 30px; width: 30px; border-right: 2px solid #fff; border-top: 2px solid #fff; background: #FFDFDF; transform: rotate(45deg);}
          &.active-last {background: #FFDFDF; color: #CD4A4A; font-weight: 600; }
          &:last-child::after { display: none; }
        }
      }
    }

    .create-network-all-steps-content-container {
      & {  display: flex; flex-direction: column; justify-content: center; }
        .network-details-container {
          & { padding-top: 1rem; }
        }
        .network-details-container {
          .network-image-container {
            & { position: relative; padding: 0 1rem;  height: 175px; display: flex; align-items: center; justify-content: center; }
            .image-container {
              & {  cursor: pointer; height: 170px; width: 170px; background: #EEEEEE; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
              img { height: 170px; width: 170px; border-radius: 5px; object-fit: cover; }
            }
            .close { position: absolute; right: 32%; top: 0px; background-color: white; border-radius: 2px; }
          }
          .network-name-status-container {
            & { padding: 0 1rem; margin-top: 2.5rem; display: flex; align-items: center; justify-content: center; gap: 1rem;flex-direction: column; }
            .network-name-input {
              & { width: 100%; height: 40px; border: none; border-bottom: 1px solid #ccc; padding: 0 .5rem; transition: 250ms ease; }
              &:focus { outline: none; border-bottom: 1px solid #959595; }
            }
            .network-status {
              & { position: relative; width: 50%; display: flex; align-items: center; justify-content: center; }
              .status-public {
                & { cursor: pointer; height: 40px; width: 125px; background: #EEEEEE; color: #9B9B9B; border-radius: 4px 0 0 4px; display: flex; align-items: center; justify-content: center; gap: 10px; }
                &.active { background: #1AB53F; color: #FFF; }
              }
              .status-private {
                & { cursor: pointer; height: 40px; width: 125px; background: #EEEEEE; color: #9B9B9B; border-radius: 0 4px 4px 0; display: flex; align-items: center; justify-content: center; gap: 10px; }
                &.active { background: #1AB53F; color: #FFF; }
              }
  
              .tooltip-info {
                & { position: absolute; top: 25%; right: 0px; }
              }
            }
          }
          .select-topic-container {
            & { padding: 0 1rem; margin-top: 1.5rem; width: 100%; display: flex; align-items: center; justify-content: center;  }
            .MuiInput-underline:before { border-bottom: 1px solid #ccc; }
            .MuiInput-underline:after { border-bottom: 1px solid #959595; }
            .MuiInput-underline:focus:not(.Mui-disabled, .Mui-error):before { border-bottom: 1px solid #ccc; }
            .MuiFormControl-root { margin: 8px 0; }
            .MuiSelect-select.MuiInputBase-input.MuiInput-input { padding-left: 0.5rem; }
            .MuiFormLabel-root.MuiInputLabel-root { padding-left: 0.5rem; }
          }
    
          .headline-container {
            & { padding: 0 1rem; margin-top: 1.5rem; display: flex; align-items: center; justify-content: space-between; gap: 1rem; }
            .network-headline-input {
              & { width: 100%; height: 40px; border: none; border-bottom: 1px solid #ccc; padding: 0 .5rem; transition: 250ms ease; }
              &:focus { outline: none; border-bottom: 1px solid #959595; }
            }
          }
          .description-container {
            & { padding: 0 1rem; margin-top: 1.5rem; display: flex; align-items: center; justify-content: space-between; gap: 1rem; }
            .network-description-input {
              & { width: 100%; resize: none; border: 1px solid #ccc; padding: 0 .5rem; transition: 250ms ease; border-radius: 8px; padding: 8px; }
              &:focus { outline: none; border: 1px solid #959595; }
            }
          }
    
          .network-type-container {
            & { padding: 0 1rem; margin-top: 1.5rem; display: flex; align-items: center; }
          }
    
        }
    
        .network-access-container {
          & { padding: 0 2rem; }
          .network-access-inner-container {
            & { padding: 0 1rem; margin-top: 2rem; display: flex; align-items: center; justify-content: center; gap: 1rem;flex-direction: column; }
            .network-access {
              & { position: relative; width: 50%; display: flex; align-items: center; justify-content: center; }
              .access-public {
                & { cursor: pointer; height: 40px; width: 125px; background: #EEEEEE; color: #9B9B9B; border-radius: 4px 0 0 4px; display: flex; align-items: center; justify-content: center; gap: 10px; }
                &.active { background: #1AB53F; color: #FFF; }
              }
              .access-private {
                & { cursor: pointer; height: 40px; width: 125px; background: #EEEEEE; color: #9B9B9B; border-radius: 0 4px 4px 0; display: flex; align-items: center; justify-content: center; gap: 10px; }
                &.active { background: #1AB53F; color: #FFF; }
              }
            }
          }
          .network-type-container {
            & { padding: 0 1rem; margin-top: 2rem; display: flex; align-items: center;justify-content: center; }
          }
          .paid-network-toggle-container{
            &{ display: flex; flex-direction: column; align-items: center; justify-content: center;margin-top: 2rem}
            .paid-network-heading{
              &{ font-size: 1.3rem; }
            }
            .css-xjimrv-Switch{
              &{ width: 60px; }
            }
          }
          .paid-network-content-container{
            &{ width: 80%; margin: 30px auto; }
            input{
              &{ border: none; border-bottom: 1px solid #9b9b9b; outline: none; width: 100%; padding-bottom: 5px; }
            }
      
            .payment-type-radiogroup-container{
              &{ display:flex; justify-content: space-between; flex-direction: row;}
            }
      
            .recurring-payment-type-radiogroup-container{
              &{}
              .recurring-payment-type-flexbox{
                &{ display: flex; align-items: center; justify-content: space-between; }
                .recurring-payment-type-heading{
                  &{ font-size: 1rem; color: black; font-weight: 500; }
                }
              }
              .features-container{
                &{ display: flex; align-items: center; justify-content: space-between; }
                button{
                  &{ background-color: #cd4a4a; display: flex; align-items: center; gap: 15px; padding: 0px 10px; font-weight: 500; text-transform: capitalize; color: #FFF; border: none; border-radius: 5px; font-size: 14px;}
                  span{
                    &{ font-size: 2rem; font-weight: 500; }
                  }
                }
              }
              .features-render-container{
                &{ display: flex; align-items: center; justify-content: space-between; }
                h3{ color: black; font-weight: 500; font-size: 1rem; }
              }
              .recurring-payment-frequency-option{
                &{ margin-right: 0 !important; }
              }
            }
      
            .one-time-payment-container{
              .features-container{
                &{ display: flex; align-items: center; justify-content: space-between; }
                button{
                  &{ background-color: #cd4a4a; display: flex; align-items: center; gap: 15px; padding: 0px 10px; font-weight: 500; text-transform: capitalize; color: #FFF; border: none; border-radius: 5px; font-size: 14px;}
                  span{
                    &{ font-size: 2rem; font-weight: 100; }
                  }
                }
              }
            }
          }
          
        }
       
    
        .network-invite-members-container {
          & { flex: auto; padding: 0 1rem; margin-top: 1.5rem; }
    
          .invite-member-tabs {
            & { display: flex; align-items: center; justify-content: center; gap: 1rem; }
            .tab-item {
              & { font-weight: 600; width: 30%; height: 35px; display: flex; align-items: center; justify-content: center; cursor: pointer; }
              &.active { border-bottom:2px solid #cd4a4a; color: #cd4a4a; }
              &:hover { background: #f5f5f5; }
              &.active:hover { background: #FFF; border-bottom:2px solid #cd4a4a; color: #cd4a4a; }
            }
          }
    
          .invite-member-tab {
            & { margin-top: 1rem; }
            .invite-member-container {
              & { position: relative;  }
              h1 { font-size: 30px; color: #718194; margin: 5px 0; }
    
              .invite-by-link {
                & { margin: 10px 0; }
                span { font-size: 20px; color: #707070; font-weight: 700; }
                .link { 
                  & { margin-top: 5px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
                  .link-box { width: 80%; height: 35px; color: #898787; font-weight: 600; font-size: 16px; border: 1px solid #AEAEAE; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
                  .link-copy { 
                    & { cursor: pointer; width: 18%; height: 35px; padding: 0 10px; color: #606060; font-weight: 700; border: 1px solid #AEAEAE; border-radius: 5px; display: flex; align-items: center; justify-content: space-around; }
                    img { height: 25px; width: 25px; opacity: 65%; }
                  }
                }
              }
    
              .invite-members {
                & { margin: 10px 0 16px 0; }
                span { font-size: 20px; color: #707070; font-weight: 700; }
                .search-member { 
                  & {  margin-top: 5px; width: 100%; display: flex; align-items: center; justify-content: space-evenly; border: 1px solid #AEAEAE; border-radius: 5px; }
                  .search-icon { color: #A7A7A7; cursor: pointer; height: 25px; width: 25px;}
                  input {
                    & { width: 90%; height: 35px; font-size: 16px; border: 0px; outline: none; }
                  }
                  input:focus { outline: none; }
                  ::-webkit-input-placeholder { color: #C9C9C9; }
                  ::placeholder { color: #C9C9C9; }
                }
              }
    
              .frequency-members {
                & { position: relative; margin: 25px 0; max-height: 300px; overflow-y: scroll; margin-bottom: 25px; }
                .member {
                  & { height: 60px; width: 100%; margin: 5px 0; display: flex; align-items: center; justify-content: space-between; }
                  .member-profile { 
                    & { height: 100%; width: 150px; display: flex; align-items: center; justify-content: space-around;  }
                    .member-img {
                      & { width: 40%; }
                      img { height: 55px; width: 55px; border-radius: 100%; object-fit: cover; }
                      span { display: inline-block; height: 55px; width: 55px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                    }
    
                    .member-deets {
                      & { width: 60%; display: flex; flex-direction: column; align-items: flex-start; justify-content: center; }
                      .user { color: #707070; font-size: 16px; font-weight: 700; }
                      .username { color: #A9A9A9; font-size: 12px; }
                    }
                  }
                  .invite-btn { color: #fff; width: 100px; height: 40px; font-size: 16px; background: #1C97AA; border-radius: 5px; text-transform: none; }
                }
    
                .more-btn { cursor: pointer; color: #A39F9F; position: fixed; bottom: 240px; left: 50%; right: 50%; z-index: 10; height: 20px; width: 20px; display: flex; align-items: center; justify-content: center; }
                .loading { height: 200px; display: flex; align-items: center; justify-content: center; }
              }
    
              .invite-by-email {
                & { margin: 10px 0; }
                span { font-size: 20px; color: #707070; font-weight: 700; }
                .add-email { 
                  & { margin-top: 5px; width: 100%; max-height: 120px; overflow-y: scroll; display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start; border: 1px solid #AEAEAE; border-radius: 5px; }
                  .email { 
                    & { height: 30px; color: #fff; padding: 3px 5px; margin: 10px 5px; background: #77C6D2; border-radius: 5px; position: relative; }
                    .remove-email { 
                      & { cursor: pointer; height: 17px; width: 17px; background: #F00; color: #fff; position: absolute; top: -7px; right: -7px; display: flex; align-items: center; justify-content: center; border-radius: 100%;  }
                      .rm-email { height: 15px; width: 15px; }
                    }
                }
                  input {
                    & { padding: 5px; height: 30px; width: 100%; font-size: 16px; border: 0px; outline: none; }
                  }
                  input:focus { outline: none; }
                  ::-webkit-input-placeholder { color: #C9C9C9; }
                  ::placeholder { color: #C9C9C9; }
                }
              }
              .send-btn-container {
                & { width: 100%; height: 40px; display: flex; justify-content: flex-end; margin: 10px 0; }
                .send-btn { color: #fff !important; width: 100px; height: 40px; font-size: 16px; background: #BEBEBE; border-radius: 5px; text-transform: none; }
                .active { background: #1C97AA; }
              }
              .close-btn { cursor: pointer; border: 2px solid #656565; color: #656565; border-radius: 5px; position: absolute; top: 0; right: 0; }
            }
          }
        }
    
        .paid-network-toggle-container{
          &{ display: flex; flex-direction: column; align-items: center; justify-content: center;}
          .paid-network-heading{
            &{ font-size: 1.3rem; }
          }
          .css-xjimrv-Switch{
            &{ width: 60px; }
          }
        }
        
        .paid-network-content-container{
          &{ width: 80%; margin: 30px auto; }
          input{
            &{ border: none; border-bottom: 1px solid #9b9b9b; outline: none; width: 100%; padding-bottom: 5px; }
          }
    
          .payment-type-radiogroup-container{
            &{ display:flex; justify-content: space-between; flex-direction: row;}
          }
    
          .recurring-payment-type-radiogroup-container{
            &{}
            .recurring-payment-type-flexbox{
              &{ display: flex; align-items: center; justify-content: space-between; }
              .recurring-payment-type-heading{
                &{ font-size: 1rem; color: black; font-weight: 500; }
              }
            }
            .features-container{
              &{ display: flex; align-items: center; justify-content: space-between; }
              button{
                &{ background-color: #cd4a4a; display: flex; align-items: center; gap: 15px; padding: 0px 10px; font-weight: 100;}
                span{
                  &{ font-size: 2rem; font-weight: 100; }
                }
              }
            }
            .features-render-container{
              &{ display: flex; align-items: center; justify-content: space-between; }
              h3{ color: black; font-weight: 500; font-size: 1rem; }
            }
            .recurring-payment-frequency-option{
              &{ margin-right: 0 !important; }
            }
          }
    
          .one-time-payment-container{
            .features-container{
              &{ display: flex; align-items: center; justify-content: space-between; }
              button{
                &{ background-color: #cd4a4a; display: flex; align-items: center; gap: 15px; padding: 0px 10px; font-weight: 100;}
                span{
                  &{ font-size: 2rem; font-weight: 100; }
                }
              }
            }
          }
        }
        
        .invite-to-team-button { 
          & { display: flex; align-items: center; justify-content: flex-end; }
          .btn { width: 100%;  padding: 8px 24px; background-color: #cd4a4a; font-weight: bold; border: 1px solid #cd4a4a; border-radius: 5px; font-family: "Lato", sans-serif; text-transform: uppercase; color: #ffffff; }
        }  
    
      }
      .network-moderation-container {
        & { padding: 0 2rem; }
        .moderation-flagged {
          & { margin-top: 1.5rem; }
          .title { font-weight: 600; }
          .range-slider {
            & { margin: 1rem 0; }
            .MuiSlider-valueLabelOpen {
              & { top: 50px; background: transparent; color: #000000; }
            }
  
          }
          .comment-container {
            & { display: flex; align-items: center; gap: 1rem; }
            .profile {
              & { display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 8px; }
              .profile-image {
                & { height: 30px; width: 30px; }
                img { height: 100%; width: 100%; border-radius: 100%; }
              }
              .profile-name {
                & { font-size: 10px; }
              }
            }
            .message-text { 
              & { font-weight: 500; background: #FFF; box-shadow: 0px 3px 6px #00000023; padding: 8px 20px; border-radius: 3px; }
            }
          }
        }
        .moderation-removed {
          & { margin-top: 2rem; }
          .title { font-weight: 600; }
          .range-slider {
            & { margin: 1rem 0; }
            .MuiSlider-valueLabelOpen {
              & { top: 50px; background: transparent; color: #000000; }
            }
  
          }
          .comment-container {
            & { display: flex; align-items: center; gap: 1rem; }
            .profile {
              & { display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 8px; }
              .profile-image {
                & { height: 30px; width: 30px; }
                img { height: 100%; width: 100%; border-radius: 100%; }
              }
              .profile-name {
                & { font-size: 10px; }
              }
            }
            .message-text { 
              & { font-weight: 500; background: #FFF; box-shadow: 0px 3px 6px #00000023; padding: 8px 20px; border-radius: 3px; }
            }
          }
        }
      }
      .navigate-btn-container {
        & { padding: 0 1rem; margin: 2rem 0 1rem 0; display: flex; align-items: center; justify-content: space-between;  width: 100%; background: white; }
        .prev-btn {
          & { font-weight: 600; text-transform: capitalize; width: 100px; height: 40px; color: #CD4A4A; border: 2px solid #CD4A4A; }
        }
        .next-btn {
          & { margin-left: auto; font-weight: 600; text-transform: capitalize; width: 100px; height: 40px; background: #CD4A4A; color: #FFF; }
        }
      }
    }

}

.add-links-container {
  & { height: 350px; width: 400px; background-color: #fff; padding: 1rem; border-radius: 8px; }
  .links-modal { 
    & { display: flex; flex-direction: column; justify-content: space-between; height: 100%; }
  }
  .link-header {
    & { display: flex; align-items: start; }
  }
  .add-links {
    & { display: flex; flex-direction: column; width: 100%; gap: 3rem;  }
    .title {
      & { height: 48px; width: 100%; } 
      input { 
        & { height: 100%; width: 100%; }
      }
    }
    .link-url {
      & { height: 48px; width: 100%; }
      input {
        & { height: 100%; width: 100%; }
      }
    }
  }
  .buttons {
    & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
  }
}

.MuiTooltip-popper {
  & { z-index: 10000000 !important; }
}


@media screen and (max-width: 1279px) {
  .create-network-modal-container{width: 700px;}
  .create-network-modal-container .create-network{width: auto !important; }
  .create-network-modal-container .create-network .header-steps-container h2{font-size: 1.1rem !important;}
  .create-network-modal-container .create-network .header-steps-container .step{height: 30px !important; font-size:12px !important;}
  .create-network-modal-container .create-network .header-steps-container .step::after{height: 20px !important; width: 20px !important; right: -11px !important;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-image-container .image-container{width: 190px !important; height: 190px !important;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container{flex-direction: column !important; margin-top: 1.5rem;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container .network-status{width: 100% !important; }
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container input{width: 100% !important;}
}

@media screen and (max-width: 768px) {
  .create-network-modal-container{width: 100%;height: 100%;}
  .create-network-modal-container .create-network{width: auto !important; }
  .create-network-modal-container .create-network .header-steps-container h2{font-size: 1.1rem !important;}
  .create-network-modal-container .create-network .header-steps-container .step{height: 30px !important; font-size:12px !important;}
  .create-network-modal-container .create-network .header-steps-container .step::after{height: 20px !important; width: 20px !important; right: -11px !important;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-image-container .image-container{width: 190px !important; height: 190px !important;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container{flex-direction: column !important;margin-top: 1.5rem;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container .network-status{width: 100% !important; }
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container input{width: 100% !important;}
}

@media screen and (max-width: 480px) {
  .create-network-modal-container{width: 100%;height: 100%;}
  .create-network-modal-container .create-network{width: auto !important; }
  .create-network-modal-container .create-network .header-steps-container h2{font-size: 1.1rem !important;}
  .create-network-modal-container .create-network .header-steps-container .step{height: 30px !important; font-size:11px !important;}
  .create-network-modal-container .create-network .header-steps-container .step::after{height: 20px !important; width: 20px !important; right: -11px !important;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-image-container .image-container{width: 190px !important; height: 190px !important;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container{flex-direction: column !important;margin-top: 1.5rem;}
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container .network-status{width: 100% !important; }
  .create-network-modal-container .create-network .create-network-all-steps-content-container .network-details-container .network-name-status-container input{width: 100% !important;}
}

  
@media only screen and (max-width: 750px) {
  .modal-root-div { &::-webkit-scrollbar { display: none; } }
  .modal-root-div .modal-container { margin: 36px 8px; width: 100%; }
}