@media screen and (max-width: 480px) {
    .network-wrapper{
        &{ position: relative; margin-left: 0 !important; }
        .network-sidebar-wrapper{
            &{min-width: auto !important; max-height: 100% !important; z-index: 5; position: absolute !important; left: 0; margin-top: 0 !important; }
            .network-sidebar-toogle-button{
                &{ top: 10% !important; }
            }
            .expanded{
                &{ width: 100% !important; }      
                .selected-network{
                    &{ width: 100% !important; }
                    .network-text{
                        &{ display: block !important; }
                    }
                }          
            }
        }
        .network-sidebar-open{
            &{ width: 75% !important; }
        }
        .network-sidebar-close{
            &{ width: 0!important; padding: 0 !important; }
        }
        .network-details-wrapper{
            &{ width: 100% !important;}
            .nav-container{
                &{ gap: 0px !important; left: 0 !important; width: 100% !important; height: auto !important;}
                .nav-tab {
                  & { margin: 0 !important; padding: 3px 4px; font-size: 12px !important; }
                }
            }
            .overview-container{
                .overview{
                    &{ min-height: 385px !important;}
                    .overview-img{
                        &{ height: 40% !important;  }
                    }
                    .network-overview{
                        .network-img{
                            &{ left: 22px !important; top: -74px !important; width: 80px !important; height: 80px !important; }
                            img{
                                &{ min-width: auto !important; width: 100% !important; height: 100% !important; max-height: auto !important; }
                            }
                            .initial{
                                &{ min-width: auto !important; width: 100% !important; height: 100% !important; max-height: auto !important; }
                            }
                        }
                        .network-info{
                            &{ flex-direction: column; }
                            .network-headline{
                                &{ width: 100% !important; }
                            }
                        }
                    }
                }
                .member-activity{
                    &{ flex-direction: column !important; }
                    .members{
                        &{ width: 100% !important; min-width: auto !important; }
                    }
                }
            }
            .invite-members{
                &{ height: auto !important; font-size: .8rem !important; }
            }
        }
    }

    .top-navbar{
        .nav-container{
            &{ z-index: 6 !important; }
            ul{
                &{ z-index: 6 !important;}
            }
        }
    }
    .live-container { overflow-y: auto !important; }
    .live-container .live-stream-viewer .live-stream { height: 240px !important; width: 100% !important; }
    .live-container .live-stream-catalog .stream-container { height: 205px !important; padding: 1rem; }
    .live-container .live-stream-catalog .stream-container .streams-carousel { height: 205px !important; }
    .live-container .live-stream-catalog .stream-container .stream>img { border-radius: 10px; }
    .live-container .live-stream-catalog .stream-container .stream .we-are-live { right: 3% !important; }
    .live-container .live-stream-catalog .stream-container .stream .view-count { right: 3% !important; }
    .live-container .live-stream-catalog .stream-container .stream .profile { left: 3% !important; }
    .live-container .live-stream-viewer .live-stream .live-video-player { height: 240px !important; }
    .live-container .live-stream-catalog .explore-streams .streams { gap: 1rem !important;     justify-content: center !important; }
    .live-container .live-stream-catalog .explore-streams .streams .stream-box { width: calc(50% - 1rem) !important; }
    .live-container .live-stream-catalog .explore-streams .streams .stream-box .stream-img  { &, &>img { height: 100px !important; width: 100% !important; }}
    .live-container .live-stream-viewer .live-stream .chat-icon { display: none !important; }

    .live-container .live-stream-viewer .live-stream .stream-details .live-indicator { display: none !important; }
    .live-container .live-stream-viewer .live-stream .stream-details .time-count { display: none !important; }
    .live-container .live-stream-viewer .live-stream .stream-details .view-count { display: none !important; }

    .live-container .live-stream-viewer  { flex-direction: column !important; align-items: center; justify-content: center;  }
    .live-container .live-stream-viewer .live-chat { display: flex !important; width: 100% !important; height: calc(100% - 240px) !important; }
    .live-container .live-stream-viewer .live-chat .live-chat-container { height: calc(100% - 55px) !important; }
    .live-container .live-stream-viewer .live-chat .chat-header { border-bottom: 1px solid #eee; }
    .live-container .live-stream-viewer .live-chat .chat-box-container { border-top: 1px solid #eee; }
    .live-container .live-stream-viewer .live-chat .chat-header .MuiSvgIcon-root { display: none; }

    .live-container .live-stream-viewer .live-stream .stream-details { min-height: 50px; width: 100% !important; max-width: 100% !important; background: linear-gradient(360deg, #d0d0d01e 0%, #3f3f3fa3 100%); top: 0 !important; left: 0 !important; }
    .live-container .live-stream-viewer .live-stream .stream-details .title { font-size: 14px !important; }
    .live-container .live-stream-viewer .live-stream .profile { display: none !important; }
}