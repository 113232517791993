// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$mainfont : 'Lato', sans-serif;
$mulish : 'Mulish', sans-serif;
$work-sans : 'Work Sans', sans-serif;
$lato :  'Lato', sans-serif;


.home-container {
  &  { font-family: $lato; height: 100%; width: 100%; }
    .drowdown-menu { position: absolute; z-index: 10000; top: 55px; right: 0; width: 260px; height: auto; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.0509803922); border-radius: 5px; opacity: 1; display: flex; flex-direction: column; padding: 10px 20px; }
    .drowdown-menu .drowdown-menu-element { cursor: pointer; display: flex; flex-direction: row; align-items: center; }
    .drowdown-menu .drowdown-menu-element h1 { text-align: left; font-family: "Lato"; font-size: 16px; font-style: normal; letter-spacing: 0px; color: #17170F; opacity: 1; }
    .drowdown-menu .drowdown-menu-element img { height: 24px; width: 24px; margin-right: 10px; }
    .nav-container {
      & { display: flex; align-items: center; justify-content: center; width: 100%; padding: 25px 0; background-color: #fff; }
      nav {
        & { padding: 0 25px; max-width: 1440px; display: flex; align-items: center; justify-content: space-between; width: 100%; transition: 250ms ease; }
        .nav-icon{ 
          &{ display: none; }
        }
        @media screen and (max-width: 900px) {
          flex-direction: column;
          .links {
            padding-top: 8px;
          }
        }
        @media screen and (max-width: 500px) {
          .links {
            nav {
              flex-direction: column;
            }
          }
        }
        .logo {
          img { max-width: 350px; min-width: 190px; width: 30vw; }
        }
        .links {
          // & {
          // 	margin-right: 60px;
          // }
          .link-container {
            & { display: flex; align-items: center; justify-content: center; gap: 15px; }
            // button {
            // 	font-family: $work-sans;
            // 	width: fit-content;
            // 	color: #fff;
            // 	background-color: transparent;
            // 	border: 3px solid #fff;
            // 	border-radius: 50px;
            // }
            .login-button { color: #000000; font-size: 20px; font-weight: bold; background-color: transparent; box-shadow: none; border: none; }
            .register-button { background-color: #cd4a4a; white-space: nowrap; padding: 0.5rem 1rem; }
            // button:hover {
            // 	background-color: #fff;
            // 	color: #00000090;
            // }
          }
          @media screen and (max-width: 500px) {
            .nav {
              flex-direction: column;
              .nav-links { width: 100%; }
            }

          }
          .nav {
            & { min-width: 320px; width: min(500px, 90vw); display: flex; align-items: center; justify-content: space-between; gap: min(10px, 5vw); 
            }
            .nav-links {
              & { color: #000; width: 60%; display: flex; align-items: center; justify-content: space-evenly; gap: 5px; }
              @media screen and (max-width: 500px) {
                width: 100%; padding: 8px 0; }
              span {
                color: #000;
                font-size: 16px;
              }
            }
            .profile {
              & { display: flex; align-items: center; gap: 5px; }
              img { width: 40px; height: 40px; border-radius: 100%; object-fit: cover; }
              span { color: #000; min-width: 70px; width: min(110px, 40vw); height: 20px; word-break: break-all; overflow: hidden; text-overflow: ellipsis; }
            }
          }
        }
        .menu-icon {
          display: none;
          height: 45px;
          width: 45px;
          color: #fff;
        }
        .mobile-links {
          & {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #27252d;
            height: 100vh;
            width: 100vw;
            margin-right: 100px;
            transition: background 250ms ease;
          }
          .MuiSvgIcon-root {
            color: #fff;
            height: 40px;
            width: 40px;
            margin-top: 20px;
          }
  
          .link-container {
            & {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 15px;
              margin: auto 0;
            }
            button {
              font-family: $work-sans;
              width: fit-content;
              color: #fff;
              background-color: transparent;
              border: 3px solid #fff;
              border-radius: 50px;
            }
            button:hover {
              background-color: #fff;
              color: #00000090;
            }
          }
          .nav {
            & {
              width: 100%;
              display: flex;
              flex-direction: column-reverse;
              align-items: center;
              justify-content: space-between;
              margin: auto 0;
            }
            .nav-links {
              & {
                color: #fff;
                width: 60%;
                margin-top: 35px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                gap: 15px;
              }
              span {
                text-decoration: underline;
                color: #fff;
                font-size: 16px;
              }
            }
            .profile {
              & {
                display: flex;
                align-items: center;
                gap: 5px;
              }
              img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
              }
              span {
                color: #fff;
                width: 110px;
                height: 20px;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .close {
          display: none;
        }
        //.open { display: block; }
      }
    }
     .main-section-container {
      & { margin-top: -3px; }
      .main-part-1-container {
        & { padding: 0 25px; width: 100%; background: #27252d; display: flex; align-items: center; justify-content: center; }
        .main-part-1 {
          & { max-width: 1367px; height: 100%; display: flex; align-items: center; justify-content: space-evenly; gap: 100px; }
          .phone-img {
            & { width: 50%; }
            img {width: 100%;}
          }
          .text-content {
             & { width: 50%; }
             h1 { font-size: 60px; font-family: $work-sans; font-weight: normal; color: #fff; margin: 5px 0; }
             h3 { font-size: 24px; font-family: $work-sans; font-weight: bold; color: #fff; margin: 5px 0; }
             p { font-family: $work-sans; font-style: italic; color: #fff; }
          }
        }
      }
  
      .main-part-2-container {
        & { width: 100%; height: 350px; background: url(../assets/img/home/earth.jpg) ; background-position: 50% 60%; background-repeat: no-repeat; background-size: cover; display: flex; align-items: center; justify-content: center; }
        .main-part-2 {
          & { padding: 0 25px; max-width: 1367px; height: 100%; display: flex; align-items: center; justify-content: space-evenly; gap: 100px; }
          .com-img {
            & { width: 45%; }
            img {width: 100%;}
          }
          .text-content {
             & { width: 55%; }
             h2 { font-size: 28px; font-family: $work-sans; font-weight: normal; color: #fff; margin: 5px 0; }
             p {
              & { font-family: $work-sans; font-size: 18px; font-weight: 400; color: #fff; margin: 0; line-height: 1.75; }
              span { font-weight: bold; }
            }
          }
        }
      }
  
      .main-part-3-container {
        & { width: 100%; height: 350px; display: flex; align-items: center; justify-content: center; }
        .main-part-3 {
          & { padding: 0 25px; max-width: 1367px; width: 1367px; height: 100%; display: flex; align-items: flex-start; justify-content: space-between; }
          .text-block {
            & { margin: 75px 0; width: 375px; }
            h2 {font-size: 32px; font-weight: 300; letter-spacing: -1px; line-height: 1.3; margin: 0;}
            p {
              & { font-family: $work-sans; font-size: 18px; font-weight: 400; margin: 0; line-height: 1.75; }
              span { font-weight: bold; }
            }
          }
        }
      }
  
      .main-part-4-container {
        & { width: 100%; background: #7047eb; height: 175px; display: flex; align-items: center; justify-content: center; }
        .main-part-4 {
          & { padding: 0 25px; max-width: 1367px; width: 1367px; height: 100%; display: flex; align-items: center; justify-content: space-between; gap: 50px; }
          h1 { width: 50%; font-size: 50px; font-weight: 300; line-height: 1.3; margin: 0; color: #fff; }
          .MuiButtonBase-root { width: 200px; height: 75px; border-radius: 50px; background: #ffc233; color: #27252d;  }
        }
      }
  
      .main-part-5-container {
        & { width: 100%; height: 300px; display: flex; align-items: center; justify-content: center; }
        .main-part-5 {
          & { padding: 0 25px; max-width: 1367px; width: 1367px; height: 100%; display: flex; align-items: center; justify-content: center; gap: 50px; }
         img { height: 90%; }
        }
      }
  
    }
    //footer {
    //  & { width: 100%; height: 175px;  background: #27252d; display: flex;  align-items: center; justify-content: center; }
    //  .footer-container {
    //    & { max-width: 1367px; width: 1367px; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 50px; }
    //    .terms {
    //      & { width: 100%; display: flex; align-items: center; justify-content: space-around;}
    //      a { color: #fff; font-size: 18px; }
    //    }
    //    span { font-size: 18px; color: #fff; }
    //  }
    //}
  }

.MuiFormHelperText-root {
  text-align: right !important;
}