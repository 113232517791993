// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$mainfont : 'Lato', sans-serif;
$mulish : 'Mulish', sans-serif;
$work-sans : 'Work Sans', sans-serif;
$lato :  'Lato', sans-serif;

$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #F5F7FB; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #f1f2f6;
$menudividerdark: #ced6e0;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #57606f;
$titlecolor2: #227093;


//profile
.profile-update-container {
    & { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; }
    .notification-settings {
      & { width: 582px; height: 368px; display: flex; flex-direction: column; align-items: center; justify-content: center; background: #FFFFFF; box-shadow: 0px 3px 10px #0000000D; border-radius: 10px; }
      .notification-header {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        & { height: 20%; width: 100%; text-align: center; }
        h2 { margin: 0; font-size: 24px; color: #000; font-family: $lato; }
      }
      .notification-preference {
        & { height: 80%; width: 100%; padding: 0 40px 40px 40px; display: flex; flex-direction: column; gap: 24px; }
        .item {
          & { display: flex;  }
          .item-details {
            & { width: 50%; }
            .item-main-text { font-size: 20px; color: #000; font-family: $lato; font-weight: bold; }
            .item-secondary-text { font-size: 16px; color: #000; font-family: $lato; }
          }
          .item-control {
            & { width: 50%; display: flex; justify-content: flex-end; }
            .switch {
              font-size: 1em;
              position: relative;
              input { position: absolute; height: 1px; width: 1px; background: none; border: 0; overflow: hidden; padding: 0;
                + label {position: relative; min-width: 40px; border-radius: 16px; height: 25px; line-height: 32px; display: inline-block; cursor: pointer; outline: none; user-select: none; vertical-align: middle; text-indent: 96px; font-weight: bold; font-size: 28px; color: #333;}
                + label::before,
                + label::after { content: ""; position: absolute; top: 0; left: 0; width: 48px; bottom: 0; display: block; }
                + label::before { right: 0; background-color: #CACACA; border-radius: 24px; transition: .2s all; width: 48px; }
                + label::after { top: 2px; left: 3px; width: 21px; height: 21px; border-radius: 16px; background-color: #fff; transition: .2s all; }
                &:checked:hover + label::before,
                &:checked + label::before {
                  background-color: #1AB53F;
                }
                &:checked + label::after {
                  margin-left: 21px;
                  background-color: #fff;
                }
                &:focus + label::before {
                  outline: none;
                }
              }
            }
              input{
              &:checked + label::after { background-color: #FFF !important; }
              }
          }
        }
      }
    }
  }