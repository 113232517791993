@media screen and (max-width: 480px) {
    .home-container{
        nav{
            &{ flex-direction: row !important; }
        }

        .logo {
            &{ display: flex; align-items: center; justify-content: center; }
        }
    }

    .nav-links{
        &{ display: none !important; padding: 0px !important; }
    }

    .home-container .nav-container nav .links .nav .profile span{
        &{ display: none; }
    }
    
    .home-container .nav-container nav .links .nav .profile{
        &{ width: auto; }
    }

    .home-container .nav-container nav .links .nav{
        &{ width: auto !important; min-width: auto !important; }
    }
    
    .home-container .nav-container nav .nav-icon{
        &{ display: block !important; }
    }

    .home-container .nav-container nav .logo img{
        &{ min-width: 135px !important;}
    }

    .PhoneSlider_wrapper__kOg6U{
        &{ z-index: 0;}
    }

    .Contact_wrapper__c-cyA .Contact_wrapper__main__ctIc4 .Contact_wrapper__main__content__1oNvo{
        z-index: 0 !important;
    }
  }