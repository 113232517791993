//explore container

.explore-container {
    & { height: 100%; background: #F6F7FA; max-width: 1366px; margin: 0 auto; }
    .explore {
      & { height: 100%; }
      .topic-container {
        & { height: 100%; padding: 10px 0px; }
        .topic {
          .heading { h1 {color: #17170F;}}
  
          .article-container {
            & { height: 340px; padding: 0 12px; display: flex; align-items: center; gap: 30px; overflow-x: scroll; }
            .article {
              & { cursor: pointer; box-shadow: 0 0 5px #eee; background: #FFF; border-radius: 20px; padding: 12px 0; height: calc(100% - 32px); }
              .article-img {
               & { width: 256px; height: 150px; border-top-left-radius: 20px; border-top-right-radius: 20px; }
                img { width: 256px; height: 150px; object-fit: cover; border-top-left-radius: 20px; border-top-right-radius: 20px; }
                span { width: 256px; height: 150px; object-fit: cover; border-top-left-radius: 20px; border-top-right-radius: 20px; }
              }
              .article-name {
                & { height: 95px; overflow: hidden; padding: 5px 1rem; margin: 2rem 0 0 0; }
                span { font-size: 16px; color: #000000; font-weight: 700; }
              }
              .article-date {
                //& {  }
                span { font-size: 12px; padding: 0px 15px; }
              }
            }
          }
        }
      }
    }
  }