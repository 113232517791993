.networks-page-container {
  & { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; }

  //----------------------------------- sidebar -------------------------------------
  .network-sidebar-container {
    & { position: relative; padding: 1rem; height: 100%; width: 377px; background: #ffffff; box-shadow: 3px 0px 6px #0000000d; padding-bottom: 0; transition: width 250ms ease-in-out; }

    .sidebar-toggle-btn {
      & { position: absolute; display: flex; align-items: center; justify-content: center; right: -10px; top: 50%; }
      img { height: 35px; width: 35px; cursor: pointer; transform: rotate(0deg); transition: transform 250ms ease-in-out; }
    }
    .sidebar-actions {
      & { height: 55px; width: 100%; display: flex; align-items: center; justify-content: space-between; padding-right: 10px; padding-left: 10px; position: relative; z-index: 10 }
      .sidebar-filters {
        & { height: 33px; width: 33px; cursor: pointer; }
      }
      .create-network-btn {
        & { cursor: pointer; overflow: hidden; position: relative; display: flex; align-items: center; justify-content: center; background: #1BA0A2; color: #ffffff; height: 38px; padding: 0 3rem; border-radius: 4px; font-size: 14px; font-weight: 600; }
        &>img {
          & { opacity: .3; height: 70px; width: 70px; position: absolute; bottom: -20px; left: -20px; }
        }
      }
      .create-network-btn-compact {
        & { margin: 0 auto; cursor: pointer; overflow: hidden; position: relative; display: flex; align-items: center; justify-content: center; border: 3px solid #1BA0A2; height: 50px; width: 50px; border-radius: 15px;  }
      }
    }
    .sidebar-networks {
      & { height: calc(100% - 55px); width: 100%; overflow-y: scroll; padding: 1rem 4px 0 0; }
      &::-webkit-scrollbar { display: none; }
      .network-slot-container {
        & { padding: 10px; border-radius: 8px; margin-bottom: 10px; }
        .network-slot {
          & { height: 50px; display: flex; align-items: center; cursor: pointer; }
          .active-network-border {
            & { height: 100%; width: 3px; background: #CD4A4A; border-radius: 2px; margin-right: 5px; }
          }
          .network-image {
            & { height: 50px; width: 50px; background: #d3d3d3; border-radius: 4px; display: flex; align-items: center; justify-content: center; }
            img { height: 100%; width: 100%; border-radius: 4px; object-fit: cover; }
          }
          .network-details {
            & { padding: 0 10px; display: flex; align-items: flex-start; }
            .text-details {
              & {}
              .network-title {
                & { font-size: 16px; font-weight: 600; color: #000; width: 20ch; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
              }
              .network-last-message {
                & { font-size: 11px; color: #ababab; width: 28ch; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
              }
            }
          }
        }
        .sub-network-container {
          & {  }
          .sub-network-slots-container {
            & {  }
            .sub-network-slot {
              & { height: 40px; display: flex; align-items: center; margin-top: 10px; cursor: pointer; }
              .active-network-border {
                & { height: 100%; width: 3px; background: #CD4A4A; border-radius: 2px; margin-right: 5px; }
              }
              .network-image {
                & { height: 40px; width: 40px; background: #d3d3d3; border-radius: 4px; display: flex; align-items: center; justify-content: center; }
                img { height: 100%; width: 100%; border-radius: 4px; object-fit: cover; }
              }
              .network-details {
                & { padding: 0 10px; display: flex; align-items: flex-start; }
                .text-details {
                  & {}
                  .network-title {
                    & { font-size: 15px; color: #000; width: 20ch; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
                  }
                  .network-last-message {
                    & { font-size: 11px; color: #ababab; width: 28ch; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
                  }
                }
              }
            }
          }
          .sub-network-show-all-container {
            & { width: 100%; display: flex; align-items: center; gap: 10px; margin-top: 10px; }
            .sub-network-show-all-slots {
              & { display: flex; align-items: center; justify-content: center; gap: 10px;}
              .sub-network-thumbnail {
                & { min-height: 30px; display: flex; align-items: center; }
                .network-image {
                  & { height: 30px; width: 30px; background: #d3d3d3; border-radius: 4px; display: flex; align-items: center; justify-content: center; }
                  img { height: 100%; width: 100%; border-radius: 4px; object-fit: cover; }
                }
              }
            }
            .sub-network-show-all-btn {
              & { cursor: pointer; height: 30px; display: flex; align-items: center; justify-content: center; background: #ffffff; color: #CD4A4A; border: 2px solid #CD4A4A; font-size: 14px; padding: 0 10px; border-radius: 4px; transition: all 250ms ease-in-out; }
              &:hover{color: #fff; background-color: #CD4A4A;}
            }

          }
        }
      }
      .active-network {
        & { background: #F6F7FA; }
      }
    }
  }

  // --------------------------- main network container ----------------------------------
  .network-main-container {
    & { position: relative; height: 100%; width: calc(100% - 377px); background: #F6F7FA; transition: width 250ms ease-in-out; overflow-y: scroll; }
    // &::-webkit-scrollbar { display: none; }
    .changing-network-overlay {
      & { z-index: 1000; position: absolute; height: 100%; width: 100%; background: #ffffff; display: flex; align-items: center; justify-content: center; }
    }
    .network-main {
      & { width: 100%; height: 100%; }
      .network-details-container {
        & { width: 100%; background: #ffffff; transition: all 250ms ease; padding: 1rem; }
        .network-details {
          & { max-width: 1000px; min-height: 300px; margin: 0 auto; }
          .network-banner-container {
            & { width: 100%; height: 425px; background: #909090; border-radius: 0 0 10px 10px; overflow: hidden; }
            .network-banner {
              & { height: 100%; width: 100%; }
              img {
                & { height: 100%; width: 100%; object-fit: cover; }
              }
            }
          }
          .network-info-container {
            & { padding: 1rem; }
            .network-info {
              & { display: flex; color: #000; padding-bottom: 1.5rem; }
              .network-title-image {
                & { display: flex; gap: 1rem; flex-direction: column; margin-top: -80px; }
                .network-image {
                  & { z-index: 10; height: 160px; width: 160px; border-radius: 5px; background: #ffffff; display: flex; align-items: center; justify-content: center; }
                  img { height: 100%; width: 100%; border-radius: 5px; object-fit: cover; }
                }
                .network-title-desc {
                  & { max-width: 500px; }
                  .network-title { font-size: 24px; font-weight: 600; }
                  .network-headline { font-size: 20px; font-weight: 600; margin-top: 5px; }
                  .network-description { margin-top: 3px; }
                }
              }
              .network-followers-join {
                & { flex: 1; display: flex; align-items: center; justify-content: space-evenly; flex-direction: column; gap: 1rem; }
                .network-activity-details {
                  & { display: flex; align-items: center; justify-content: center; gap: 2rem; }
                  .activity {
                    & { font-size: 24px; font-weight: 600; display: flex; flex-direction: column; align-items: center; justify-content: center; }
                  }
                }
                .network-join-btn {
                  & { margin-right: auto; }
                }
              }
            }

            .divider-div { height: 18px; width: 100%; }
            
            .network-navigation-tabs {
              & { display: flex; align-items: center; justify-content: flex-start; width: 100%; height: 0; }
              .nav-tabs {
                & { display: flex; align-items: center; justify-content: flex-start; padding-top: 1rem; width: 100%; }
                .tab {
                  & { cursor: pointer; display: flex; align-items: center; justify-content: center; height: 40px; gap: 8px; border-radius: 5px; padding: 0 12px; transition: 250ms ease-in-out; }
                  &:hover { background: #f8f6f6; }
                  .tab-name {
                    & { font-size: 14px; height: 16px; font-weight: 600; text-transform: uppercase; color: #707070; }
                  }
                }
                .active-tab {
                  & { background: #1BA0A2; }
                  &:hover { background: #1BA0A2; }
                  .tab-name {
                    & { color: #ffffff; }
                  }
                }
              }
							.network-settings {
								& { padding-top: 1rem; margin-left: auto; height: 40px; display: flex; align-items: center; justify-content: center; gap: 10px; }
								.setting-tab {
                  & { cursor: pointer; display: flex; align-items: center; justify-content: center; width: 40px; height: 40px; gap: 4px; border-radius: 5px; padding: 0 6px; transition: 250ms ease-in-out; }
                  &:hover { background: #f8f6f6; }
                }
                .active-setting-tab {
                  & { background: #1BA0A2; }
                  &:hover { background: #1BA0A2; }
                }
							}
            }
          }
        }
      }
      .network-tab-container {
        & { width: 100%; }
        .network-tab {
          & { width: 100%; margin: 0 auto; }
          .overview-top-container { 
            & { width: 100%; max-width: 1000px; margin: 0 auto; display: flex; justify-content: space-between; padding: 1rem ; gap: 40px; position: relative; }
            .poll-container{ & { max-width: calc(50% - 20px); } 
              .inner-container { & { display: flex; justify-content: center; align-items: flex-start; gap: 10px; width: 100%; }
                .poll-icon img { width: 32px; }
                .poll-details { 
                  .question { font-weight: 700; font-size: 18px; margin-top: 5px; }
                  .answers { &{ margin: 10px auto;}
                    .poll-input { & { display: flex; justify-content: center; align-items: center; gap: 10px; margin: 20px 0;}
                      input { width: 20px; height: 20px; background-color: transparent !important;  accent-color: #cd4a4a; }
                      .answer-box { & {width: 100%; flex-shrink: 1; display: flex; justify-content: space-between; align-items: flex-end; position: relative;} 
                        .answer { font-size: 18px; font-weight: 700;}
                        .number { font-size: 14px; color: #9b9b9b; }
                        .perentage-bar{& {position: absolute; width: 100%; height: 7px; border-radius: 10px; background-color: #ECECEC; bottom: -10px; overflow: hidden;}
                          .current-percentage{height: 100%; background-color: #cd4a4a;}
                        }
                      }
                    }
                  }
                }
              }
              .view-votes-button { color: #cd4a4a; font-size: 18px; cursor: pointer; text-align: center; font-weight: 700; margin: 10px auto 0; }
              .votes-container{ &{width: 100%; background-color: white; border-radius: 8px; margin-top: 10px;}
                .heading-box { & {display: flex; justify-content: flex-start; align-items: center; color: #cd4a4a; font-size: 18px; font-weight: 700; padding: 15px; border-bottom: 1px solid black; }
                    .section-name{width: 100%; flex-shrink: 1;}
                    .total-votes{ white-space: nowrap;}
                    .icon{cursor: pointer;}
                  }
                .answer-container { & { padding: 10px 15px 25px 15px; } 
                  .singleAnswer { & { border-bottom: 1px solid #ddd; display: flex; justify-content: flex-start; align-items: center; padding: 10px; color: #cd4a4a; font-size: 18px; font-weight: 700; width: 100%; gap: 10px;}
                    .answer { width: 100%; flex-shrink: 1;}
                    .votes { width: 20%;}
                    .view-button { padding: 5px 0; font-size: 14px; background-color: #CD4A4A; color: #ffffff; cursor: pointer; width: 120px; text-align: center;}
                  }
                }
                .single-answer-votes {
                  .user-votes{ & { padding: 10px 15px 25px 15px;}
                    .user { &{display: flex; justify-content: flex-start; align-items: center; margin: 15px auto; gap: 15px;}
                      img { width: 50px; height: 50px; border-radius: 100%;}
                      .name { font-size: 18px; font-weight: 700; color: #000;}
                      .time { color: #555555; font-size: 14px;}
                    }
                  }
                }
              }
            }
            .center-border{ width: 1px; height: calc(100% - 2rem); background-color: #D9D9D9; position: absolute; left: calc(50% - 0.5px);}
            .review-container{ & {display: flex; justify-content: center; align-items: flex-start; max-width: calc(50% - 20px); gap: 10px; }
              .image { & { display: flex; flex-direction: column; justify-content: center; align-items: center; }
                img { width: 70px; height: 70px; border-radius: 70px; }
                .name { font-weight: 700; margin-top: 5px; color: #000000; }
              }
              .message-box { & {margin-left: 10px}
                .message { font-weight: 700; line-height: 21px; font-size: 16px; color: #000000;}
                .time { color: #9B9B9B; text-align: end; font-weight: 700; margin-top: 5px;}
              }
            }
          }
          .discover-container {
            & { width: 100%; max-width: 1000px; min-height: 700px; margin: 0 auto; display: flex; justify-content: space-between; padding: 1rem ; gap: 40px; }
            // .posts-container {
              //   & { width: 50%; display: flex; gap: 20px; flex-direction: column; align-items: flex-start; justify-content: flex-start; }
            //   .follow-notice { width: 100%; text-align: center; }
            //   .post {
              //     & { width: 590px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
              //     .post-profile {
                //       & { display: flex; align-items: center; justify-content: space-between; padding: 10px; }
                //       .profile {
                  //         & { height: 64px; width: 100%; display: flex; gap: 16px; align-items: center; }
            //         .profile-img {
            //           & { height: 64px; width: 64px; border-radius: 100%; }
            //           img { height: 64px; width: 64px; border-radius: 100%; object-fit: cover; }
            //           span { height: 64px; width: 64px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
            //         }
            //         .profile-name {
            //           & { display: flex; flex-direction: column; }
            //           .user { 
            //             & {  font-size: 20px; font-weight: 700; color: #000;  }
            //             .tagged-users { cursor: pointer; }
            //             .live-indicator { font-weight: 600; color: #CD4A4A; margin-left: 10px; text-transform: uppercase; font-size: 1rem; }
            //           }
            //           .activity-time {  font-size: 16px; color: #000;  }
            //         }
            //       }
            //     }
            //     .post-caption {
            //       & { padding: 16px 24px; width: 90%; color: #000; font-weight: 600; }
            //     }
            //     .newsfeed-post-caption {
            //       & { font-size: 20px; width: 100%; }
            //     }
        
            //     .post-img {
            //       & { max-height: 700px; width: 100%; display: flex; align-items: center; justify-content: center; border-top: 1px solid #f1f1f1;  }
            //       img { object-fit: contain; height: 100%; width: 100%; }
            //       video { object-fit: contain; height: 100%; width: 100%; }
            //       .carousel-root { height: 100%; width: 100%; }
            //       .carousel .carousel-status {  font-size: 10px; text-shadow: none; right: 50px; background: #00000080; border-radius: 5px; }
            //       .carousel .thumbs-wrapper { display: none; }
            //       .carousel.carousel-slider { height: 100%; }
            //       .carousel.carousel-slider .control-arrow { opacity: 0.8; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; border-radius: 100%; background: #ffffff; right: 16px; top: 50%; }
            //       .carousel.carousel-slider .control-arrow::before { border-left: 8px solid #d2d2d2; }
            //       .carousel .control-prev.control-arrow { opacity: 0.8; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; border-radius: 100%; background: #ffffff; left: 16px; top: 50%; }
            //       .carousel .control-prev.control-arrow::before { margin-right: 10px; border-left: 0; border-right: 8px solid #d2d2d2; }
            //       .carousel .control-disabled.control-arrow { opacity: 0; }
            //       .carousel .slider-wrapper { height: 100%; }
            //       .carousel .control-dots { bottom: 0px; }
            //       .carousel .control-dots .dot { height: 10px; width: 10px; border: 1px solid #FFF; background: transparent; opacity: 1; box-shadow: 0 0 3px #919191; }
            //       .carousel .control-dots .dot.selected { background: #CD4A4A !important; }
            //       .carousel .slider-wrapper.axis-horizontal .slider { height: 100%; border-radius: 10px; }
            //       .carousel .slider-wrapper.axis-horizontal .slider .slide { height: 100%; border-radius: 10px; }
            //       .carousel .slider-wrapper.axis-horizontal .slider .slide .topic { position: absolute; top: 10px; left: 10px; font-size: 12px; padding: 5px; border-radius: 15px; background: #1BA1A1; color: #FFF; }
            //       .carousel .slider-wrapper.axis-horizontal .slider .slide .final-img-container { position: relative; height: 100%; border-radius: 10px; }
            //       .carousel.carousel-slider .control-arrow:hover { background: rgba(0,0,0,0.1); }
      
                  
            //     }
        
            //     .post-actions {
            //       & { height: 32px; width: 100%; padding: 0px 24px; margin: 16px 0; display: flex; align-items: center; justify-content: flex-start; gap: 16px; }
            //       .post-like {
            //         & { position: relative; display: flex; align-items: center; justify-content: center; gap: 8px; }
            //         img { height: 32px; width: 32px; cursor: pointer; }
            //         .like-heart { height: 32px; width: 32px; cursor: pointer; }
      
            //         .reaction-container {
            //           & { z-index: 100; position: absolute; top: -4rem; left: 0; display: flex; align-items: center; gap: 10px; padding: 10px 16px; background: #fff; box-shadow: 0 0 5px #00000050; border-radius: 12px; }
            //           img { height: 32px; width: 32px; cursor: pointer; }
            //         }
            //       }
            //       .post-comment {
            //         & { display: flex; align-items: center; justify-content: center; gap: 8px; }
            //         img { height: 32px; width: 32px; cursor: pointer; }
            //       }
            //       .post-delete {
            //         & { margin-left: auto; display: flex; align-items: center; justify-content: center; gap: 8px; }
            //         img { height: 32px; width: 32px; cursor: pointer; }
            //       }
            //     }
        
            //     .add-comment {
            //       & { padding: 16px 24px; display: flex; align-items: center; justify-content: center; gap: 8px; }
            //       .user-avatar {
            //         & { height: 32px; width: 32px; border-radius: 100%; }
            //         img { height: 32px; width: 32px; border-radius: 100%; object-fit: cover; }
            //         span { height: 32px; width: 32px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
            //       }
            //       .comment-input {
            //         & { width: calc(100% - 40px); height: 32px; padding-left: 10px; background: #FFFFFF; border: 1px solid #CACACA; border-radius: 5px; }
            //         &:focus { outline: none; border: 1px solid #4a9fff; }
            //       }
            //     }
            //   }
            //   .newsfeed{
            //     p{
            //       &{ padding: 0 20px; }
            //     }
            //     a {
            //       & { color: #2275B9; }
            //     }
            //   }
            //   .hubfeed{
            //     .post-actions{
            //       &{ display: flex; justify-content: space-between; }
            //       h4{
            //         &{ color: black; }
            //       }
            //       button{
            //         &{ background:#CD4A4A; width: 160px; padding: 10px 0;}
            //       }
            //     }
            //   }
            //   .streamfeed{
            //     .live-creator-details {
            //       & { background:#ffffff; color: #000000; font-size: 20px; font-weight: 700; height: 65px; width: 100%; display: flex; align-items: center; justify-content: flex-start; padding-left: 1rem; }
            //     }
            //     .watch-recording {
            //       & { background:#CD4A4A; color: #FFF; gap: 10px; font-size: 18px; height: 65px; width: 100%; display: flex; align-items: center; justify-content: center; border-radius: 0 0 10px 10px; }
                  
            //     }
            //   }
            // }
            .overview-chat-container { & { width: 100%; flex-shrink: 1; background-color: #ffffff; border-radius: 8px; padding: 30px; height: fit-content; }
            .add-reaction {
              & { height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; z-index: 100; background: #ffffff; position: absolute;   bottom: -20px; left: 60px; box-shadow: 0 0 10px #aaaaaa8e; }
    
              .reactions {
                & { position: relative; height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; }
                img {
                  & { cursor: pointer; width: 28px; height: 28px; transition: transform 200ms ease; }
                  &:hover { transform: scale(1.1); }
                }
                .close-reaction { height: 28px; width: 28px; cursor: pointer; color: #000000; margin-left: 1rem; }
              }
            }
              .messages {
                & { position: relative; width: 100%; min-height: 100%; max-height: 100%; overflow-x: hidden; display: flex; flex-direction: column-reverse; align-items: flex-start; }
                
                .message {
                  & { position: relative; max-width: 80%; margin: 0 10px 35px 0; display: flex; flex-direction: row-reverse; align-content: flex-end; align-items: center; justify-content: flex-end; gap: 25px; font-size: 15px;  }
                  .message-text {
                    & { position: relative; padding: 10px; display: flex; flex-direction: column; gap: 5px; align-items: flex-start; word-break: break-word; box-shadow: 0px 2px 10px #0000000F; }
                    strong { cursor: pointer; color: #3c80ff; display: contents; }
                    .reply-msg {
                      & { height: 100%; width: 100%; }
                      .reply-content {
                        & { font-size: 15px; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 0 5px 0; border-left: 4px solid #007c8950; }
                        .name {
                          color: #3483fa;
                          font-weight: 700;
                        }
                        .content { width: 100%; max-height: 60px; overflow: hidden; text-overflow: ellipsis; }
                      }
                    }
                    .message-emote-time {
                      & { position: absolute; bottom: -28px; left: 0; right: 0; width: 275px; height: 25px; display: flex; align-items: center; justify-content: flex-start; gap: 5px; }
                      .time { font-size: 12px; color: #9e9e9e; }
                      .reaction {
                        & { background: none; height: 25px; display: flex; align-items: center; min-width: 30px; border-radius: 5px; margin: 0 2px; }
                        img { width: 20px; height: 20px; }
                        .reaction-count { height: 25px !important; background: none; font-size: 12px; padding: 0; margin: 0; }
                      }
                    }
                  }
                  .message-emote-action {
                    & { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); right: -125px; display: flex; align-items: center; justify-content: space-evenly; }
                    .css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
                    &:hover { display: flex !important; }
                  }
      
                  &:hover {
                    .message-emote-action { display: flex !important; }
                  }
      
                  .chat-avatar {
                    & { cursor: pointer; position: relative; width: 35px; height: 35px; border-radius: 20px; align-self: flex-start; }
                    span { width: 35px; height: 35px; border-radius: 20px; }
                    img { width: 35px; height: 35px; border-radius: 20px; object-fit: cover; }
                    .name { cursor: pointer; width: 50px; position: absolute; bottom: -20px; left: 0; right: 0; font-size: 12px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
                  }
                  .chat-attachment { object-fit: contain; width: 100%; max-height: 450px; }
                  span {
                    & { position: relative; padding: 5px; min-height: 30px; border-radius: 7px; display: flex; align-items: center; justify-content: center; background-color: #ffffff; }
                    .play-btn {
                      & { cursor: pointer; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; }
                      .css-i4bv87-MuiSvgIcon-root { color: #fff; height: 35px; width: 35px; transition: transform 250ms ease;
                        &:hover {
                          transform: scale(1.2);
                        }
                      }
                    }
                  }
                }
      
                .message-mine {
                  & { align-self: flex-end; flex-direction: row; }
                  .chat-avatar { display: none; }
                  .message-emote-action {
                    & { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); left: -40px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-evenly; }
                    .css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
                    &:hover { display: flex !important; }
                  }
      
                  &:hover {
                    .message-emote-action { display: flex !important; }
                  }
                  .message-text {
                    & { position: relative; max-height: 50%; word-break: break-word; background: #CBEFD7; }
                    strong { cursor: pointer; color: #3c80ff; display: contents; }
                    .message-emote-time {
                      & { position: absolute; bottom: -28px; left: auto; right: 0px; width: 240px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start; }
                      .time { font-size: 12px; }
                    }
                  }
                  .add-reaction {
                    & { height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; z-index: 100; background: #ffffff; position: absolute;   bottom: -20px; right: 0px; left: auto; box-shadow: 0 0 10px #aaaaaa8e; }
          
                    .reactions {
                      & { position: relative; height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; }
                      img {
                        & { cursor: pointer; width: 28px; height: 28px; transition: transform 200ms ease; }
                        &:hover { transform: scale(1.1); }
                      }
                      .close-reaction { height: 28px; width: 28px; cursor: pointer; color: #000000; margin-left: 1rem; }
                    }
                  }
                }
      
                .no-messages {
                  & { color: #989898; user-select: none; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; }
                  .top-span { font-size: 20px; display: flex; align-items: center; justify-content: center; gap: 10px; }
                }
              }
              .action-box{ & {display: flex; justify-content: flex-end;}
                // button { background-color: #cd4a4a; color: #ffffff; padding: 10px 30px; margin-top: 20px; border-radius: 5px; }
              }
            }
            .recommend-container {
              & { width: 47%; height: max-content; display: flex; gap: 20px; flex-direction: column; align-items: flex-end; justify-content: flex-start; }
              .recommend-people {
                & { width: 80%; padding: 24px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
                .friend {
                  & { height: 48px; width: 100%; margin-top: 24px; display: flex; align-items: center; justify-content: space-between;  }
                  &:first-child { margin-top: 0; }
                  .profile {
                    & { display: flex; align-items: center; gap: 10px; }
                    .profile-img {
                      & { height: 48px; width: 48px; border-radius: 100%; }
                      img { height: 48px; width: 48px; border-radius: 100%; object-fit: cover; }
                      span { height: 48px; width: 48px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
                    }
                    .profile-name {
                      & { display: flex; flex-direction: column; }
                      .user {  font-size: 20px; font-weight: 700; color: #000;  }
                      .username {  font-size: 10px; color: #9B9B9B;  }
                    }
                  }
                  .follow-btn {
                    & { width: 95px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
                  }
                  .unfollow-btn {
                    & { text-transform: capitalize; width: 95px; height: 32px; color: #7d7d7d; background: #ffffff; font-weight: normal; border: 1px solid #7d7d7d; border-radius: 5px; }
                  }
                  .white-bg-btn {
                    & { background: #FFF; color: #cd4a4a; }
                  }
                }
              }
              .recommend-hubs {
                & { width: 95%; height: 315px; padding: 0 24px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
                .hub-container {
                  & { height: 48px; width: 100%; margin-top: 24px; display: flex; align-items: center; justify-content: space-between;  }
                  .hub {
                    & { display: flex; align-items: center; gap: 10px; }
                    .hub-img {
                      & { height: 48px; width: 48px; border-radius: 5px; }
                      img { height: 48px; width: 48px; border-radius: 5px; object-fit: cover; }
                      span { height: 48px; width: 48px; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
                    }
                    .hub-name {
                      & { display: flex; flex-direction: column; }
                      .user {  font-size: 20px; font-weight: 700; color: #000;  }
                      .username {  font-size: 10px; color: #9B9B9B;  }
                    }
                  }
                  .follow-btn {
                    & { width: 95px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
                  }
                }
              }
            }
            .side-container {
              & { height: max-content; width: 300px; background: #ffffff; padding: 10px 10px 20px 10px; border-radius: 8px; }
              .heading { display: flex; align-items: center; justify-content: space-around; width: 100%; }
              h1 { color: #17170F; margin: .5rem 0;}
              p { cursor: pointer; color: #9B9B9B; font-size: 14px; }
              .contents { width: 100%; }
              .profile-content {
                & { display: flex; width: 100%; align-items: flex-start; justify-content: center; padding: .3rem 1rem; }
                img { margin-top: 4px; height: 35px; width: 35px; border-radius: 50%; object-fit: cover; }
                .initial { margin-top: 4px; height: 35px; width: 35px; border-radius: 50%; display: flex; align-items: center; justify-content: center; }
                .profile-head { width: 100%; display: flex; justify-content: flex-start; gap: 15px; align-items: flex-start; }
                // button { width: 110px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
                button { width: 110px; }
                .profile-body {
                  h3 { margin: 4px 0; font-size: 18px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 150px; }
                  p { margin-bottom: 7px; margin-top: 7px;  font-weight: bold; }
                  .hub-owner { color: #1BA0A2; text-transform: uppercase; font-size: 12px; font-weight: 700; }
                  .hub-member { color: #88488F; text-transform: uppercase; font-size: 12px; font-weight: 700; }
                  .hub-moderator { color: #F38028; text-transform: uppercase; font-size: 12px; font-weight: 700; }
                  .hub-invited { color: #383838; text-transform: uppercase; font-size: 12px; font-weight: 700; }
                }
                .member-follow {
                  & { height: 32px; width: 115px; display: flex; align-items: center; justify-content: center; }
                  .MuiButtonBase-root { text-transform: capitalize; height: 32px; width: 115px; background: #CD4A4A; color: #fff; font-size: 14px; font-weight: 500; border-radius: 5px; box-shadow: none !important; }
                  .red-btn {
                    & { text-transform: capitalize; font-size: 20px; width: 115px; height: 32px; color: #FFF; background: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
                  }
                  .unfollow-btn {
                    & { text-transform: capitalize; font-size: 14px; font-weight: 500; width: 115px; height: 32px; color: #7d7d7d !important; background: #ffffff !important; font-weight: normal; border: 1px solid #7d7d7d; border-radius: 5px; }
                  }
                  .white-bg-btn {
                    & { background: #FFF; color: #cd4a4a; }
                  }
                }
              }
            }
          }

					.chat-container {
						& {  width: 100%; height: 100%; overflow: hidden; display: flex; flex-direction: column; justify-content: flex-end; }
            
						.chat {
              & { position: relative; padding: 1rem; padding-bottom: 0; margin: 0 auto; width: 100%; max-width: 1000px; flex-grow: 1; height: 90%; display: flex; align-items: flex-end; justify-content: flex-end; }

              .chat-filter-setting-icon {
                & { cursor: pointer; background: #ffffff; display: flex; align-items: center; justify-content: center; border-radius: 5px; padding: 10px; position: absolute; top: 10px; right: 35px; z-index: 10; }
              }
              .chat-filter-setting {
                & { height: 75vh; width: 400px; background: #ffffff; border-radius: 5px; position: absolute; top: 10px; right: 35px; z-index: 101; }
                .filter-box{ & { width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; } 
                  .filter-header { & { padding: 5px 20px; width: 100%; padding-top: 20px; margin-bottom: -10px; position: relative; } 
                    p { font-weight: 700; font-size: 22px; line-height: 0px; color: #000000; }
                    img { position: absolute; width: 30px; top: 20px; right: 20px; cursor: pointer; }
                  }
                  .filter-content { & { width:100%; height: 100%; flex-shrink: 1; overflow-y: scroll; } 
                    .single-filter { & { border-bottom: 1px solid #D9D9D9; padding: 0 20px; }
                      .filter-name { & { display: flex; justify-content: space-between; align-items: center; padding: 10px 0; cursor: pointer; } 
                        p { font-size: 18px; line-height: 0px; font-weight: 600; color: #000000; }
                        input { width: 15px; height: 15px; accent-color: #cd4a4a;}
                      }
                      .filter-section { & { width: 100%; } 
                        .input-box { & { position: relative; } 
                          input { background-color: #EEEEEE; border-radius: 5px; padding: 10px; width: 100%; outline: none; border: none; font-size: 16px; font-weight: 400; }
                          img { width: 30px; position: absolute; top: 5px; right: 10px; }
                        }
                        .suggestion-heading { font-size: 18px; font-weight: 600; color: #000000; }
                        .user-box{ & {display: flex; justify-content: space-between; align-items: center; margin: 10px 0;}
                          .user-details { & {display: flex; justify-content: flex-start; align-items: center;}
                          .initial {object-fit: cover;border-radius: 50px; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center; margin-right: 10px;}
                            .user-info {
                              .name { font-size: 18px; font-weight: 600; color: #000000; }
                              .username { font-size: 16px; color: #9b9b9b;}
                            }
                            img{object-fit: cover;width: 40px; height: 40px; border-radius: 100px; margin-right: 10px;}
                          }
                          input { width: 15px; height: 15px; accent-color: #cd4a4a;}
                        }
                      }
                    }
                  }
                  .filter-footer { & { width: 100%; height: fit-content; padding: 20px;}
                    button { background: #CD4A4A; color: white; cursor: pointer; width: 100%; text-align: center; font-size: 16px; padding: 10px;}
                  }
                }
              }

							.add-reaction {
								& { height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; z-index: 100; background: #ffffff; position: absolute;   bottom: -20px; left: 60px; box-shadow: 0 0 10px #aaaaaa8e; }
			
								.reactions {
									& { position: relative; height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; }
									img {
										& { cursor: pointer; width: 28px; height: 28px; transition: transform 200ms ease; }
										&:hover { transform: scale(1.1); }
									}
									.close-reaction { height: 28px; width: 28px; cursor: pointer; color: #000000; margin-left: 1rem; }
								}
							}
							.messages {
								& { position: relative; width: 100%; min-height: 100%; max-height: 100%; overflow-y: scroll; overflow-x: hidden; display: flex; flex-direction: column-reverse; align-items: flex-start; }
                &::-webkit-scrollbar { display: none; }
								.message {
									& { position: relative; max-width: 80%; margin: 0 10px 35px 0; display: flex; flex-direction: row-reverse; align-content: flex-end; align-items: center; justify-content: flex-end; gap: 25px; font-size: 15px;  }
									.message-text {
										& { position: relative; padding: 10px; display: flex; flex-direction: column; gap: 5px; align-items: flex-start; word-break: break-word; box-shadow: 0px 2px 10px #0000000F; }
										strong { cursor: pointer; color: #3c80ff; display: contents; }
										.reply-msg {
											& { height: 100%; width: 100%; }
											.reply-content {
												& { font-size: 15px; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 0 5px 0; border-left: 4px solid #007c8950; }
												.name {
													color: #3483fa;
													font-weight: 700;
												}
												.content { width: 100%; max-height: 60px; overflow: hidden; text-overflow: ellipsis; }
											}
										}
										.message-emote-time {
											& { position: absolute; bottom: -28px; left: 0; right: 0; width: 275px; height: 25px; display: flex; align-items: center; justify-content: flex-start; gap: 5px; }
											.time { font-size: 12px; color: #9e9e9e; }
											.reaction {
												& { background: none; height: 25px; display: flex; align-items: center; min-width: 30px; border-radius: 5px; margin: 0 2px; }
												img { width: 20px; height: 20px; }
												.reaction-count { height: 25px !important; background: none; font-size: 12px; padding: 0; margin: 0; }
											}
										}
									}
									.message-emote-action {
										& { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); right: -125px; display: flex; align-items: center; justify-content: space-evenly; }
										.css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
										&:hover { display: flex !important; }
									}
			
									&:hover {
										.message-emote-action { display: flex !important; }
									}
			
									.chat-avatar {
										& { cursor: pointer; position: relative; width: 35px; height: 35px; border-radius: 20px; align-self: flex-start; }
										span { width: 35px; height: 35px; border-radius: 20px; }
										img { width: 35px; height: 35px; border-radius: 20px; object-fit: cover; }
										.name { cursor: pointer; width: 50px; position: absolute; bottom: -20px; left: 0; right: 0; font-size: 12px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
									}
									.chat-attachment { object-fit: contain; width: 100%; max-height: 450px; }
									span {
										& { position: relative; padding: 5px; min-height: 30px; border-radius: 7px; display: flex; align-items: center; justify-content: center; background-color: #ffffff; }
										.play-btn {
											& { cursor: pointer; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; }
											.css-i4bv87-MuiSvgIcon-root { color: #fff; height: 35px; width: 35px; transition: transform 250ms ease;
												&:hover {
													transform: scale(1.2);
												}
											}
										}
									}
								}
			
								.message-mine {
									& { align-self: flex-end; flex-direction: row; }
									.chat-avatar { display: none; }
									.message-emote-action {
										& { display: none !important; height: 100%; width: 85px; position: absolute; top: 50%; bottom: 50%; transform: translate(-50%, -50%); left: -40px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-evenly; }
										.css-i4bv87-MuiSvgIcon-root { cursor: pointer; color: #686868; }
										&:hover { display: flex !important; }
									}
			
									&:hover {
										.message-emote-action { display: flex !important; }
									}
									.message-text {
										& { position: relative; max-height: 50%; word-break: break-word; background: #CBEFD7; }
										strong { cursor: pointer; color: #3c80ff; display: contents; }
										.message-emote-time {
											& { position: absolute; bottom: -28px; left: auto; right: 0px; width: 240px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start; }
											.time { font-size: 12px; }
										}
									}
                  .add-reaction {
                    & { height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; z-index: 100; background: #ffffff; position: absolute;   bottom: -20px; right: 0px; left: auto; box-shadow: 0 0 10px #aaaaaa8e; }
          
                    .reactions {
                      & { position: relative; height: 40px; width: 200px; border-radius: 35px; display: flex; align-items: center; justify-content: center; }
                      img {
                        & { cursor: pointer; width: 28px; height: 28px; transition: transform 200ms ease; }
                        &:hover { transform: scale(1.1); }
                      }
                      .close-reaction { height: 28px; width: 28px; cursor: pointer; color: #000000; margin-left: 1rem; }
                    }
                  }
								}
			
								.no-messages {
									& { color: #989898; user-select: none; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; }
									.top-span { font-size: 20px; display: flex; align-items: center; justify-content: center; gap: 10px; }
								}
							}
						}
						.message-box-container {
							& { position: relative; min-height: 65px; background-color: #dadada; }
							.msg-edit {
								& { width: 100%; max-height: 65px; height: 65px; padding: 5px; position: absolute; top: -63px; position: absolute; left: 0; right: 0; background-color: #dadada; }
								.reply-content {
									& { font-size: 15px; max-width: 1000px; border-radius: 5px; background: #efefef; padding: 5px 10px; margin: 0 auto; border-left: 4px solid #007c8950; }
									.name {
										& { width: 100%; color: #3483fa; font-weight: 700; display: flex; align-items: center; justify-content: space-between; }
										.MuiSvgIcon-root { margin: 0; cursor: pointer; color: #00000090; border: 2px solid #00000090; border-radius: 5px; }
									}
									.content { width: 100%; max-height: 40px; overflow: hidden; text-overflow: ellipsis; }
								}
							}
						}
						.message-box {
							& { position: relative; height: 100%; max-width: 1000px; margin: 0 auto; padding: 10px 0; display: flex; align-items: center; flex-shrink: 0;  }
							.input-style {
								& { max-width: 100%; margin-inline: 10px; word-break: break-word; flex: 1; -moz-appearance: none; -webkit-appearance: none; appearance: none; background-color: #fff; border-radius: 10px; border-width: 0; }
								&:empty::before {content: 'Type a message';font-size: 16px;opacity: 0.5;}
								&:focus { outline: none; }
								.emoji-icon { cursor: pointer; position: absolute; top: 8px; right: 5px; }
								& .input-style__control { max-width: 100%; min-height: 100% !important; width: 100%; padding: 0; border-radius: 10px; }
								& .input-style__control input { border: 0px !important; top: 10px !important; padding: 2px 13px !important; outline: none; max-width: 100%; min-width: 100%; }
								& .input-style__control textarea { border: 0px !important; outline: none; border-radius: 10px; }
								& .input-style__control textarea:empty::before { content: 'Type a message'; font-size: 16px; opacity: 0.5; }
								& .input-style__control input:empty::before { content: 'Type a message'; font-size: 16px; opacity: 0.5; }
								& .input-style__control textarea:focus { border: 0px !important; outline: none; }
								& .input-style__control input:focus { border: 0px !important; outline: none; }
								& .input-style__control .input-style__highlighter > strong { padding: 2px 0px; border-radius: 5px; font-weight: 700; }
								.input-style__suggestions { display: flex; overflow: scroll !important; top: -165px !important; height: 150px; max-height: 150px; min-width: 150px !important; }
								.input-style__suggestions ul { overflow: scroll !important; width: 100%; }
							}
							.css-i4bv87-MuiSvgIcon-root { margin: auto; }
			
							//.input-style { padding: 7px 30px 7px 10px; width: calc(100% - 40px); border-radius: 3px; border: 1px solid #999; margin-bottom: 10px;}
			
							.attach-meadia {
								& { position: absolute; left: 1px; right: 0; top: -267px; width: 120px; background: #fff; border-radius: 3px; padding: 15px 10px; box-shadow: 3px 3px 15px #00000015; }
								.attach-btns {
									& { position: relative; }
									.arrow { position: absolute; bottom: -35px; left: 35px; right: 0;
										div { height: 27px; width: 27px; background: #fff; transform: rotateZ(45deg); }
									}
									.icon-img {
										& { cursor: pointer; margin: 10px 0; display: flex; flex-direction: column; align-items: center; transition: transform 250ms ease; }
										span { font-size: 14px; color: #9f9fa1; font-weight: bold; }
			
										&:hover { transform: scale(1.05); }
									}
								}
							}
							.common-button {
								& { cursor: pointer; position: relative; padding: 8px; width: 40px; height: 40px; background: #dadada; color: #000000; box-shadow: none; margin-bottom: 2px; }
								&:hover,
								&:focus { outline: none; .icon { filter: grayscale(1); }
								}
								.icon { display: block; width: 24px; height: 24px; font-size: 18px; text-align: center; filter: grayscale(1) opacity(0.5); transition: 0.2s; }
							}
						}
			
						.textbox {
							& { min-height: 30%; padding: 15px; background-color: #fff; }
			
							textarea {
								& { resize: none; width: 100%; border: none; }
								&:focus { outline: none; }
							}
						}
					}

          // --------------------- sub network -------------------------
          .sub-network-container {
            & { width: 100%; min-height: 700px; }
            .sub-network-wrapper {
              & { max-width: 1000px; width: 100%; margin: 0 auto; }
              .sub-network {
                & { min-height: 700px; width: 100%; padding: 0 2rem; }
                .sub-network-head {
                  & { width: 100%; height: 65px; display: flex; align-items: center; justify-content: space-between; margin-bottom: 1rem; }
                }
                .sub-network-flex {
                  & { width: 100%; min-height: 500px; height: auto; position: relative; gap: 1rem; display: flex; align-items: flex-start; flex-wrap: wrap; justify-content: space-between; padding-bottom: 1rem; }
                  .no-sub-network-info {
                    & { display: flex; align-items: center; justify-content: center; flex-direction: column-reverse; gap: 10px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
                  }
                  .sub-network-item {
                    & { cursor: pointer; width: 429px; height: 194px; background: #ffffff; box-shadow: 0px 2px 10px #0000000F; display: flex; align-items: center; justify-content: center; gap: 1rem; }
                    .sub-network-image {
                      & { height: 165px; width: 165px; background: #ededed; border-radius: 10px; display: flex; align-items: center; justify-content: center; }
                      img {
                        & { height: 100%; width: 100%; object-fit: cover; border-radius: 10px; }
                      }
                    }
                    .sub-network-details {
                      & { height: 165px; width: 215px; min-width: 215px;  }
                      .network-activity-details {
                        & { display: flex; align-items: center; justify-content: center; gap: 2rem; }
                        .activity {
                          & { font-size: 24px; font-weight: 600; display: flex; flex-direction: column; align-items: center; justify-content: center; }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          // --------------------- sub network -------------------------
          .events-container {
            & { width: 100%; min-height: 700px; }
            .events-wrapper {
              & { max-width: 1000px; width: 100%; margin: 0 auto; }
              .events {
                & { min-height: 700px; width: 100%; padding: 0 2rem; }
                .events-head {
                  & { width: 100%; height: 65px; display: flex; align-items: center; justify-content: space-between; margin-bottom: 1rem; }
                }
                .events-flex {
                  & { width: 100%; min-height: 500px; height: auto; position: relative; gap: 1rem; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; padding-bottom: 1rem; }
                  .no-events-info {
                    & { display: flex; align-items: center; justify-content: center; flex-direction: column-reverse; gap: 10px; position: absolute; top: 70%; left: 50%; transform: translate(-50%, -50%); }
                  }
                  .event-group-container {
                    & {  }
                    .event-date {
                      & { font-size: 18px; font-weight: bold; margin-bottom: 1rem; }
                    }
                    .event-group {
                      & { display: flex; flex-wrap: wrap; gap: 24px; }
                      .event {
                        & { width: 455px; height: 100px; background: #FFFFFF; border-radius: 10px; display: flex; align-items: center; justify-content: flex-start; gap: 10px; padding: 10px; } 
                        .event-image {
                          & { position: relative; height: 80px; width: 80px; border-radius: 4px; background: #aaa; display: flex; align-items: center; justify-content: center; }
                          img {
                            & { height: 100%; width: 100%; object-fit: cover; border-radius: 4px; }
                          }
                          .event-time-overlay {
                            & { color: #FFF; height: 100%; width: 100%; position: absolute; top: 0; left: 0; display: flex; align-items: center; justify-content: center; flex-direction: column; }
                            .event-time-overlay-day {
                              & { font-size: 18px; font-weight: 600; }
                            }
                          }
                        }
                        .event-details {
                          & { width: calc(100% - 90px); display: flex; align-items: flex-start; justify-content: center; flex-direction: column; gap: 8px; }
                          h3 {
                            & { margin: 0; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
                          }
                          .event-link {
                            & { color: #111; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
                          }
                          .event-time {
                            & { color: #1BA0A2; display: flex; align-items: center; justify-content: flex-start; gap: 6px; }
                            .event-time-icon { height: 18px; width: 18px; }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .single-event{
               & {max-width:970px; min-height: 601px;margin:0 auto; margin-top:25px; background-color: #fff;border-radius: 10px 10px 0px 0px; }
               .event-image{
                & { position: relative; }
                   img {
                      & { height:249px; width: 100%; object-fit: cover; border-radius:10px 10px 0px 0px; }
                  }
                }
                .event-header{
                &{ display:flex; align-items:center; justify-content: space-between;padding:24px;}
                .header-item{display: flex; gap:60px; margin-right:20px}
                 h2{color: #000;font-size:22px;font-weight:600;padding:0px;margin:0px;}
                h3{color: #000;font-size:17px;font-weight:600;padding:0px;margin:0px;margin-bottom:5px;}
                p{color: #000;font-size:16px;font-weight:400;padding:0px;margin:0px;}
                .timeZone{display: flex;gap:5px;}
               }
              .event-description,.event-link{
               &{display: flex;flex-direction: column;gap: 10px;padding:24px; padding-top:10px;}
               h3{color: #000;font-size:17px;font-weight:600;padding:0px;margin:0px}
               p,a{color: #000;font-size:16px;font-weight:400;padding:0px;margin:0px;}
              } 
              .event-footer{
               &{display: flex;flex-direction: column;gap: 10px;padding:24px;padding-top:10px;}
               h3{color: #000;font-size:17px;font-weight:600;padding:0px;margin:0px}
               div{ &{display: flex;align-items: center;justify-content: space-between;}
               #imageContainer { position: relative;}
               .avatar-image{height:40px;width:40px;border-radius:50%; margin-top:-20px;margin-right:-5px}
               .count { position: relative;  background-color: #D9D9D9; padding: 5px; display: flex; justify-content: center;  align-items: center; font-size: 15px; }
               .join-btn{
               & { text-transform: capitalize; font-size: 20px; width:219px; height:52px; color: #FFF; background: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
               }

               }
              }
            }
          }

          // --------------------- folder -------------------------
          .folder-container {
            & { width: 100%; min-height: 700px; }
            .folder-wrapper {
              & { max-width: 1000px; width: 100%; margin: 0 auto; }
              .folder {
                & { min-height: 700px; width: 100%; padding: 0 2rem; }
                .folder-head {
                  & { width: 100%; height: 65px; display: flex; align-items: center; justify-content: space-between; margin-bottom: 1rem; }
                }
                .folder-flex {
                  & { width: 100%; min-height: 500px; height: auto; position: relative; column-gap: 45px; row-gap: 20px; display: flex; align-items: flex-start; flex-wrap: wrap; justify-content: flex-start; padding-bottom: 1rem; }
                  .no-folder-info {
                    & { display: flex; align-items: center; justify-content: center; flex-direction: column-reverse; gap: 10px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
                  }
                  .folder-item {
                    & { width: 200px; height: 270px; position: relative; }
                    .folder-type {
                      & { position: absolute; top: -10px; left: -10px; color: #FFF; background: #dbdbdb; height: 40px; width: 40px; border-radius: 100%; display: flex; align-items: center; justify-content: center; font-size: 13px; font-weight: 600; }
                    }
                    .folder-image {
                      & { height: 200px; width: 200px; background: #e4e4e4; border-radius: 8px; box-shadow: 0px 2px 10px #00000014; display: flex; align-items: center; justify-content: center;  }
                      img {
                        & { height: 100%; width: 100%; object-fit: cover; border-radius: 8px;}
                      }

                    }
                    .folder-details {
                      & { margin-top: 10px; }
                      .folder-title {
                        & { margin: 0; width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
                      }
                      .attachment-details {
                        & { display: flex; align-items: center; justify-content: flex-end; gap: 5px; width: 100%; }
                        .attachment-size {
                          & { margin-right: auto; }
                        }
                        .attachment-action {
                          & { cursor: pointer; }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          // --------------------- courses -------------------------
          .course-container {
            & { width: 100%; }
            .course-wrapper {
              & { max-width: 1000px; width: 100%; margin: 0 auto; }
              .heading {
                & { display: flex; justify-content: space-between; align-items: center; max-width: 900px; width: 95%; margin: auto; }
                h1 { color: #17170F; font-size: 24px; }
                // button { background: #CD4A4A; color: #ffffff; border-radius: 5px; height: 40px; padding: 4px; font-size: 17px; width: 150px; font-weight: 500; }
              }
              .course-item-wrapper {
                & { display: flex; justify-content: center; align-items: center; max-width: 900px; width: 95%; margin: auto; flex-wrap: wrap; gap: 26px; }
                .course-card { 
                  & { max-width: 279px; width: 95%; position: relative;}
                  .c-type-draft { position: absolute; top: 80px; left: 30%; color: white; font-size: 40px; font-weight: 700;}
                  .c-type-published { margin: 0; padding: 0; position: absolute; top: 20px; left: 60%; color: white; padding: 10px; background-color: rgba($color: #000000, $alpha: 0.7); border-radius: 30px;}
                  .card-head {
                    & { display: flex; justify-content: space-between; align-items: center; }
                    h1, h4{ white-space: nowrap; text-transform: capitalize; color: #000; font-size: 16px; font-weight: bold; margin-bottom: 0; }
                  }
                  .card-desc {
                    & { position: relative;}
                  .card-about { 
                   & {display: flex; align-items: center; flex-direction: row; gap: 10px;}
                    .item-desc { color: #9B9B9B; margin-top: 8px; font-size: 14px; }
                    .arrow-img{ transform: rotate(180deg); cursor: pointer;}
                  }
                  .more-info {
                    &{ position: absolute; z-index: 2; width: 100%; background-color: #F6F7FA; transition: top 0.5s ease-in-out 3s; padding-left: 12px;}
                    h1 { white-space: nowrap; text-transform: capitalize; color: #000; font-size: 16px; font-weight: bold; margin-bottom: 0; }
                    .modules {
                      h1{ margin: 4px; padding: 0px; }
                      .module-info { 
                      & {margin-top: 10px; display: flex; justify-content: space-between; align-items: center; }
                      .image-stack {
                        img{ width: 40px; height: 40px; border-radius: 8px;}
                      }
                      p { right: 80px; position: absolute; color: #000;} 
                      .module-manage { padding: 8px; border: 1px solid #CD4A4A; border-radius: 4px; color: #CD4A4A; }
                      }  
                    }
                    .students {
                      h1 { margin: 4px; padding: 0px; }
                      .student-info {
                      & {  margin-top: 10px; display: flex; justify-content: space-between; align-items: center; }
                      .image-stack {
                        img{ width: 40px; height: 40px; border-radius: 20px;}
                      }
                      .student-view { padding: 8px; border: 1px solid #CD4A4A; border-radius: 4px; color: #CD4A4A; cursor: pointer;}
                      }
                    }
                  .supporting-files { margin-top: 10px; margin-bottom: 24px; width: 50%; color: white; background-color: #CD4A4A; padding: 8px; border-radius: 4px; cursor: pointer;}
                  } 
                  .hidden {display: none;}
                  }

                }
              }
            }
          }

          // --------------------- members -------------------------
          .members-container {
            & { position: relative; width: 100%; }
            .members-wrapper {
              & { position: relative; max-width: 1000px; width: 100%; margin: 0 auto; padding: 1rem; }
            }
            .members-header {
              & { height: 48px; width: 100%; display: flex; align-items: center; justify-content: space-between; margin: 1rem 0; }
              h2 { font-size: 24px; color: #17170f; margin: 0; }
              .invite-members { margin-left: 20px; width: 185px; height: 40px; color: #FFF; background: #CD4A4A; border-radius: 5px; }
            }
            .members {
              & { display: flex; align-items: flex-start; justify-content: flex-start; gap: 23px; flex-wrap: wrap; padding: 5px; }
              .member-container {
                & { }
              }
              .member {
                & { cursor: pointer; display: flex; flex-direction: column; align-items: center; gap: 8px; padding: 8px 0; height: 230px; min-width: 140px !important; border-radius: 5px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000025; }
                .member-avatar {
                  & { height: 96px; width: 96px; }
                  img { height: 96px; width: 96px; border-radius: 100%; object-fit: cover; }
                  span { display: flex; align-items: center; justify-content: center; height: 96px; width: 96px; border-radius: 100%; }
                }
                .member-name {
                  & {  }
                  .name { font-size: 14px; font-weight: 700; color: #000; height: 20px; text-align: center; white-space: pre; width: 120px; overflow: hidden; text-overflow: ellipsis; }
                  .user-name { font-size: 14px; font-weight: 500; color: #9B9B9B; height: 20px; text-align: center; white-space: pre; width: 120px; overflow: hidden; text-overflow: ellipsis; }
                }
                .member-role {
                  .hub-owner { color: #1BA0A2; text-transform: capitalize; font-size: 12px; font-weight: 700; }
                  .hub-member { color: #88488F; text-transform: capitalize; font-size: 12px; font-weight: 700; }
                  .hub-moderator { color: #F38028; text-transform: capitalize; font-size: 12px; font-weight: 700; }
                  .hub-invited { color: #383838; text-transform: capitalize; font-size: 12px; font-weight: 700; }
                }
                .member-follow {
                  & { height: 32px; width: 115px; display: flex; align-items: center; justify-content: center; }
                  .MuiButtonBase-root { height: 32px; width: 115px; background: #CD4A4A; color: #fff !important; font-size: 14px; font-weight: 500; border-radius: 5px; box-shadow: none !important; }
                  .red-btn {
                    & { text-transform: capitalize; font-size: 20px; width: 115px; height: 32px; color: #FFF; background: #cd4a4a; font-weight: normal; border: 1px solid #cd4a4a; border-radius: 5px; }
                  }
                  .unfollow-btn {
                    & { text-transform: capitalize; font-size: 14px; font-weight: 500; width: 115px; height: 32px; color: #7d7d7d !important; background: #ffffff !important; font-weight: normal; border: 1px solid #7d7d7d; border-radius: 5px; }
                  }
                  .white-bg-btn {
                    & { background: #FFF; color: #cd4a4a; }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.network-join-modal {
  & { z-index: 10000; position: absolute; top: 0; left: 0; height: 100%; width: 100%; background: #00000090; display: flex; align-items: center; justify-content: center; }
  .network-modal {
    & {  }
  }
}


.paid-network-modal {
  & { max-width: 500px; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; gap: 32px; background-color: #fff; color: #000; border-radius: 8px; padding: 1rem; }
  .modal-btns {
    & { display: flex; width: 100%; gap: 1rem; }
  }
  h1 { text-align: center; }
  p { font-size: 18px; text-align: center; }
  h2 { text-align: center; }

}


.primary-red-btn {
  & { height: 32px; border-radius: 5px; background: #CD4A4A; font-size: 14px; text-transform: uppercase; min-width: 65px; padding: 6px 8px; display: flex; align-items: center; justify-content: center; }
  &:hover { background: #c04646; }
}

.chat-filter-setting { 
  & { width: 90%; position: absolute; z-index: 10; top: 45px; right: 30px; background-color: #F6F7FA; box-shadow: 0px 0px 35px rgb(0 0 0 / 25%); color: #000; padding: 1rem; display: flex; flex-direction: column; align-items: start; justify-content: center; border-radius: 8px; }
  .filter-header { 
    & { width: 100%; position: relative; display: flex; align-items: center; justify-content: space-between; gap: 1rem; margin-bottom: 1rem; } 
      p {
        & { margin: 0; font-weight: 700; font-size: 22px; line-height: 0px; color: #000000; }
      }
      .close {
        & { cursor: pointer; }
      }
  }
  .single-filter { 
    & { width: 100%; }
    
    .filter-name { 
      & { display: flex; justify-content: space-between; font-weight: 700; align-items: center; margin-top: 8px; }
      input { 
        & { cursor: pointer; height: 20px; width: 20px; accent-color: #cd4a4a; }
      }
      p { margin: 0; }
    }
  }
  .filter-footer { 
    & { width: 100%; margin-top: 1rem; }
  }
}