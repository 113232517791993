// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$mainfont : 'Lato', sans-serif;
$mulish : 'Mulish', sans-serif;
$work-sans : 'Work Sans', sans-serif;
$lato :  'Lato', sans-serif;


// wave 
.featured-tabs-container {
    & { width: 100%; height: 100%; padding: 0 24px; }
    .featured-nav-container {
      & { height: 10%; width: 100%; max-width: 1366px; margin: 0 auto; padding: 20px 0; display: flex; align-items: center; gap: 40px; justify-content: flex-start; }
      .nav-item {
        & { cursor: pointer; display: flex; align-items: center; justify-content: center; gap: 8px; }
        img { height: 40px; width: 40px; }
        span { font-size: 20px; color: #000; font-family: $lato; padding: 0 0 6px 0; border-bottom: 3px solid transparent; }
      }
      .create-post {
        & { margin-left: auto; display: flex; align-items: center; }
        .create-post-icon { color: #CD4A4A; border: 2px solid #CD4A4A; border-radius: 2px; }
        span { padding: 0; border: 0; color: #CD4A4A; }
      }
    }
    .following-container {
      & { padding: 16px 0; max-width: 1366px; margin: 0 auto; }
    }
    .find-friend-container {
      & { max-height: 62%; width: 100%; max-width: 1366px; margin: 0 auto; padding: 24px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; margin-bottom: 24px; }
      .find-friend {
        & { height: 10%; width: 100%; display: flex; align-items: center; justify-content: flex-start; gap: 32px; }
        span { font-family: $lato; font-size: 24px; font-weight: 700; color: #000; }
        .search-bar {
          & { width: 423px; height: 40px; padding: 0 10px; display: flex; align-items: center; justify-content: space-between; background: #EEEEEE; border-radius: 5px; }
          input { font-family: $lato; font-size: 20px; background: transparent; width: 90%; height: 100%; border: 0px; }
          input:focus { outline: none; }
          input::placeholder { color: #9B9B9B; }
          .search-icon { color: #9B9B9B; cursor: pointer; }
        }
      }
      .friends-container {
        & { position: relative; height: 500px; width: 100%; overflow-y: scroll; margin-bottom: 16px; }
          .no-friend {
            & { height: 100%; width: 100%; display: flex; gap: 16px; flex-direction: column; align-items: center; justify-content: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
            .not-avail { font-family: $lato; font-size: 20px; font-weight: 700; color: #9B9B9B; }
            .invite-btn {
              & { width: 107px; height: 52px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
            }
          }
          .friend {
            & { height: 48px; width: 100%; margin-top: 24px; display: flex; align-items: center; justify-content: space-between;  }
            .profile {
              & { display: flex; align-items: center; gap: 10px; }
              .profile-img {
                & { height: 48px; width: 48px; border-radius: 100%; }
                img { height: 48px; width: 48px; border-radius: 100%; object-fit: cover; }
                span { height: 48px; width: 48px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .profile-name {
                & { display: flex; flex-direction: column; }
                .user { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                .username { font-family: $lato; font-size: 10px; color: #9B9B9B;  }
              }
            }
            .follow-btn {
              & { width: 95px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
            }
            .unfollow-btn {
              & { text-transform: capitalize; width: 95px; height: 32px; color: #7d7d7d; background: #ffffff; font-weight: normal; border: 1px solid #7d7d7d; border-radius: 5px; }
            }
            .white-bg-btn {
              & { background: #FFF; color: #cd4a4a; }
            }
          }
      }
    }
  
    .discover-container {
      & { padding: 20px 0; height: 87%; width: 100%; max-width: 1366px; margin: 0 auto; display: flex; justify-content: space-between; }
      .posts-container {
        & { width: 50%; display: flex; gap: 20px; flex-direction: column; align-items: flex-start; justify-content: flex-start; }
        .follow-notice { width: 100%; text-align: center; }
        .post {
          & { width: 700px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
          .post-profile {
            & { display: flex; align-items: center; justify-content: space-between; padding: 10px; }
            .profile {
              & { height: 64px; width: 100%; display: flex; gap: 16px; align-items: center; }
              .profile-img {
                & { height: 64px; width: 64px; border-radius: 100%; }
                img { height: 64px; width: 64px; border-radius: 100%; object-fit: cover; }
                span { height: 64px; width: 64px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .profile-name {
                & { display: flex; flex-direction: column; }
                .user { 
                  & { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                  .tagged-users { cursor: pointer; }
                  .live-indicator { font-weight: 600; color: #CD4A4A; margin-left: 10px; text-transform: uppercase; font-size: 1rem; }
                }
                .activity-time { font-family: $lato; font-size: 16px; color: #000;  }
              }
            }
          }
          .post-caption {
            & { padding: 16px 26px; width: 100%; color: #000; font-weight: 600; word-wrap: break-word; }
          }
          .newsfeed-post-caption {
            & { font-size: 20px; width: 100%; }
          }
  
          .post-img {
            & { max-height: 700px; width: 100%; display: flex; align-items: center; justify-content: center; border-top: 1px solid #f1f1f1;  }
            img { object-fit: contain; height: 100%; width: 100%; }
            video { object-fit: contain; height: 100%; width: 100%; }
            .carousel-root { height: 100%; width: 100%; }
            .carousel .carousel-status { font-family: $lato; font-size: 10px; text-shadow: none; right: 50px; background: #00000080; border-radius: 5px; }
            .carousel .thumbs-wrapper { display: none; }
            .carousel.carousel-slider { height: 100%; }
            .carousel.carousel-slider .control-arrow { opacity: 0.8; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; border-radius: 100%; background: #ffffff; right: 16px; top: 50%; }
            .carousel.carousel-slider .control-arrow::before { border-left: 8px solid #d2d2d2; }
            .carousel .control-prev.control-arrow { opacity: 0.8; display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; border-radius: 100%; background: #ffffff; left: 16px; top: 50%; }
            .carousel .control-prev.control-arrow::before { margin-right: 10px; border-left: 0; border-right: 8px solid #d2d2d2; }
            .carousel .control-disabled.control-arrow { opacity: 0; }
            .carousel .slider-wrapper { height: 100%; }
            .carousel .control-dots { bottom: 0px; }
            .carousel .control-dots .dot { height: 10px; width: 10px; border: 1px solid #FFF; background: transparent; opacity: 1; box-shadow: 0 0 3px #919191; }
            .carousel .control-dots .dot.selected { background: #CD4A4A !important; }
            .carousel .slider-wrapper.axis-horizontal .slider { height: 100%; border-radius: 10px; }
            .carousel .slider-wrapper.axis-horizontal .slider .slide { height: 100%; border-radius: 10px; }
            .carousel .slider-wrapper.axis-horizontal .slider .slide .topic { position: absolute; top: 10px; left: 10px; font-size: 12px; padding: 5px; border-radius: 15px; background: #1BA1A1; color: #FFF; }
            .carousel .slider-wrapper.axis-horizontal .slider .slide .final-img-container { position: relative; height: 100%; border-radius: 10px; }
            .carousel.carousel-slider .control-arrow:hover { background: rgba(0,0,0,0.1); }

            
          }
  
          .post-actions {
            & { height: 32px; width: 100%; padding: 0px 24px; margin: 16px 0; display: flex; align-items: center; justify-content: flex-start; gap: 16px; }
            .post-like {
              & { position: relative; display: flex; align-items: center; justify-content: center; gap: 8px; }
              img { height: 32px; width: 32px; cursor: pointer; }
              .like-heart { height: 32px; width: 32px; cursor: pointer; }

              .reaction-container {
                & { z-index: 100; position: absolute; top: -4rem; left: 0; display: flex; align-items: center; gap: 10px; padding: 10px 16px; background: #fff; box-shadow: 0 0 5px #00000050; border-radius: 12px; }
                img { height: 32px; width: 32px; cursor: pointer; }
              }
            }
            .post-comment {
              & { display: flex; align-items: center; justify-content: center; gap: 8px; }
              img { height: 32px; width: 32px; cursor: pointer; }
            }
            .post-delete {
              & { margin-left: auto; display: flex; align-items: center; justify-content: center; gap: 8px; }
              img { height: 32px; width: 32px; cursor: pointer; }
            }
          }
  
          .add-comment {
            & { padding: 16px 24px; display: flex; align-items: center; justify-content: center; gap: 8px; }
            .user-avatar {
              & { height: 32px; width: 32px; border-radius: 100%; }
              img { height: 32px; width: 32px; border-radius: 100%; object-fit: cover; }
              span { height: 32px; width: 32px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
            }
            .comment-input {
              & { width: calc(100% - 40px); height: 32px; padding-left: 10px; background: #FFFFFF; border: 1px solid #CACACA; border-radius: 5px; }
              &:focus { outline: none; border: 1px solid #4a9fff; }
            }
          }
        }
        .newsfeed{
          p{
            &{ padding: 0 20px; }
          }
          a {
            & { color: #2275B9; }
          }
        }
        .hubfeed{
          .post-actions{
            &{ display: flex; justify-content: space-between; }
            h4{
              &{ color: black; }
            }
            button{
              &{ background:#CD4A4A; width: 160px; padding: 10px 0;}
            }
          }
        }
        .streamfeed{
          .live-creator-details {
            & { background:#ffffff; color: #000000; font-size: 20px; font-weight: 700; height: 65px; width: 100%; display: flex; align-items: center; justify-content: flex-start; padding-left: 1rem; }
          }
          .watch-recording {
            & { background:#CD4A4A; color: #FFF; gap: 10px; font-size: 18px; height: 65px; width: 100%; display: flex; align-items: center; justify-content: center; border-radius: 0 0 10px 10px; }
            
          }
        }
      }
      .recommend-container {
        & { width: 47%; height: max-content; display: flex; gap: 20px; flex-direction: column; align-items: flex-end; justify-content: flex-start; }
        .recommend-people {
          & { width: 80%; padding: 24px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
          .friend {
            & { height: 48px; width: 100%; margin-top: 24px; display: flex; align-items: center; justify-content: space-between;  }
            &:first-child { margin-top: 0; }
            .profile {
              & { display: flex; align-items: center; gap: 10px; }
              .profile-img {
                & { height: 48px; width: 48px; border-radius: 100%; }
                img { height: 48px; width: 48px; border-radius: 100%; object-fit: cover; }
                span { height: 48px; width: 48px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .profile-name {
                & { display: flex; flex-direction: column; }
                .user { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                .username { font-family: $lato; font-size: 10px; color: #9B9B9B;  }
              }
            }
            .follow-btn {
              & { width: 95px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
            }
            .unfollow-btn {
              & { text-transform: capitalize; width: 95px; height: 32px; color: #7d7d7d; background: #ffffff; font-weight: normal; border: 1px solid #7d7d7d; border-radius: 5px; }
            }
            .white-bg-btn {
              & { background: #FFF; color: #cd4a4a; }
            }
          }
        }
        .recommend-hubs {
          & { width: 95%; height: 315px; padding: 0 24px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
          .hub-container {
            & { height: 48px; width: 100%; margin-top: 24px; display: flex; align-items: center; justify-content: space-between;  }
            .hub {
              & { display: flex; align-items: center; gap: 10px; }
              .hub-img {
                & { height: 48px; width: 48px; border-radius: 5px; }
                img { height: 48px; width: 48px; border-radius: 5px; object-fit: cover; }
                span { height: 48px; width: 48px; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
              }
              .hub-name {
                & { display: flex; flex-direction: column; }
                .user { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                .username { font-family: $lato; font-size: 10px; color: #9B9B9B;  }
              }
            }
            .follow-btn {
              & { width: 95px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
            }
          }
        }
      }
    }
  }
.wave-container {
    & { width: 100%; height: 100%; padding: 0 24px; }
    .wave-nav-container {
      & { height: 10%; width: 100%; max-width: 1366px; margin: 0 auto; padding: 0; display: flex; align-items: center; gap: 40px; justify-content: flex-start; }
      .nav-item {
        & { cursor: pointer; display: flex; align-items: center; justify-content: center; gap: 8px; }
        img { height: 40px; width: 40px; }
        span { font-size: 20px; color: #000; font-family: $lato; padding: 0 0 6px 0; border-bottom: 3px solid transparent; }
      }
      .create-post {
        & { margin-left: auto; display: flex; align-items: center; }
        .create-post-icon { color: #CD4A4A; border: 2px solid #CD4A4A; border-radius: 2px; }
        span { padding: 0; border: 0; color: #CD4A4A; }
      }
    }
    .following-container {
      & { padding: 16px 0; max-width: 1366px; margin: 0 auto; }
    }
    .find-friend-container {
      & { max-height: 62%; width: 100%; max-width: 1366px; margin: 0 auto; padding: 24px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; margin-bottom: 24px; }
      .find-friend {
        & { height: 10%; width: 100%; display: flex; align-items: center; justify-content: flex-start; gap: 32px; }
        span { font-family: $lato; font-size: 24px; font-weight: 700; color: #000; }
        .search-bar {
          & { width: 423px; height: 40px; padding: 0 10px; display: flex; align-items: center; justify-content: space-between; background: #EEEEEE; border-radius: 5px; }
          input { font-family: $lato; font-size: 20px; background: transparent; width: 90%; height: 100%; border: 0px; }
          input:focus { outline: none; }
          input::placeholder { color: #9B9B9B; }
          .search-icon { color: #9B9B9B; cursor: pointer; }
        }
      }
      .friends-container {
        & { position: relative; max-height: 90%; width: 100%; overflow-y: scroll; margin-bottom: 16px; }
          .no-friend {
            & { height: 100%; width: 100%; display: flex; gap: 16px; flex-direction: column; align-items: center; justify-content: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
            .not-avail { font-family: $lato; font-size: 20px; font-weight: 700; color: #9B9B9B; }
            .invite-btn {
              & { width: 107px; height: 52px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
            }
          }
          .friend {
            & { height: 48px; width: 100%; margin-top: 24px; display: flex; align-items: center; justify-content: space-between;  }
            .profile {
              & { display: flex; align-items: center; gap: 10px; }
              .profile-img {
                & { height: 48px; width: 48px; border-radius: 100%; }
                img { height: 48px; width: 48px; border-radius: 100%; object-fit: cover; }
                span { height: 48px; width: 48px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .profile-name {
                & { display: flex; flex-direction: column; }
                .user { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                .username { font-family: $lato; font-size: 10px; color: #9B9B9B;  }
              }
            }
            .follow-btn {
              & { width: 95px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
            }
          }
      }
    }
  
    .discover-container {
      & { padding: 30px 0; height: 87%; width: 100%; max-width: 1366px; margin: 0 auto; display: flex;  }
      .posts-container {
        & { width: 60%; display: flex; gap: 20px; flex-direction: column; align-items: flex-start; justify-content: flex-start; }
        .post {
          & { width: 95%; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
          .post-profile {
            & { display: flex; align-items: center; justify-content: space-between; padding: 24px 24px 16px 24px; }
            .profile {
              & { height: 64px; width: 100%; display: flex; gap: 16px; align-items: center; }
              .profile-img {
                & { height: 64px; width: 64px; border-radius: 100%; }
                img { height: 64px; width: 64px; border-radius: 100%; object-fit: cover; }
                span { height: 64px; width: 64px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .profile-name {
                & { display: flex; flex-direction: column; }
                .user { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                .activity-time { font-family: $lato; font-size: 16px; color: #000;  }
              }
            }
          }
          .post-caption {
            & { padding: 16px 24px; width: 90%; color: #000; font-weight: 600; }
          }
  
          .post-img {
            & { height: 650px; width: 100%; margin: 16px 0; display: flex; align-items: center; justify-content: center; border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1;  }
            img { object-fit: contain; height: 100%; width: 100%; }
            .carousel-root { height: 100%; width: 100%; }
            .carousel .carousel-status { font-family: $lato; font-size: 10px; text-shadow: none; right: 50px; background: #00000080; border-radius: 5px; }
            .carousel .thumbs-wrapper { display: none; }
            .carousel.carousel-slider { height: 100%; }
            .carousel.carousel-slider .control-arrow { width: 50px; }
            .carousel .slider-wrapper { height: 100%; }
            .carousel .control-dots { bottom: 0px; }
            .carousel .control-dots .dot { height: 10px; width: 10px; border: 1px solid #FFF; background: transparent; opacity: 1; box-shadow: 0 0 3px #919191; }
            .carousel .control-dots .dot.selected { background: #CD4A4A !important; }
            .carousel .slider-wrapper.axis-horizontal .slider { height: 100%; border-radius: 10px; }
            .carousel .slider-wrapper.axis-horizontal .slider .slide { height: 100%; border-radius: 10px; }
            .carousel .slider-wrapper.axis-horizontal .slider .slide .final-img-container { position: relative; height: 100%; border-radius: 10px; }
            .carousel.carousel-slider .control-arrow:hover { background: rgba(0,0,0,0.1); }

            
          }
  
          .post-actions {
            & { height: 32px; width: 100%; padding: 0px 24px; margin: 16px 0; display: flex; align-items: center; justify-content: flex-start; gap: 16px; }
            .post-like {
              & { display: flex; align-items: center; justify-content: center; gap: 8px; }
              img { height: 32px; width: 32px; }
              .like-heart { height: 32px; width: 32px; cursor: pointer; }
            }
            .post-comment {
              & { display: flex; align-items: center; justify-content: center; gap: 8px; }
              img { height: 32px; width: 32px; cursor: pointer; }
            }
          }
  
          .add-comment {
            & { padding: 16px 24px; display: flex; align-items: center; justify-content: center; gap: 8px; }
            .user-avatar {
              & { height: 32px; width: 32px; border-radius: 100%; }
              img { height: 32px; width: 32px; border-radius: 100%; object-fit: cover; }
              span { height: 32px; width: 32px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
            }
            .comment-input {
              & { width: calc(100% - 40px); height: 32px; padding-left: 10px; background: #FFFFFF; border: 1px solid #CACACA; border-radius: 5px; }
              &:focus { outline: none; }
            }
          }
        }
      }
      .recommend-container {
        & { width: 40%; height: max-content; display: flex; gap: 20px; flex-direction: column; align-items: flex-end; justify-content: flex-start; }
        .recommend-people {
          & { width: 95%; height: 315px; padding: 0 24px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
          .friend {
            & { height: 48px; width: 100%; margin-top: 24px; display: flex; align-items: center; justify-content: space-between;  }
            .profile {
              & { display: flex; align-items: center; gap: 10px; }
              .profile-img {
                & { height: 48px; width: 48px; border-radius: 100%; }
                img { height: 48px; width: 48px; border-radius: 100%; object-fit: cover; }
                span { height: 48px; width: 48px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
              }
              .profile-name {
                & { display: flex; flex-direction: column; }
                .user { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                .username { font-family: $lato; font-size: 10px; color: #9B9B9B;  }
              }
            }
            .follow-btn {
              & { width: 95px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
            }
          }
        }
        .recommend-hubs {
          & { width: 95%; height: 315px; padding: 0 24px; background: #FFFFFF; box-shadow: 0px 3px 10px #00000014; border-radius: 10px; }
          .hub-container {
            & { height: 48px; width: 100%; margin-top: 24px; display: flex; align-items: center; justify-content: space-between;  }
            .hub {
              & { display: flex; align-items: center; gap: 10px; }
              .hub-img {
                & { height: 48px; width: 48px; border-radius: 5px; }
                img { height: 48px; width: 48px; border-radius: 5px; object-fit: cover; }
                span { height: 48px; width: 48px; border-radius: 5px; display: flex; align-items: center; justify-content: center; }
              }
              .hub-name {
                & { display: flex; flex-direction: column; }
                .user { font-family: $lato; font-size: 20px; font-weight: 700; color: #000;  }
                .username { font-family: $lato; font-size: 10px; color: #9B9B9B;  }
              }
            }
            .follow-btn {
              & { width: 95px; height: 32px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
            }
          }
        }
      }
    }
  }